import { useState, useEffect, ChangeEvent } from "react";
import {
    Container,
    Box,
    Typography,
    Divider,
    Autocomplete,
    TextField,
    SvgIcon,
    Modal,
    Stack,
    Grid,
    fabClasses,
    Button,
    InputLabel,
    FormControlLabel,
    Switch,
    MenuItem,
    Backdrop,
    CircularProgress,
    TextareaAutosize,
    Tooltip,
    IconButton,
    Checkbox,
    Select
} from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone, AlignHorizontalCenter } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IDeleteBlog, IGetBlogsDet } from "../../redux/api/types";
import { useGetBlogDetailsAdminQuery, useBlogDetailsUpdateMutation, useBlogDetailsDraftMutation, useBlogDetailsPublishMutation, useBlogsCountQuery, useGenerateBlogsMutation, useDeleteBlogMutation, useGetgeneratedBlogListingsQuery, useRegenerateBusinessBlogMutation, useCopyurlMutation, usePublishUnpublishCountMutation, usePublishBulkyBlogsMutation, useGetBlogsByCampaignQuery } from "../../redux/api/promptApi";
import { useGetUserDetailsMutation } from "../../redux/api/authApi";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as EditIcon } from '../../assets/pencil1_ico.svg';
import { redirect, useNavigate } from "react-router-dom";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LinkIcon from '@mui/icons-material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import { object, string, TypeOf, number } from "zod";
import { toast } from "react-toastify";
import { ReactComponent as AddIcon } from '../../assets/ico_plus_white.svg';
import { ReactComponent as WishIcon } from "../../assets/ico_wish.svg";
import { ReactComponent as EyeIcon } from "../../assets/ico_eye.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MagicWand from "../../components/MagicWand";
import Header from "../../components/headers/Header";
import demoImage from "../../assets/image1.png";
import { Visibility } from "@mui/icons-material";
import { GetAppOutlined } from "@mui/icons-material";
import { updateNamedExports } from "typescript";
import { ReactComponent as ViewIcon } from '../../assets/view_ico.svg';
import { ReactComponent as DownloadIcon } from "../../assets/download1_ico.svg";
import { ReactComponent as TrashCan } from '../../assets/trash1_ico.svg';
import { ReactComponent as RegenerateIcon } from '../../assets/regenerate1_ico.svg';
import { ReactComponent as VerticalIcon } from '../../assets/traffic_monster/vertical_ln_ico.svg';
import { ReactComponent as ShareIcon } from '../../assets/share1_ico.svg';
import { ReactComponent as MenuIcon } from '../../assets/Menu_ico.svg';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useGetproductsDetQuery } from '../../redux/api/promptApi';
import { renderToStaticMarkup } from 'react-dom/server';
import { ReactComponent as FeedBackIcon } from '../../assets/ico_feedback.svg'
import { useFeedBackMutation } from '../../redux/api/authApi';
import { store, useAppDispatch, useAppSelector } from "../../redux/store";
import { isBrowser, isMobile } from "react-device-detect";
import PayNowButton from '../../pages/payment/payNowButton';
import { useLazyGetProductQuery } from '../../redux/api/productApi';
import './generated_blogs.css';
import { IPlanDetail } from "../../redux/api/types";
import axios from 'axios';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useCampaignListMutation, useGetCnameEntriesMutation } from "../../redux/api/promptApi";


// import { Tooltip } from '@material-ui/core';


interface IGetBlogsAdmin {
    blog_title: string,
    blog_image_string: string,
    blog_summary: string,
    blog_image_path: string,
    cat: string,
    blog_det: string,
    url: string,
    _id: string,
    limitval: number,
    skipval: number,
    query: string,
    serialNumber: number,
    blog_image_path_small: string,
    image_original_id: string,
    image_updated: string,
    mode: string,
    blog_det_draft: string,
    blog_image_path_draft: string,
    blog_summary_draft: string,
    blog_title_draft: string,
    url_draft: string,
    seo_score: number,
    total_views: number,
    rattings: number,
    publish_date: string,
    author: string,
    author_image: string,
    author_url: string,
    cta_label: string,
    cta_url: string,
    product_id: string,
    statichtml: boolean,
    canonical_url: string
}

interface IGenerateContent {
    cat: string,
    title: string,
    prompt: string,
    /*     blog_title_draft: string,
        blog_image_string: string,
        blog_summary_draft: string,
        blog_image_path_draft: string,
        blog_det_draft: string,
        url_draft: string,
        image_update: string,
        mode: string, */
}

interface BlogPreviewResponseStruct {
    data: {
        msg: BlogPreviewResponseStructMsg
    };
    status: string;
}

interface BlogPreviewResponseStructMsg {
    blog_det_draft: string;
    blog_image_path_draft: string;
    blog_image_string: string;
    blog_summary_draft: string;
    blog_title_draft: string;
    cat: string;
    image_updated: string;
    mode: string;
    url_draft: string;
    blogid: string;
};


const generateContent = object({
    cat: string(),
    title: string(),
    prompt: string(),
})

const feedbackform = object({
    email: string(),
    rating: number(),
    feedback: string(),
    productname: string(),
    date: string()
});

const blogUpdate = object({
    id: string(),
    blog_title: string(),
    blog_image_path: string(),
    blog_image_string: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    publish_date: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string()
})

const blogDraft = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string()
})

const blogPublish = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string()
})

export type FeedBackForm = TypeOf<typeof feedbackform>

export type BlogUpdate = TypeOf<typeof blogUpdate>
export type BlogDraft = TypeOf<typeof blogDraft>
export type BlogPublish = TypeOf<typeof blogPublish>

export type GenerateCont = TypeOf<typeof generateContent>




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#1D252D', 
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: 'white',
        backgroundColor: '#131825', // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        // backgroundColor: '#F2F6FA',
        /* backgroundColor: '#131825', */ // Set background color for even rows to gray
        backgroundColor: '#1D252D',
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const customInputStyle = {
    color: '#fff',
    border: '1px solid #CBCBCB',
};

interface LoadingStates {
    [key: string]: boolean;
}

const Blogs_List = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem("logged_in");

        if (loggedIn !== "true") {
            navigate("/signin");
        }
    }, [navigate]);

    const [open, setOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState<IGetBlogsAdmin | null>(null);
    const [updateblogs, setUpdateBlogs] = useState<BlogUpdate>({ id: '', blog_title: '', blog_image_path: '', blog_image_string: '', blog_summary: '', blog_det: '', url: '', publish_date: '', author: '', author_image: '', author_url: '', cta_label: '', cta_url: '' });
    const [blogDetailsUpdateMutation] = useBlogDetailsUpdateMutation();
    const [openPaidModal, setOpenPaidModal] = useState(false);
    const [Draftblogs, setDraftBlogs] = useState<BlogDraft>({ id: '', blog_title: '', blog_image_string: '', blog_summary: '', blog_det: '', url: '', author: '', author_image: '', author_url: '', cta_label: '', cta_url: '' });
    const [blogDetailsDraftMutation] = useBlogDetailsDraftMutation();

    const [Publishblogs, setPublishBlogs] = useState<BlogPublish>({ id: '', blog_title: '', blog_image_string: '', blog_image: '', blog_summary: '', blog_det: '', url: '' });
    const [blogDetailsPublishMutation] = useBlogDetailsPublishMutation();

    const [ContentGeneration, setContentGeneration] = useState<IGenerateContent>({ cat: '', title: '', prompt: '' });
    const [generateBlogsMutation] = useGenerateBlogsMutation();
    const [delBlogs, setDelBlogs] = useState<IDeleteBlog | undefined>(undefined);
    const [userDets, setUserDets] = useState<any>();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();



    const [openAddNew, setOpenAddNew] = useState(false);
    const [blogPreviewValues, setBlogPrevValues] = useState<BlogPreviewResponseStructMsg | null>(null);
    const [sendBlogId, setSendBlogId] = useState<BlogPreviewResponseStructMsg | null>(null);

    //feedback popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const ratings = [1, 2, 3, 4, 5];
    const user = useAppSelector((state) => state.userState.user);
    const [productName, setProductName] = useState<string | null>(localStorage.getItem('module'));
    const currentDate = new Date().toISOString();
    const [feedbackForm, setFeedbackForm] = useState<FeedBackForm>({ email: localStorage.getItem('user_email') || "", rating: 0, feedback: '', productname: productName || '', date: currentDate })
    const [feedbackMutation] = useFeedBackMutation();


    const [productDetail, setProductDetail] = useState<any>();
    const [productURL, setProductURL] = useState('');

    const [blogs, setBlogs] = useState<IGetBlogsAdmin[]>([]);
    const [blogId, setBlogId] = useState("");
    const [status, setStatus] = useState("");

    const [selectedBlogIndex, setSelectedBlogIndex] = useState(0);

    //console.log(blogs);
    const useremail = localStorage.getItem('user_email') ?? '';


    const [copySharingUrlMutation] = useCopyurlMutation();
    const [isLoadingCopied, setIsLoadingCopied] = useState<LoadingStates>({});
    const [ismulipleBlogsLoading, setIsmultipleBlogsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState<any>({});
    const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(false);
    const [selectedBlogTitle, setSelectedBlogTitle] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 100; //Number of blogs per page
    const domain = window.location.hostname;
    const params = new URLSearchParams(window.location.search);
    const promptid = params.get('promptid') ?? "";
    const getBlogsCount = useBlogsCountQuery({promptid: promptid, userid: useremail, cat: "", query: "", domain: domain });
    const getProducts = useGetproductsDetQuery({ user_id: useremail });
    //console.log(getBlogsCount);
    let totalPages = Math.ceil((getBlogsCount?.data ?? 0) / pageSize);
    const [totalPagesFiltered, setTotalPagesFiltered] = useState(totalPages);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignListDatas, setCampaignListDatas] = useState<any>();
    const [campaignListMutation] = useCampaignListMutation();
    // const getBlogs = useGetBlogDetailsAdminQuery({ limitval: pageSize, skipval: (currentPage - 1) * pageSize, query: searchQuery });
    const user_email = localStorage.getItem('user_email');
    const getBlogs = useGetgeneratedBlogListingsQuery({promptid: promptid, userid: user_email || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize });
    const getCampaignBasedBlogs = useGetBlogsByCampaignQuery({ campaignid: selectedCampaign || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize });

    const [publishUnpublishCountMutation] = usePublishUnpublishCountMutation();
    const [bulkyblogspublishingMutation] = usePublishBulkyBlogsMutation();

    const [publishedUnpublishedCount, setPublishedUnpublishedCount] = useState<any>();

    const [getProduct, getProductState] = useLazyGetProductQuery();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [wishRemCount, setWishRemCount] = useState(0);
    const [subscriptionLevel, setSubscriptionLevel] = useState(localStorage.getItem('subscription_level'));

    const [blogImg, setBlogImg] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isDraftOrPublishLoading, setIsDraftOrPublishLoading] = useState<boolean>(false);
    const [prevImgLoader, setPrevImgLoader] = useState<boolean>(false);


    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
        },
    ]);

    const [isWideScreen, setIsWideScreen] = useState(false);

    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */


    const campaignRes = async () => {
        try {
            const response = await campaignListMutation({ email: user_email || '' });
            if ('data' in response) {
                setCampaignListDatas(response.data);
                console.log('campaign list response=>', response.data)
            } else {
                console.error('Error fetching campaign List:', response.error);
            }
        } catch (error) {
            console.log('Error fetching campaign List:', error);
        }
    }

    useEffect(() => {
        campaignRes();
    }, []);

    useEffect(() => {
        if (campaignListDatas && campaignListDatas.length > 0) {
            setSelectedCampaign(campaignListDatas[0].campaign_id)
            fetchingPublishDatas(campaignListDatas[0].campaign_id);
            localStorage.setItem('campId', campaignListDatas[0].campaign_id);
        }
    }, [campaignListDatas]);

    const handlecampaignSelectionChange = (event: any) => {
        setSelectedCampaign(event.target.value);
        console.log('Selected campaign:', event.target.value);//getting selected campaign id here
        fetchingPublishDatas(event.target.value);
        localStorage.setItem('campId', event.target.value);
    };

    useEffect(() => {

        if (getProducts) {
            setProductDetail(getProducts.data);

            const productDet = getProducts.data?.map((product, index) => ({
                ...product
            }));

            setProductDetail(productDet)

        }
    }, [getProducts]);


    useEffect(() => {

        //console.log("productDetail....");

        const producturl = productDetail ? productDetail.map((product: any) => product.url ?? '') : '';
        setProductURL(producturl[0]);

    }, [productDetail])

    //console.log(getBlogs);
    useEffect(() => {
        if (getBlogs && getBlogs.data) {
            const updatedBlogs = getBlogs.data.map((blog, index) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            // Apply filtering if a blog title is selected
            if (selectedBlogTitle) {
                const filtered = updatedBlogs.filter(blog => blog.blog_title === selectedBlogTitle);
                setBlogs(filtered);
                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered);

            } else {
                setBlogs(updatedBlogs);
                console.log(updatedBlogs);
            }
        }
    }, [getBlogs, currentPage, selectedBlogTitle, pageSize]);
    console.log('CBlogs=>', blogs);

    useEffect(() => {
        if (selectedCampaign && getCampaignBasedBlogs && getCampaignBasedBlogs.data) {
            const updatedcampaignBlogs = getCampaignBasedBlogs.data.map((blog: any, index: any) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            console.log('campaignBlogs=>', updatedcampaignBlogs);
            // Apply filtering if a blog title is selected
            if (selectedCampaign) {
                const filtered = updatedcampaignBlogs.filter((blog: any) => blog.product_id === selectedCampaign);
                setBlogs(filtered);
                console.log('filtered val=>', filtered);
                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered);

            } else {
                setBlogs(updatedcampaignBlogs);
                //console.log(blogs);
            }
        }
    }, [getCampaignBasedBlogs, currentPage, selectedCampaign, pageSize]);


    //options for dropdown
    const dropdownList = blogs.map((blog) => blog.blog_title || blog.blog_title_draft);


    // Pagination

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
    }

    const generatePageNumbers = (totalPages: number, currentPage: number): (number | '...')[] => {
        const pageNumbers: (number | '...')[] = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || Math.abs(i - currentPage) < 3) {
                pageNumbers.push(i);
            } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
                pageNumbers.push('...');
            }
        }
        return pageNumbers;
    };

    const pageNumbers = generatePageNumbers(/* blogs.length > 0 ?  */((selectedBlogTitle ? totalPagesFiltered : totalPages)) /* : 0 */, currentPage);
    //
    //console.log(blogs);

    const handleFormSubmit = () => {
        // Call the feedbackMutation function with the form data as payload
        feedbackMutation(feedbackForm)
            .then((response) => {
                // console.log('Feedback sent successfully:', response);
                toast.success('FeedBack Sent Successfully!!');
                setIsPopupOpen(false);
                setFeedbackForm({
                    email: localStorage.getItem('user_email') || '',
                    rating: 0,
                    feedback: '',
                    productname: 'TrafficMonster',
                    date: currentDate
                });

            })
            .catch((error) => {
                console.error('Error sending feedback:', error);

            });
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // Update feedbackForm with the textarea value
        setFeedbackForm((prevForm) => ({
            ...prevForm,
            feedback: event.target.value,
        }));

    };

    const handleRating = (event: number) => {
        // Update feedbackForm with the textarea value
        setFeedbackForm((prevForm) => ({
            ...prevForm,
            rating: event
        }));

    };

    //Edit functionality in Listing
    const handleEditClick = (blog: IGetBlogsAdmin) => {
        //console.log('blog=>', blog);
        setSelectedBlog(blog);
        setOpen(true);

        const mode = blog.mode || 'draft';

        let updatedBlogDet = blog.blog_det_draft ? blog.blog_det_draft : blog.blog_det;


        //do some formatting for blog details
        let htmlstring = "<div>";


        {
            (updatedBlogDet) ? updatedBlogDet.split('\n\n').map((paragraph: any, idx: any) => {

                paragraph = paragraph.replace(/\*\*/g, '');
                paragraph = paragraph.replace(/\#\#\#/g, '');
                paragraph = paragraph.replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '');
                paragraph = paragraph.replace(/\"/g, '');
                paragraph = paragraph.replace(/:/g, ' ');

                if (paragraph.includes('://') || paragraph.includes('//')) {
                    let newBlogDet = (
                        <div key={idx}><div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h5>", "</br><h5>") }} /><br /></div>
                    );
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);

                } else if (!paragraph.match(/<h5>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {

                    let newBlogDet = (
                        <div key={idx}>
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />
                        </div>
                    );
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                } else if (paragraph.includes(':')) {
                    if (paragraph.indexOf(':') < 100) {
                        let parts = paragraph.split(':');
                        let label = parts[0];
                        label = label.replace('<h5>', '');
                        label = label.replace('</h5>', '');
                        label = label.replace('<br>', '');
                        let content = "";
                        if (parts.slice(1).indexOf(":") >= 0) {
                            content = parts.slice(1).join(':');
                        } else
                            content = parts[1];
                        content = content.replace('<h5>', '');
                        content = content.replace('</h5>', '');
                        content = content.replace('<br>', '');
                        let newBlogDet = (
                            <div key={idx}>
                                <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else {
                        let newBlogDet =
                            (
                                <div key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                                </div>
                            );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    }
                } else {
                    let modifiedParagraph = "";
                    let newparagraph = paragraph.split('\n');
                    if (newparagraph[0].length < 100) {
                        if (newparagraph[0].indexOf("<h5>") !== -1)
                            modifiedParagraph = newparagraph[0];
                        else
                            modifiedParagraph = "<h5>" + newparagraph[0] + "</h5>";
                        for (var i = 1; i < newparagraph.length; i++) {
                            modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                    } else {
                        for (var i = 0; i < newparagraph.length; i++) {
                            modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                        modifiedParagraph = modifiedParagraph.replace("<h5>", "");
                        modifiedParagraph = modifiedParagraph.replace("</h5>", "");
                        modifiedParagraph = modifiedParagraph.replace("<div", "<div>");
                        modifiedParagraph = modifiedParagraph.replace("</div>", "</div>");
                    }

                    let newBlogDet = (<div key={idx}><div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} /> <br /></div>);
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    return (
                        ""
                    );
                }
            }) :
                blog.blog_det_draft?.split('\n\n').map((paragraph: any, idx: any) => {

                    paragraph = paragraph.replace(/\*\*/g, '');
                    paragraph = paragraph.replace(/\#\#\#/g, '');
                    paragraph = paragraph.replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '');
                    paragraph = paragraph.replace(/\"/g, '');
                    paragraph = paragraph.replace(/:/g, ' ');

                    if (paragraph.includes('://') || paragraph.includes('//')) {
                        let newBlogDet = (
                            <div key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h5>", "<h5>") }} />
                                <br />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else if (!paragraph.match(/<h5>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {
                        let newBlogDet = (
                            <div key={idx}>
                                <br />
                                <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else if (paragraph.includes(':')) {
                        if (paragraph.indexOf(':') < 100) {
                            let parts = paragraph.split(':');
                            let label = parts[0];
                            label = label.replace('<h5>', '');
                            label = label.replace('</h5>', '');
                            label = label.replace('<br>', '');
                            let content = "";
                            if (parts.slice(1).indexOf(":") >= 0) {
                                content = parts.slice(1).join(':');
                            } else
                                content = parts[1];
                            content = content.replace('<h5>', '');
                            content = content.replace('</h5>', '');
                            content = content.replace('<br>', '');
                            let newBlogDet = (
                                <div key={idx}>
                                    <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />
                                </div>
                            );
                            htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                        } else {
                            let newBlogDet = (
                                <div key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                                </div>
                            );
                            htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                        }
                    } else {
                        let modifiedParagraph = "";
                        let newparagraph = paragraph.split('\n');
                        if (newparagraph[0].length < 100) {
                            if (newparagraph[0].indexOf("<h5>") !== -1)
                                modifiedParagraph = newparagraph[0];
                            else
                                modifiedParagraph = "<h5>" + newparagraph[0] + "</h5>";
                            for (var i = 1; i < newparagraph.length; i++) {
                                modifiedParagraph = modifiedParagraph + newparagraph[i];
                            }
                        } else {
                            for (var i = 0; i < newparagraph.length; i++) {
                                modifiedParagraph = modifiedParagraph + newparagraph[i];
                            }
                            modifiedParagraph = modifiedParagraph.replace("<h5>", "");
                            modifiedParagraph = modifiedParagraph.replace("</h5>", "");
                            modifiedParagraph = modifiedParagraph.replace("<div", "<div>");
                            modifiedParagraph = modifiedParagraph.replace("</div>", "</div>");
                        }
                        let newBlogDet = (
                            <div key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} />
                                <br />
                            </div>
                        );

                        return ("");
                    }
                })
        }

        htmlstring = htmlstring + "</div>";

        htmlstring = htmlstring.replace(/<h5>/g, "<h3>");
        htmlstring = htmlstring.replace(/<\/h5>/g, "</h3>");


        //formatting ends
        let user_image = userDets.user_image ? userDets.user_image : '';
        let social_url = userDets.social_url ? userDets.social_url : '';

        /*if (mode === 'Live') {
            setUpdateBlogs({
                id: blog._id,
                blog_title: blog.blog_title,
                blog_image_path: blog.blog_image_path,
                blog_image_string: blog.blog_image_string,
                blog_summary: blog.blog_summary,
                blog_det: htmlstring,
                url: blog.url,
                publish_date: blog.publish_date,
                author: blog.author ? blog.author : userDets.name,
                author_image: blog.author_image ? blog.author_image : user_image,
                author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : social_url,
                cta_label: blog.cta_label,
                cta_url: blog.cta_url
            });

        }
        else {*/
        setUpdateBlogs({
            id: blog._id,
            blog_title: blog.blog_title_draft ? blog.blog_title_draft : blog.blog_title,
            blog_image_path: blog.blog_image_path_draft ? blog.blog_image_path_draft : blog.blog_image_path,
            blog_image_string: blog.blog_image_string,
            blog_summary: blog.blog_summary_draft ? blog.blog_summary_draft : blog.blog_summary,
            blog_det: htmlstring,
            url: blog.url_draft ? blog.url_draft : blog.url,
            publish_date: blog.publish_date,
            author: blog.author ? blog.author : userDets.name,
            author_image: blog.author_image ? blog.author_image : user_image,
            author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : social_url,
            cta_label: blog.cta_label,
            cta_url: blog.cta_url
        });
        //console.log(newBlogDet);
        //}


    };


    //popup datas update functionality
    /*  const handleUpdate = async () => {
         try {
             const updatedBlog = await blogDetailsUpdateMutation({
                 id: selectedBlog?._id || '',
                 blog_title: updateblogs.blog_title,
                 blog_image: updateblogs.blog_image,
                 blog_summary: updateblogs.blog_summary,
                 blog_det: updateblogs.blog_det,
                 url: updateblogs.url,
             });
             toast.success('Blog saved to draft Successfully!!');
             setOpen(false);
         } catch (error) {
             console.error('Error updating blog:', error);
         }
     }; */
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setImageFile(file);

            const objectUrl = URL.createObjectURL(file);
            setBlogImg(objectUrl);
        }
    };

    useEffect(() => {

        return () => {
            if (blogImg) {
                URL.revokeObjectURL(blogImg);
            }
        };
    }, [blogImg]);

    const handlesaveDraft = async () => {
        /* const updatedBlog = await blogDetailsDraftMutation({
            id: selectedBlog?._id || '',
            blog_title: updateblogs.blog_title,
            blog_image_string: updateblogs.blog_image_string,
            blog_image: updateblogs.blog_image_string,
            blog_summary: updateblogs.blog_summary,
            blog_det: updateblogs.blog_det,
            url: updateblogs.url,
            author: updateblogs.author,
            author_image: userDets.author_image ? userDets.author_image : 'undefined',
            author_url: updateblogs.author_url,
            cta_label: updateblogs.cta_label,
            cta_url: updateblogs.cta_url
        }); */

        setIsDraftOrPublishLoading(true);
        const formData = new FormData();
        formData.append('id', selectedBlog?._id || '');
        formData.append('blog_title', updateblogs.blog_title);
        formData.append('blog_image_string', updateblogs.blog_image_string);
        if (imageFile) {
            formData.append('blog_image', imageFile);
        }
        formData.append('blog_summary', updateblogs.blog_summary);
        formData.append('blog_det', updateblogs.blog_det);
        formData.append('url', updateblogs.url);
        formData.append('cta_url', updateblogs.cta_url);
        formData.append('cta_label', updateblogs.cta_label);
        formData.append('author', updateblogs.author);
        formData.append('author_image', updateblogs.author_image);
        formData.append('author_url', updateblogs.author_url);
        try {

            /* if ('data' in updatedBlog) {
                const datas = updatedBlog.data.status;
                if (datas === 'success') {
                    toast.success('Blog saved to draft Successfully!!');
                    window.location.reload();
                } else {
                    toast.error('Something went wrong!');
                }
            } */
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/savecontentasdraft', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                toast.success('Blog saved to draft Successfully!!');
                window.location.reload();
            } else {
                toast.error('Something went wrong!');
            }
            setOpen(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        } finally {
            setIsDraftOrPublishLoading(false);
        }
    };

    const handlePublished = async () => {
        /*  const updatedBlog = await blogDetailsPublishMutation({
             id: selectedBlog?._id || '',
             blog_title: updateblogs.blog_title,
             blog_image_string: updateblogs.blog_image_string,
             blog_image: updateblogs.blog_image_string,
             blog_summary: updateblogs.blog_summary,
             blog_det: updateblogs.blog_det,
             url: updateblogs.url,
             cta_url: updateblogs.cta_url,
             cta_label: updateblogs.cta_label,
             author: updateblogs.author,
             author_image: updateblogs.author_image,
             author_url: updateblogs.author_url
         }); */
        setIsDraftOrPublishLoading(true);
        const formData = new FormData();
        formData.append('id', selectedBlog?._id || '');
        formData.append('blog_title', updateblogs.blog_title);
        formData.append('blog_image_string', updateblogs.blog_image_string);
        if (imageFile) {
            formData.append('blog_image', imageFile);
        }
        formData.append('blog_summary', updateblogs.blog_summary);
        formData.append('blog_det', updateblogs.blog_det);
        formData.append('url', updateblogs.url);
        formData.append('cta_url', updateblogs.cta_url);
        formData.append('cta_label', updateblogs.cta_label);
        formData.append('author', updateblogs.author);
        formData.append('author_image', updateblogs.author_image);
        formData.append('author_url', updateblogs.author_url);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/publishcontent', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            /* if ('data' in updatedBlog) {
                const datas = updatedBlog.data.status;
                if (datas === 'success') {
                    toast.success('Blog Published Successfully!!');
                    window.location.reload();
                } else {
                    toast.error('Something went wrong!');
                }
            } */
            if (response.data.status === 'success') {
                toast.success('Blog Published Successfully!!');
                window.location.reload();
            } else {
                toast.error('Something went wrong!');
            }
            setOpen(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        } finally {
            setIsDraftOrPublishLoading(false);
        }
    };


    useEffect(() => {

        //console.log(sendBlogId);
        //
        //console.log(status);
    }, [blogPreviewValues, sendBlogId, status])

    const encodedContent = encodeURIComponent(ContentGeneration.prompt).replace(/%2B/g, '+');


    const [isLoading, setIsLoading] = useState(false);

    const toggleLoading = (value: boolean) => {
        setIsLoading(value);
    };

    const handleGenerateContent = async () => {
        try {
            toggleLoading(true);
            const updatedBlog: any = await generateBlogsMutation({
                cat: ContentGeneration.cat,
                title: ContentGeneration.title,
                prompt: encodedContent

            });
            const blogPrevValues = updatedBlog.data.msg;
            const blogId = updatedBlog.data.blogid;
            const responsestatus = updatedBlog.data.status;
            setBlogId(blogId);
            setBlogPrevValues(blogPrevValues);
            setSendBlogId(blogId)
            setStatus(responsestatus);
            toast.success('Succesfully Generated a Content');
            toggleLoading(false);
            // setOpenAddNew(false)
        } catch (error) {
            console.error('Error generating blog:', error);
            toggleLoading(false);
        }
    };

    const handleSave = async (blogId: string) => {
        try {
            if (blogPreviewValues) {
                const generatedBlog = await blogDetailsPublishMutation({
                    id: blogId,
                    blog_title: blogPreviewValues.blog_title_draft,
                    blog_image_string: blogPreviewValues.blog_image_string,
                    blog_image: updateblogs.blog_image_string,
                    blog_summary: blogPreviewValues.blog_summary_draft,
                    blog_det: blogPreviewValues.blog_det_draft,
                    url: blogPreviewValues.url_draft,
                    cta_url: updateblogs.cta_url,
                    cta_label: updateblogs.cta_label,
                    author: updateblogs.author,
                    author_image: updateblogs.author_image,
                    author_url: updateblogs.author_url
                });
                toast.success('Saved Successfully!!');
                setOpenAddNew(false);

            } else {
                console.error('blogPreviewValues is null or undefined');
            }
        } catch (error) {
            console.error('Error updating blog:', error);
        }
    }




    const [checked, setChecked] = useState(false);
    const [regenerateBusinessBlogMutation] = useRegenerateBusinessBlogMutation();
    const [isloader, setIsLoader] = useState<LoadingStates>({});




    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }

    const staticTypeOptions = ['Blogs']

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, false] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            // [{ 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],  // Include alignment options
        ],
    };



    const handleAddNew = () => {
        setOpenAddNew(true);
        setBlogPrevValues({
            blog_det_draft: '',
            blog_image_path_draft: '',
            blog_image_string: '',
            blog_summary_draft: '',
            blog_title_draft: '',
            cat: '',
            image_updated: '',
            mode: '',
            url_draft: '',
            blogid: '',
        })

    }


    const [deleteBlogMutation] = useDeleteBlogMutation();

    const handleDelete = async (blog_id: string) => {
        const confirmed = window.confirm('Are you sure, you want to delete this blog?');
        if (confirmed) {
            try {
                const response = await deleteBlogMutation({ blog_id });
                //console.log(response);
                if ('data' in response) {
                    const datas = response.data;
                    if (datas.status === 'success') {
                        toast.success('Blog Deleted successfully');

                        setBlogs(prevBlogs => {
                            const updatedBlogs = prevBlogs.filter(blog => blog._id !== blog_id);

                            // Update serial numbers for the remaining blogs
                            return updatedBlogs.map((blog, index) => ({
                                ...blog,
                                serialNumber: index + 1,
                            }));
                        });
                    }
                    else {
                        toast.error('Failed to delete blog');
                    }
                } else {
                    toast.error('Failed to delete blog');
                }
            } catch (error) {
                console.error('Error deleting blog', error);
                toast.error('An error occurred while deleting the blog');
            }
        }
    }
    //console.log('updated blogs val=>', updateblogs);

    const handleViewClick = (blogId: string, productId: string, mode: string, statichtml: boolean, canonical_url: string) => {
        let url = `/PreviewBlogs1/${blogId}?campId=${productId}`;
        if (mode=="Live" && statichtml==true)
            url = canonical_url;
        window.open(url, '_blank');
        // window.open(`/PreviewBlogs1/${blogId}`, '_blank');
    }

    const handelShareClick = async (blog_id: string) => {
        /* navigator.clipboard.writeText(`http://localhost:3000/blogs/${url}`).then(() => {
            toast.success('URL Copied to Clipboard');
        }) */
        /* const blogurl = (url.charAt(0) === '-' || url.charAt(url.length - 1) === '-' ? url.replace(/^-+|-+$/g, '') : url);
        const currentDomain = window.location.hostname;

        navigator.clipboard.writeText(`https://${currentDomain}/blog/${blogurl}.htm`).then(() => {
            toast.success('URL Copied to Clipboard');
        }) */
        try {
            setIsLoadingCopied(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: true
            }));
            const copyingurl = await copySharingUrlMutation({ blog_id });
            //console.log('copyurl resp=>', copyingurl);
            if ('data' in copyingurl) {
                const datas = copyingurl.data;
                if (datas.status === 'success') {
                    navigator.clipboard.writeText(datas.url).then(() => {
                        toast.success('Page Published. Click the View icon to see the page');
                        window.location.reload();
                    });
                } else {
                    toast.error('Something went wrong!');
                }
            }
        } catch (error) {
            console.error('Error', error);
        } finally {
            setIsLoadingCopied(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: false
            }));
        }
    }


    const handleToggleClick = () => {
        navigate('/codegenie/blogs-list');
    }
    const handleToggleClick1 = () => {
        navigate('/codegenie/generated-blogs');
    }

    const handleRegeneratingBlogClick = async (blog_id: string) => {
        setIsLoader(prevLoadingStates => ({
            ...prevLoadingStates,
            [blog_id]: true
        }));
        try {
            const response = await regenerateBusinessBlogMutation({ blog_id });
            //console.log('response of regeneration=>', response);
            if ('data' in response) {
                const regeneratedResp = response.data;
                if (regeneratedResp.status === 'success') {
                    window.location.reload();
                    toast.success(regeneratedResp.msg);

                }
            }
        } catch (error) {
            console.error('Error deleting blog', error);
        } finally {
            setIsLoader(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: false
            }));
        }
    }

    const handleBlogSelect = (index: any) => {
        setSelectedBlogIndex(index);
    }


    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: useremail });

            if ('data' in response) {
                // const datas = response.data.data;
                setUserDets(response.data.data);

            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {

        fetchData();
    }, []);

    const handleCheckBoxChange = (event: any, rowId: any) => {
        setIsChecked((prevState: any) => ({
            ...prevState,
            [rowId]: event.target.checked
        }));
        setIsCheckBoxClicked(true);
    };

    const handleHeaderCheckboxChange = (event: any) => {
        const updatedIsChecked: any = {};
        const isChecked = event.target.checked;
        blogs.forEach((blog) => {
            updatedIsChecked[blog._id] = isChecked;
        });
        setIsChecked(updatedIsChecked);
        setIsCheckBoxClicked(true);
    };


    const selectedIds = Object.keys(isChecked).filter((key) => isChecked[key]).join(',');
    console.log('selected checkboxes=>', selectedIds);
    const isAnyCheckBoxChecked = Object.values<boolean>(isChecked).some((isChecked: boolean) => isChecked);
    //console.log('userImage=>', userDets?.user_image);

    const fetchingPublishDatas = async (campaignId: any) => {
        console.log('campaignid from pubUnpub count=>', campaignId);
        try {
            const responseCount = await publishUnpublishCountMutation({ email: useremail, campaignid: campaignId });
            console.log('publish count=>', responseCount);
            if ('data' in responseCount) {
                const datas = responseCount.data;
                console.log(datas);
                setPublishedUnpublishedCount(datas);
            }
        } catch (error) {
            console.log('Error fetching publishing counts data:', error);
        }
    }
    useEffect(() => {
        fetchingPublishDatas('');
    }, []);

    const handleMultiplePublishing = async () => {
        setIsmultipleBlogsLoading(true);
        try {
            const response = await bulkyblogspublishingMutation({ blog_ids: selectedIds });
            if ('data' in response) {
                const datas = response.data;
                if (datas.status === 'success') {
                    toast.success('Blogs published successfully!');
                    window.location.reload();
                }
            }
        } catch (error) {
            toast.error('Error:' + String(error));
        } finally {
            setIsmultipleBlogsLoading(false);
        }
    }

    useEffect(() => {
        localStorage.removeItem('prevImg');
    }, [])

    const handlePreviewBlogClick = async (blog_image: any, blog_id: string) => {

        if (blog_image) {
            setPrevImgLoader(true);
            const formData = new FormData();
            if (!blog_image) {
                const emptyFile = new Blob([''], { type: 'text/plain' });
                formData.append('blog_image', emptyFile, 'empty.txt');
            } else {
                formData.append('blog_image', blog_image);
            }
            formData.append('blog_id', blog_id);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                console.log('apiurl', apiUrl);
                const response = await axios.post(apiUrl + '/api/prompts/getBlogDetwihtImage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('API response:', response.data.status);
                localStorage.setItem('prevImg', response.data.temp_image_path);
            } catch (error) {
                console.error('Error during API call:', error);
            } finally {
                setPrevImgLoader(false);
                window.open(`/PreviewBlogs/${updateblogs.id}`, '_blank');
            }
        } else {
            window.open(`/PreviewBlogs/${updateblogs.id}`, '_blank');
        }
    };

    return (
        <>
            <Header />
            <div style={{ margin: '1%' }}>
                <Grid container>

                    {isMobile ? <Grid item xs={12} lg={5}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={6} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{color: '#fff', fontSize: '13px', fontWeight: 900}}>Current Plan:</p>
                            </Grid>
                            <Grid item xs={6} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p /* style={{ border: '1px solid #4BA5EB', borderRadius: '20px', paddingLeft: '10%', paddingRight: '10%', textAlign: 'center', width: isMobile ? '100%' : '150%' }} */>{/* freePlann === '1' ? 'FREE' :  */(subscriptionLevel === 'level_1' || subscriptionLevel === 'level_4' ? 'BASIC' : subscriptionLevel === 'level_2' || subscriptionLevel === 'level_5' ? 'PRO' : subscriptionLevel === 'level_3' || subscriptionLevel === 'level_6' ? 'TRAFFIC HACKER' : 'FREE')}</p>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ display: 'flex', justifyContent: 'left' }}>
                                {
                                    (parseInt(localStorage.getItem('free_plan') || '0') === 0 || parseInt(localStorage.getItem('free_plan') || '1') || (wishRemCount <= 0 || localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5")) && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                color: 'white',
                                                width: isMobile ? '100%' : '70%',
                                                height: '30px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={() => {
                                                const value = {
                                                    _id: "",
                                                    product_name: "CodeGenie",
                                                    product_module: genieSelector.module ? genieSelector.module : "",
                                                    userid: "",
                                                    productid: ""
                                                };
                                                getProduct(value);
                                                localStorage.setItem('fromUpgrade', 'true');
                                                navigate('/pricing');
                                            }}>
                                            {(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5") ? "Upgrade" : "Upgrade"}
                                        </Button>
                                    )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="outlined"
                                    startIcon={
                                        <SvgIcon>
                                            <FeedBackIcon />
                                        </SvgIcon>
                                    }
                                    sx={{
                                        color: "white",
                                        marginLeft: isMobile ? '' : '26%',
                                        width: isMobile ? '100%' : '154px'
                                    }}
                                    onClick={() => {
                                        setIsPopupOpen(true);
                                    }}>
                                    Give FeedBack
                                </Button>
                            </Grid>
                        </Grid>
                        <hr></hr>
                        {!isMobile ? <p style={{ color: '#fff' }}>Blog Preview</p> : ''}
                        {/* <TableContainer style={{ border: '1px solid #0F74BF', maxWidth: isMobile ? '98%' : '99%', margin: isMobile ? '1%' : '' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ textAlign: 'center', backgroundColor: '#0F74BF' }}>Published</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center', backgroundColor: '#0F74BF' }}>Unpublished</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center', backgroundColor: '#0F74BF' }}>Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{publishedUnpublishedCount && publishedUnpublishedCount.published}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{publishedUnpublishedCount && publishedUnpublishedCount.unpubhished}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{publishedUnpublishedCount && publishedUnpublishedCount.total}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <LoadingButton
                            startIcon={
                                <SvgIcon>
                                    <FeedBackIcon />
                                </SvgIcon>
                            }
                            sx={{
                                background:
                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                marginLeft: 5,
                                color: "white",
                                paddingX: "20px",
                                paddingY: "12px",
                                borderRadius: "4px",
                                width: "fit-content",
                                float: 'right',
                                margin: '2% 0%',
                                marginRight: '1%'
                            }}
                            onClick={() => {
                                setIsPopupOpen(true);
                            }}>
                            Submit FeedBack
                        </LoadingButton> */}
                    </Grid> : ''}

                    <Grid item xs={12} lg={7}>
                        <Box
                            paddingY={5}
                            display="flex"
                            alignItems="left"
                            flexDirection="column"
                            padding={'3%'}
                            maxHeight={isMobile ? 185 : 110}
                        >
                            <p style={{ fontWeight: 'bolder', color: '#fff' }}>Select Campaign</p>

                            <Select
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    color: 'primary.main',
                                    '& .MuiSelect-icon': {
                                        color: '#fff',
                                    },
                                }}
                                value={selectedCampaign}
                                onChange={handlecampaignSelectionChange}
                            >
                                {campaignListDatas?.map((campaign: any) => (
                                    <MenuItem key={campaign.campaign_id} value={campaign.campaign_id}>
                                        {campaign.campaign_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/*  <p style={{ fontWeight: 'bolder' }}>To publish your blog, Click
                                    <span style={{ padding: '1%' }}>
                                        <Tooltip title="Publish">
                                            <IconButton aria-label="share" size="small" style={{ backgroundColor: '#CBCBCB', borderRadius: '15%' }}>
                                                <ShareIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </span><span>this icon below.</span>

                                    {ismulipleBlogsLoading ? <LoadingButton
                                            sx={{
                                                background:
                                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                color: "white",
                                                paddingX: "20px",
                                                paddingY: "12px",
                                                borderRadius: "4px",
                                                width: "fit-content",
                                            }}>
                                            <CircularProgress style={{width: '20px', height: '20px'}}/>
                                        </LoadingButton> : (isAnyCheckBoxChecked && isAnyCheckBoxChecked && (
                                        <LoadingButton
                                            sx={{
                                                background:
                                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                color: "white",
                                                paddingX: "20px",
                                                paddingY: "12px",
                                                borderRadius: "4px",
                                                width: "fit-content",
                                            }}
                                            onClick={handleMultiplePublishing}>
                                            Publish
                                        </LoadingButton>))}
                                </p> */}

                            <Modal
                                open={openPaidModal}
                                onClose={() => setOpenPaidModal(false)}
                                aria-labelledby="child-modal-title"
                                aria-describedby="child-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: "absolute" as "absolute",
                                        top: "50%",
                                        left: "50%",
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        transform: "translate(-50%, -50%)",
                                        width: isMobile ? "95%" : 600,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        pt: 2,
                                        px: 4,
                                        pb: 3,
                                    }}
                                >
                                    <p id="child-modal-description">Sorry! you can regenerate a blog only from a paid plan.</p>
                                    <Stack flexDirection="row">
                                        <Box sx={{ display: "inline-block", margin: "0 10px" }}>
                                            <PayNowButton></PayNowButton>
                                        </Box>

                                        <Button
                                            onClick={() => {
                                                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                                                setOpenPaidModal(false);
                                            }}
                                            variant="contained"
                                            sx={{ mr: 2 }}
                                        >
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Modal>

                            <Modal open={isPopupOpen} ><Box sx={{
                                position: "absolute" as "absolute",
                                top: "50%",
                                left: "50%",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                transform: "translate(-50%, -50%)",
                                width: isMobile ? "95%" : 600,
                                bgcolor: "background.paper",
                                border: "2px solid #000",
                                boxShadow: 24,
                                pt: 2,
                                px: 4,
                                pb: 3,
                            }}><div style={{ padding: '3%' }}>How satisfied are you with our TrafficMonster?</div>

                                <div style={{ padding: '3%' }}>{ratings.map((rating, index) => (
                                    <Button style={{ backgroundColor: rating <= feedbackForm.rating ? "#187BC6" : '#FFC107', color: '#FFFF', margin: '5px', minWidth: '50px' }} onClick={() => handleRating(rating)}>{rating}</Button>

                                ))}</div>

                                <TextareaAutosize
                                    aria-label="Comments"
                                    minRows={3}
                                    placeholder="Enter your comments(Optional)"
                                    onChange={handleTextAreaChange}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                />
                                <div style={{ paddingTop: '3%', width: '100%' }}>
                                    <Button
                                        onClick={() => {
                                            setIsPopupOpen(false);
                                        }}
                                        variant="contained"
                                        sx={{ mr: 2, /* backgroundColor: '#DC3545', */ float: 'right' }}
                                    >
                                        Close
                                    </Button>
                                    <Button variant="contained" sx={{ mr: 2,/*  backgroundColor: '#198754', */ float: 'right' }}
                                        onClick={handleFormSubmit}

                                    >
                                        Submit
                                    </Button></div></Box></Modal>


                            {/*  <Autocomplete
                                options={dropdownList}
                                sx={{ paddingTop: 5, "& label": { color: "black" }, "& input": { className: 'red-border1' } }}
                                value={selectedBlogTitle || ''}
                                onChange={(event, newValue) => {
                                    setSelectedBlogTitle(newValue || '');
                                    setSearchQuery(newValue || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        style={{ border: '1px solid #CBCBCB' }}
                                        {...params}
                                        placeholder="Type & search or select prompt from list"
                                        size="small"
                                        InputLabelProps={{
                                            className: 'red-label',
                                        }}
                                    />
                                )}
                            /> */}
                        </Box>

                        {/* <Divider /> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={2} style={{ display: isMobile ? 'flex' : '', justifyContent: isMobile ? 'right' : '' }}>
                        <DragIndicatorIcon style={{ cursor: 'pointer', fontSize: '40px', marginLeft: '150px' }} onClick={handleToggleClick1} />
                        <MenuIcon style={{ cursor: 'pointer', fontSize: '40px' }} onClick={handleToggleClick} />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={1}><VerticalIcon/></Grid> */}

                    {!isMobile ? <Grid item xs={12} lg={5}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={12} sm={2} style={{position: 'relative'}}/* style={{borderLeft: '1px solid #fff'}} */>
                                <p style={{color: '#fff', fontSize: '13px', fontWeight: 900}}>Current Plan:</p>
                                <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, borderLeft: '1px solid #475569', height: '2500px'}}></div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <p /* style={{ border: '1px solid #4BA5EB', borderRadius: '20px', paddingLeft: '10%', paddingRight: '10%', textAlign: 'center', width: isMobile ? '100%' : '150%' }} */>{/* freePlann === '1' ? 'FREE' :  */(subscriptionLevel === 'level_1' || subscriptionLevel === 'level_4' ? 'BASIC' : subscriptionLevel === 'level_2' || subscriptionLevel === 'level_5' ? 'PRO' : subscriptionLevel === 'level_3' || subscriptionLevel === 'level_6' ? 'TRAFFIC HACKER' : 'FREE')}</p>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ display: 'flex', justifyContent: 'left' }}>
                                {
                                    (parseInt(localStorage.getItem('free_plan') || '0') === 0 || parseInt(localStorage.getItem('free_plan') || '1') || (wishRemCount <= 0 || localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5")) && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                color: 'white',
                                                width: isMobile ? '100%' : '70%',
                                                height: '30px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={() => {
                                                const value = {
                                                    _id: "",
                                                    product_name: "CodeGenie",
                                                    product_module: genieSelector.module ? genieSelector.module : "",
                                                    userid: "",
                                                    productid: ""
                                                };
                                                getProduct(value);
                                                localStorage.setItem('fromUpgrade', 'true');
                                                navigate('/pricing');
                                            }}>
                                            {(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5") ? "Upgrade" : "Upgrade"}
                                        </Button>
                                    )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="outlined"
                                    startIcon={
                                        <SvgIcon>
                                            <FeedBackIcon />
                                        </SvgIcon>
                                    }
                                    sx={{
                                        color: "white",
                                        marginLeft: isMobile ? '' : (isWideScreen ? '12%' : '26%'),
                                        width: '154px'
                                    }}
                                    onClick={() => {
                                        setIsPopupOpen(true);
                                    }}>
                                    Give FeedBack
                                </Button>
                            </Grid>
                        </Grid>
                        <hr style={{marginLeft: isBrowser ? '-2%' : ''}}></hr>
                        <p style={{ color: '#fff' }}>Blog Preview</p>
                        {/* <TableContainer style={{ border: '1px solid #0F74BF', maxWidth: isMobile ? '98%' : '99%', margin: isMobile ? '1%' : '' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ textAlign: 'center', backgroundColor: '#0F74BF' }}>Published</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center', backgroundColor: '#0F74BF' }}>Unpublished</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center', backgroundColor: '#0F74BF' }}>Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{publishedUnpublishedCount && publishedUnpublishedCount.published}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{publishedUnpublishedCount && publishedUnpublishedCount.unpubhished}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{publishedUnpublishedCount && publishedUnpublishedCount.total}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <LoadingButton
                            startIcon={
                                <SvgIcon>
                                    <FeedBackIcon />
                                </SvgIcon>
                            }
                            sx={{
                                background:
                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                marginLeft: 5,
                                color: "white",
                                paddingX: "20px",
                                paddingY: "12px",
                                borderRadius: "4px",
                                width: "fit-content",
                                float: 'right',
                                margin: '2% 0%',
                                marginRight: '1%'
                            }}
                            onClick={() => {
                                setIsPopupOpen(true);
                            }}>
                            Submit FeedBack
                        </LoadingButton> */}
                    </Grid> : ''}
                </Grid>

                <Grid container spacing={2}>
                    {/* {!isMobile ?  */}<Grid item xs={12} sm={7}>
                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', marginTop: '1%', marginLeft: isMobile ? '1%' : '3%' }}>
                            <Grid xs={12} sm={8}>
                                <p style={{ marginLeft: isMobile ? '2%' : '', color: '#fff' }}>Campaign Summary</p>
                                <div style={{ border: '1px solid #41454F', borderRadius: '10px', padding: '1%', marginTop: isMobile ? '3%' : '', width: isMobile ? '94%' : '95%' }}>
                                    <table style={{ width: '100%', height: '76px' }}>
                                        <thead style={{ paddingBottom: '10px' }}>
                                            <tr>
                                                <th style={{ textAlign: 'center', borderRight: '1px solid #41454F', width: '30%', fontWeight: 'lighter' }}>Published:</th>
                                                <th style={{ textAlign: 'center', borderRight: '1px solid #41454F', width: '30%', fontWeight: 'lighter' }}>Unpublished:</th>
                                                <th style={{ textAlign: 'center', width: '30%', fontWeight: 'lighter' }}>Total:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid #41454F' }}><span style={{ padding: '2% 19%', borderRadius: '5px', fontSize: '32px', fontWeight: 'bolder', color: '#fff' }}>{publishedUnpublishedCount && publishedUnpublishedCount.published}</span></td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid #41454F' }}><span style={{ padding: '2% 19%', borderRadius: '5px', fontSize: '32px', fontWeight: 'bolder', color: '#fff' }}>{publishedUnpublishedCount && publishedUnpublishedCount.unpubhished}</span></td>
                                                <td style={{ textAlign: 'center' }}><span style={{ padding: '2% 19%', borderRadius: '5px', fontSize: '32px', fontWeight: 'bolder', color: '#fff' }}>{publishedUnpublishedCount && publishedUnpublishedCount.total}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={4} sx={{ display: 'flex', textAlign: 'center', margin: isMobile ? '3%' : '', alignItems: 'center', marginTop: '5%' }}>
                                <Button variant="outlined" style={{ color: '#fff', fontWeight: 'lighter', width: isMobile ? '94%' : '', marginLeft: isMobile ? '' : (isWideScreen ? '5%' : '13%') }}><a style={{ textDecoration: 'none', color: '#fff' }} href='https://youtu.be/y29AzqqbWRU' target="_blank"><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="white"
                                    // strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{ marginRight: '8px' }}
                                >
                                    <polygon points="10 17 15 12 10 7 10 17"></polygon>
                                    <circle cx="12" cy="12" r="10"></circle>
                                </svg>Site Overview Video </a></Button>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                    </Grid> {/*  : ''}  */}
                </Grid>

                <Grid container>
                    <Grid item xs={12} lg={7}>

                        {/*  <Box
                            paddingY={5}
                            display="flex"
                            alignItems="left"
                            flexDirection="column"
                            padding={'3%'}
                        >
                            <Autocomplete
                                options={dropdownList}
                                sx={{ paddingTop: 5, "& label": { color: "black" }, "& input": { className: 'red-border1' } }}
                                value={selectedBlogTitle || ''}
                                onChange={(event, newValue) => {
                                    setSelectedBlogTitle(newValue || '');
                                    setSearchQuery(newValue || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        style={{ border: '1px solid #CBCBCB' }}
                                        {...params}
                                        placeholder="Type & search or select prompt from list"
                                        size="small"
                                        InputLabelProps={{
                                            className: 'red-label',
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Divider /> */}
                        <div
                            className="card p-3 text-white"
                            style={{
                                // backgroundColor: "#282C39",
                                backgroundColor: "#1D252D",
                                marginBottom: "1.5rem",
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                marginTop: '4%',
                                width: isMobile ? '100%' : '97%'
                            }}
                        >
                            <div className="row">
                                <div className="col-md-8 col-lg-8 col-sm-12" /* style={{width: '57%'}} */>
                                    <Autocomplete
                                        options={dropdownList}
                                        sx={{ /* paddingTop: 5, */ "& label": { color: "black" }, "& input": { className: 'red-border1' } }}
                                        value={selectedBlogTitle || ''}
                                        onChange={(event, newValue) => {
                                            setSelectedBlogTitle(newValue || '');
                                            setSearchQuery(newValue || '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                // style={{ border: '1px solid #CBCBCB' }}
                                                {...params}
                                                placeholder="Search for blog"
                                                size="small"
                                                InputLabelProps={{
                                                    className: 'red-label',
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'white',
                                                        opacity: 1
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-12" /* style={{width: '57%'}} */>
                                    {ismulipleBlogsLoading ? <Button
                                        variant="outlined"
                                        sx={{
                                            /* background:
                                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)", */
                                            height: '41px',
                                            width: '100%',
                                            border: '1px solid #7A7A7A',
                                            color: "#7A7A7A",
                                            paddingX: "20px",
                                            paddingY: "12px",
                                            borderRadius: "4px",
                                            fontWeight: 'bolder',
                                            marginTop: isMobile ? '2%' : ''
                                        }}>
                                        <CircularProgress style={{ width: '20px', height: '20px' }} />
                                    </Button> : (isAnyCheckBoxChecked && (
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                height: '41px',
                                                width: '100%',
                                                /* background:
                                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)", */
                                                border: '1px solid #7A7A7A',
                                                color: "#7A7A7A",
                                                paddingX: "20px",
                                                paddingY: "12px",
                                                borderRadius: "4px",
                                                fontWeight: 'bolder',
                                                marginTop: isMobile ? '2%' : ''
                                            }}
                                            onClick={handleMultiplePublishing}>
                                            <ShareOutlinedIcon style={{ color: '#7A7A7A' }} />&nbsp;Publish
                                        </Button>))}
                                </div>
                                <div className="col-md-2 col-lg-2 col-sm-12" style={{ textAlign: 'right' }}>
                                    <DragIndicatorIcon style={{ cursor: 'pointer', fontSize: '40px' }} onClick={handleToggleClick1} />
                                    <MenuIcon style={{ cursor: 'pointer', fontSize: '40px' }} onClick={handleToggleClick} />
                                </div>
                            </div>
                            <TableContainer component={Paper} sx={{ /* margin: '1%', */ width: '100%', overflowX: 'auto', border: '1px solid #7A7A7A', marginTop: '3%' }}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead style={{/*  backgroundColor: ' #F2F6FA', */ border: "gray" }}>
                                        <TableRow>
                                            <StyledTableCell style={{ border: "gray", width: "10%" }} align="center"><Checkbox onChange={handleHeaderCheckboxChange} /></StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "30%" }} align="center">TITLE</StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "10%" }} align="center">STATUS</StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "10%" }} align="center">DATE</StyledTableCell>
                                            {/* <StyledTableCell style={{ border: "gray", width: "10%" }} align="center">IMPRESSIONS</StyledTableCell> */}
                                            {/* <StyledTableCell style={{ border: "gray" }} align="center">PLAGIARISM SCORE</StyledTableCell> */}
                                            {/* <StyledTableCell style={{ border: "gray", width: "10%" }} align="center">CLICKS</StyledTableCell> */}
                                            {/* <StyledTableCell style={{ border: "gray", width: "10%" }} align="center">ENGAGEMENT</StyledTableCell> */}
                                            <StyledTableCell style={{ border: "gray", width: "20%" }} align="center">ACTION</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {blogs && blogs.length > 0 ?
                                            (blogs.map((blog, index) => (
                                                <StyledTableRow key={blog._id} className={index % 2 === 0 ? 'blackAndWhite' : ''} style={{ /* backgroundColor: "transparent", */ border: selectedBlogIndex === index ? '1px solid #4BA5EB' : '', /* marginBottom: "1.5rem", */ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'/* , borderColor: selectedBlogIndex === index ? '#4BA5EB' : '#F9FBFC' */ }} onClick={() => handleBlogSelect(index)}>
                                                    <StyledTableCell component="th" scope="row" align="center" style={{ color: '#fff', border: "darkgray" }}>
                                                        <Checkbox
                                                            checked={isChecked[blog._id] || false}
                                                            onChange={(event) => handleCheckBoxChange(event, blog._id)}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'checkbox' }}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left" style={{ color: '#fff', border: "darkgray" }}>
                                                        {blog.blog_title.replace(/\*\*/g, '').replace(/"/g, '') || blog.blog_title_draft}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ color: '#fff', border: "darkgray" }} align="center">{blog.mode ? (blog.mode === 'Live' ? 'Published' : 'Unpublished') : "Draft"}</StyledTableCell>
                                                    <StyledTableCell style={{ color: '#fff', border: "darkgray" }} align="center">{blog.publish_date?.split("T")[0]}</StyledTableCell>
                                                    {/* <StyledTableCell style={{ color: '#fff', border: "darkgray" }} align="left">{blog.seo_score}</StyledTableCell> */}
                                                    {/* <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{'1'}</StyledTableCell> */}
                                                    {/* <StyledTableCell style={{ color: '#fff', border: "darkgray" }} align="left">{blog.total_views}</StyledTableCell> */}
                                                    {/* <StyledTableCell style={{ color: '#fff', border: "darkgray" }} align="left">{blog.rattings}</StyledTableCell> */}
                                                    <StyledTableCell align="center" style={{ cursor: 'pointer', border: "darkgray" }}>
                                                        <Tooltip title="Edit">
                                                            <EditIcon style={{ color: '#fff', marginRight: '7px' }} onClick={() => handleEditClick(blog)} />
                                                        </Tooltip>
                                                        <Tooltip title="View">
                                                            <span style={{ marginRight: '7px' }}>
                                                                <ViewIcon onClick={() => handleViewClick(blog._id, blog.product_id, blog.mode, blog?.statichtml, blog?.canonical_url)} />
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="Publish">
                                                            <span style={{ marginRight: '7px' }}>
                                                                {/* <DownloadIcon /> */}
                                                                {isLoadingCopied[blog._id] ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <ShareIcon onClick={() => handelShareClick(blog._id)} />}
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <span style={{ marginRight: '7px' }}>
                                                                <TrashCan style={{ color: 'red' }} onClick={() => handleDelete(blog._id)} />
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="Regenerate a Blog">
                                                            <span onClick={() => handleRegeneratingBlogClick(blog._id)}>
                                                                {isloader[blog._id] ? <CircularProgress size={20} /> : <RegenerateIcon />}
                                                            </span>
                                                        </Tooltip>
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            ))) : <StyledTableRow>
                                                <StyledTableCell style={{ width: '10%' }}>&nbsp;</StyledTableCell>
                                                <StyledTableCell style={{ width: '10%' }}>&nbsp;</StyledTableCell>
                                                <StyledTableCell style={{ width: '20%' }}>&nbsp;</StyledTableCell>
                                                <StyledTableCell style={{ width: '20%' }}>No data available</StyledTableCell>
                                                <StyledTableCell style={{ width: '20%' }}>&nbsp;</StyledTableCell>
                                                <StyledTableCell style={{ width: '10%' }}>&nbsp;</StyledTableCell>
                                                <StyledTableCell style={{ width: '10%' }}>&nbsp;</StyledTableCell>
                                            </StyledTableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div>&nbsp;</div>
                        <div style={{ textAlign: 'center' }}>
                            <LoadingButton
                                startIcon={
                                    <SvgIcon>
                                        <KeyboardArrowLeft />
                                    </SvgIcon>
                                }

                                sx={{
                                    paddingRight: '15px'
                                }}
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                            >

                            </LoadingButton>

                            {/* <span>{currentPage}</span> */}
                            {pageNumbers.map((page, index) => (
                                <span key={index} style={{ padding: '0 5px' }}>
                                    <span
                                        style={{
                                            cursor: page === '...' ? 'default' : 'pointer',
                                            fontWeight: (page === currentPage ? 'bold' : 'normal')
                                        }}
                                        onClick={() => {
                                            if (typeof page === 'number') setCurrentPage(page);
                                        }}
                                    >
                                        {page}
                                    </span>
                                </span>
                            ))}

                            <LoadingButton
                                startIcon={
                                    <SvgIcon>
                                        <KeyboardArrowRight />
                                    </SvgIcon>
                                }

                                sx={{
                                    paddingLeft: '35px'
                                }}
                                onClick={handleNextPage}
                                disabled={blogs.length < pageSize || currentPage === totalPagesFiltered}
                            >

                            </LoadingButton>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        {isMobile ? <p style={{ color: '#fff' }}>Blog Preview</p> : ''}
                    </Grid>
                    {/* </Grid>

                <Grid container> */}
                    <Grid item xs={12} lg={5} alignItems="flex-start" style={{ marginTop: isMobile ? '' : '-13rem' }}>
                        <div
                            className="card p-3 text-white"
                            style={{
                                // backgroundColor: "#282C39",
                                backgroundColor: "#1D252D",
                                // border: "1px solid #F9FBFC",
                                marginBottom: "1.5rem",
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                marginTop: '4%'
                            }}
                        >
                            <div style={{ maxHeight: 300, overflow: 'Hidden' }}><img className="card-img-top" style={{ maxWidth: isMobile ? '370px' : '' }} src={blogs && blogs[selectedBlogIndex] && blogs[selectedBlogIndex].blog_image_path} alt="" /></div>
                            <div className="card-body">
                                <h4 className="card-title" style={{ color: '#fff' }}>
                                    {blogs && blogs[selectedBlogIndex] && blogs[selectedBlogIndex].blog_title}
                                </h4>
                                <Typography
                                    className="card-text my-4"
                                    sx={{
                                        /* display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 3, */
                                        color: '#fff'
                                    }}
                                    variant="body1">
                                    <div dangerouslySetInnerHTML={{ __html: blogs && blogs[selectedBlogIndex] && blogs[selectedBlogIndex].blog_det }} />
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {/* Edit Popup */}
                <div>
                    <Modal open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description">
                        <Box sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "90%", // Adjusted width for responsiveness
                            maxWidth: 1000, // Maximum width
                            bgcolor: "#131825",
                            border: "none",
                            pt: 2,
                            px: 4,
                            pb: 3,
                            maxHeight: '90vh', // Limit height for smaller screens
                            overflow: 'auto'
                        }}>
                            <Stack width="100%">
                                <Grid container>
                                    <Grid item xs={12} lg={6}>
                                        <Typography variant='h5' fontWeight="bold" style={{ color: '#fff' }}>Edit Content</Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', padding: '1%' }}>
                                        <Button
                                            variant="outlined"
                                            sx={{ width: "100%", maxWidth: 200, paddingY: 1, marginRight: '2%', fontSize: '12px', color: '#fff' }}
                                            onClick={handlesaveDraft}
                                        >
                                            {isDraftOrPublishLoading ? <CircularProgress /> : 'Save As Draft'}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{ width: "100%", maxWidth: 200, paddingY: 1, marginRight: '2%', fontSize: '12px', color: '#fff' }}
                                            onClick={handlePublished}
                                        >
                                            {isDraftOrPublishLoading ? <CircularProgress /> : 'Save & Publish'}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{ width: "100%", maxWidth: 200, paddingY: 1, marginRight: '2%', fontSize: '12px', color: '#fff' }}
                                            onClick={() => setOpen(false)}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Divider style={{ height: '2px', backgroundColor: '#CBCBCB' }} />

                                <Grid container>
                                    <Grid item xs={12} lg={8} style={{ borderRight: "1px solid #CBCBCB", padding: '1%' }}>
                                        <Stack padding="1%">
                                            <Typography style={{ color: '#fff' }}>Blog Title</Typography>
                                            <TextField
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                style={{ width: '100%' }}
                                                value={/* selectedBlog ? selectedBlog.blog_title : '' */updateblogs.blog_title}
                                                onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_title: e.target.value })}></TextField>
                                        </Stack>
                                        <div style={{ display: 'none' }}>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>Blog Image</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%', display: 'none' }} value={/* selectedBlog ? selectedBlog.blog_image_string : '' */updateblogs.blog_image_string}
                                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_image_string: e.target.value })}></TextField>
                                            </Stack>
                                        </div>
                                        <Stack padding="1%">
                                            <Typography style={{ color: '#fff' }}>Blog Summary</Typography>
                                            <TextField
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                style={{ color: 'black', width: '100%' }}
                                                value={/* selectedBlog ? selectedBlog.blog_summary : '' */updateblogs.blog_summary}
                                                onChange={(e) => setUpdateBlogs({ ...updateblogs, blog_summary: e.target.value })}></TextField>
                                        </Stack>
                                        <Stack padding="1%">
                                            <Typography style={{ color: '#fff' }}>Blog Content</Typography>
                                            <div style={{ height: '300px', overflow: 'auto', border: '1px solid #ccc' }}>
                                                <ReactQuill /* fullWidth multiline rows={8} */
                                                    style={{ height: '85%', color: '#fff' }}
                                                    value={/* selectedBlog ? selectedBlog.blog_det : '' *//* decodedBlogDet */updateblogs.blog_det}
                                                    onChange={(value: any) => setUpdateBlogs({ ...updateblogs, blog_det: value })}
                                                    theme='snow'
                                                    modules={modules}
                                                />
                                            </div>
                                        </Stack>
                                        <Stack padding="1%" >
                                            <Typography style={{ color: '#fff' }}>Blog URL</Typography>
                                            <Grid container>
                                                <Grid item xs={12} sm={8} /* visibility="hidden" */>
                                                    <TextField
                                                        InputProps={{
                                                            style: customInputStyle,
                                                        }}
                                                        style={{ width: '100%' }} value={/* selectedBlog ? selectedBlog.url : '' */updateblogs.url?.charAt(0) === '-' || updateblogs.url?.charAt(updateblogs.url.length - 1) === '-' ? updateblogs.url.replace(/^-+|-+$/g, '') : updateblogs.url}
                                                        onChange={(e) => setUpdateBlogs({ ...updateblogs, url: e.target.value })}
                                                    ></TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"flex-end"}>
                                                    <Button
                                                        startIcon={
                                                            <SvgIcon>
                                                                <EyeIcon />
                                                            </SvgIcon>
                                                        }

                                                        sx={{
                                                            background:
                                                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                            marginLeft: 5,
                                                            color: "white",
                                                            paddingX: "20px",
                                                            paddingY: "12px",
                                                            borderRadius: "4px",
                                                            width: "fit-content",
                                                            marginTop: { xs: '1%', sm: 0 }
                                                        }}
                                                        onClick={() => handlePreviewBlogClick(imageFile, updateblogs.id)}
                                                    > {/* View Blog */}
                                                        {/* <Link to={`/PreviewBlogs/${updateblogs.id}`} target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', textDecoration: 'none' }}> */}{prevImgLoader ? <CircularProgress /> : 'Preview Blog'}{/* </Link> */}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Stack>

                                        <Stack padding="1%">
                                            <Typography style={{ color: '#fff' }}>Blog Image</Typography>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                //onChange={handleImageChange}
                                                style={{ width: '100%' }}
                                                onChange={handleImageChange}
                                            />
                                            {(blogImg || updateblogs.blog_image_path) && (
                                                <img src={blogImg ? blogImg : updateblogs.blog_image_path} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                            )}
                                        </Stack>

                                    </Grid>

                                    <Grid item xs={12} lg={4} style={{ padding: '1%' }}>
                                        <Stack padding="1%">
                                            <Typography variant="h6" fontWeight="bold" style={{ color: '#fff' }}>Article Details</Typography>
                                        </Stack>
                                        <Stack padding="1%">
                                            <Typography style={{ color: '#fff' }}>Type</Typography>
                                            <Autocomplete
                                                options={staticTypeOptions}
                                                sx={{ border: '#CBCBCB' }}
                                                renderInput={(params) => (
                                                    <TextField

                                                        style={{ width: '100%', border: '1px solid #CBCBCB' }}
                                                        {...params}
                                                        placeholder="Blogs"
                                                        // label="Blogs"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <div style={{ display: 'none' }}>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>Tags</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}></TextField>
                                            </Stack>
                                        </div>
                                        <Stack padding="1%">
                                            <Typography style={{ color: '#fff' }}>Date of Published</Typography>
                                            <TextField
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                value={updateblogs.publish_date}
                                                style={{ width: '100%' }}>{/* updateblogs?.publish_date.split("T")[0] */}</TextField>
                                        </Stack>
                                        <div>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>We strongly recommend that you add your info here to display on your blogs<br /><br /></Typography>
                                                <Typography style={{ color: '#fff' }}>Author</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}
                                                    value={updateblogs.author}
                                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, author: e.target.value })}></TextField>
                                            </Stack>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>Author profile page (ex: linkedin url)</Typography>
                                                {/* <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}></TextField> */}
                                                {userDets && userDets.user_image ? (
                                                    <img src={userDets?.user_image} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                                ) : (<><img src="https://images.code-genie.ai/images/no_user.jpg" alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} /><p>Upload an image here, <a href="/codegenie/settings">Go to Settings.</a></p></>)}
                                            </Stack>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>Author URL</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}
                                                    // value={updateblogs.author_url}
                                                    value={userDets && userDets.author_url ? userDets.author_url : "Update your URL on settings"}
                                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, author_url: e.target.value })}></TextField>
                                            </Stack>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>CTA Label</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}
                                                    value={updateblogs.cta_label}
                                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, cta_label: e.target.value })}></TextField>
                                            </Stack>
                                            <Stack padding="1%">
                                                <Typography style={{ color: '#fff' }}>CTA URL</Typography>
                                                <TextField
                                                    InputProps={{
                                                        style: customInputStyle,
                                                    }}
                                                    style={{ width: '100%' }}
                                                    value={updateblogs.cta_url}
                                                    onChange={(e) => setUpdateBlogs({ ...updateblogs, cta_url: e.target.value })}></TextField>
                                            </Stack>
                                        </div>



                                    </Grid>
                                </Grid>

                            </Stack>
                        </Box>
                    </Modal>
                </div>
                {/*  */}


                {/* Add New Popup */}
                <div>

                    <Modal open={openAddNew}
                        onClose={() => setOpenAddNew(false)}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <>
                            {isLoading && (
                                <Box position="absolute">
                                    <Backdrop
                                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={true}
                                    >
                                        <Box>
                                            <MagicWand />

                                            <Typography textAlign="center">Generating Content</Typography>

                                        </Box>
                                    </Backdrop>
                                </Box>
                            )}


                            <Box sx={{
                                position: "absolute" as "absolute",
                                top: "50%",
                                left: "50%",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                transform: "translate(-50%, -50%)",
                                width: /* isMobile ? "95%" : */ 'auto',
                                bgcolor: "white",
                                border: "1px solid #000",
                                boxShadow: 24,
                                pt: 2,
                                px: 4,
                                pb: 3,
                                height: '98%',
                                overflow: 'auto',
                            }}>

                                <Stack spacing={5}>
                                    <Stack flexDirection="row">
                                        <Typography
                                            fontWeight="bold"
                                            variant="h6"
                                            color="text.secondary"
                                            flexGrow={1}
                                            fontSize={25}
                                            style={{ color: 'black' }}                                >
                                            Generate New Content
                                        </Typography>
                                        <Stack flexDirection="row" gap={3}>
                                            {status === 'success' ?
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        sx={{ width: 90, paddingY: 1 }}
                                                        onClick={handleAddNew}
                                                    >
                                                        New
                                                    </Button>
                                                    <LoadingButton
                                                        variant="contained"
                                                        sx={{ width: 90, paddingY: 1 }}
                                                        // loading={updateState.isLoading}
                                                        type="submit"
                                                        onClick={() => handleSave(blogId)}
                                                    >
                                                        Save
                                                    </LoadingButton>
                                                </>
                                                : null}
                                            <LoadingButton
                                                variant="contained"
                                                sx={{ width: 90, paddingY: 1 }}
                                                // loading={updateState.isLoading}
                                                type="submit"
                                                onClick={() => setOpenAddNew(false)}
                                            >
                                                Close
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                    <Divider style={{ marginTop: '15px', height: '2px', backgroundColor: '#CBCBCB' }} />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{ color: 'black' }} fontWeight="bold" variant="h6" color="text.secondary" flexGrow={1} fontSize={20}>
                                                Settings
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        <Grid container>

                                            <Grid item xs={12}>
                                                <Stack flexDirection="row" gap={2}>
                                                    <Box alignItems="center" gap={1} >
                                                        <InputLabel id="product-label" style={{ fontWeight: 'normal', color: 'black' }}>Language</InputLabel>
                                                        <TextField
                                                            InputProps={{
                                                                style: customInputStyle,
                                                            }}
                                                            /* InputLabelProps={{
                                                                style: {
                                                                  color: 'black', // Set the desired color for the label
                                                                },
                                                              }} */
                                                            sx={{
                                                                width: {
                                                                    lg: '130px',
                                                                    xs: '100px'
                                                                }
                                                            }}
                                                            size="small"
                                                            select
                                                        // label="Language"
                                                        // ... other props
                                                        >
                                                            <MenuItem value="english">English</MenuItem>
                                                            <MenuItem value="spanish">Spanish</MenuItem>
                                                            <MenuItem value="french">French</MenuItem>
                                                            {/* ... menu items */}
                                                        </TextField>

                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="module-label" style={{ fontWeight: 'normal', color: 'black' }}>Products</InputLabel>
                                                        <TextField
                                                            InputProps={{
                                                                style: customInputStyle,
                                                            }}
                                                            size="small"
                                                            select
                                                            sx={{
                                                                width: {
                                                                    lg: '130px',
                                                                    xs: '100px'
                                                                }
                                                            }}

                                                            // label="Products"
                                                            onChange={(e) => setContentGeneration({ ...ContentGeneration, cat: e.target.value })}

                                                        // ... other props
                                                        >
                                                            <MenuItem value="Any Code">Any Code</MenuItem>
                                                            <MenuItem value="Smart Contract">Smart Contract</MenuItem>
                                                        </TextField>
                                                        {/* ... menu items */}


                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="prompt-name-label" style={{ fontWeight: 'normal', color: 'black' }}>Date</InputLabel>
                                                        <Box alignItems="center" display='flex'>

                                                            <TextField
                                                                InputProps={{
                                                                    style: customInputStyle,
                                                                }}
                                                                style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} size="small" type="date"></TextField>
                                                            <TextField
                                                                InputProps={{
                                                                    style: customInputStyle,
                                                                }}
                                                                style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                                                                size="small"
                                                                type="date"
                                                            // labelId="order-label"
                                                            // ... other props
                                                            />

                                                        </Box>
                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="order-label">&nbsp;</InputLabel>

                                                    </Box>
                                                    <Box alignItems="center" gap={1}>
                                                        <InputLabel id="order-label" style={{ fontWeight: 'normal', color: 'black' }}>Auto Generate</InputLabel>
                                                        <FormControlLabel
                                                            control={<Switch checked={checked} onChange={handleChange} color="primary" />}
                                                            label={checked ? <span className="blackLabel">On</span> : <span className="blackLabel">Off</span>}
                                                        />

                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                    </div>


                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography
                                                color="text.secondary"
                                                variant="h6"
                                                // fontWeight={400}
                                                fontWeight="bold"
                                                fontSize={20}
                                                style={{ color: 'black' }}
                                            >
                                                Text Prompts
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                fontSize={15}
                                                fontWeight={200}
                                                style={{ color: 'black' }}
                                            >
                                                Type prompt to create blogs
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            gap={2}
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <TextField
                                                // {...register("module_description")}
                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                placeholder="Title goes here"
                                                onChange={(e) => setContentGeneration({ ...ContentGeneration, title: e.target.value })}


                                            />
                                            <TextField

                                                InputProps={{
                                                    style: customInputStyle,
                                                }}
                                                // {...register("module_description")}
                                                placeholder="Type text prompt here"
                                                onChange={(e) => setContentGeneration({ ...ContentGeneration, prompt: e.target.value })}

                                            />
                                            <ReactQuill
                                                style={{ height: '300px', color: 'black' }}
                                                /* InputProps={{
                                                    style: customInputStyle,
                                                }} */
                                                placeholder="Blog preview shown here"
                                                value={blogPreviewValues?.blog_det_draft}
                                                theme='snow'
                                                modules={modules}

                                            />
                                        </Grid>
                                    </Grid>

                                    <Stack display="block">
                                        <LoadingButton

                                            startIcon={
                                                <SvgIcon>
                                                    <WishIcon />
                                                </SvgIcon>
                                            }

                                            sx={{
                                                background:
                                                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                marginLeft: 5,
                                                color: "white",
                                                paddingX: "20px",
                                                paddingY: "12px",
                                                borderRadius: "4px",
                                                width: "fit-content",
                                                float: "right",
                                                marginTop: '4%'
                                            }}
                                            onClick={handleGenerateContent}
                                        > {/* {isLoading ? <MagicWand /> : "Generate Content"} */}
                                            Generate Content
                                        </LoadingButton>
                                        {/* <LoadingButton
                startIcon={
                  <SvgIcon>
                    <WishIcon />
                  </SvgIcon>
                }

                sx={{
                  background:
                    "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                  marginLeft: 5,
                  color: "white",
                  paddingX: "20px",
                  paddingY: "12px",
                  borderRadius: "4px",
                  width: "fit-content",
                  float: "right"
                }}
                onClick={handleEditClick}
              > Edit

              </LoadingButton> */}
                                    </Stack>
                                    {/* <Grid container>
                                    <Grid item xs={2}>
                                        <Typography
                                            color="text.secondary"
                                            variant="h6"
                                            fontWeight="bold"
                                            fontSize={20}
                                            style={{ color: 'black' }}
                                        >
                                            Image Prompt
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            fontSize={15}
                                            fontWeight={200}
                                            style={{ color: 'black' }}
                                        >
                                            Type prompt to create images
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10} gap={2}
                                        display="flex"
                                        flexDirection="column">
                                        <TextField
                                            InputProps={{
                                                style: customInputStyle,
                                            }}
                                        />

                                        <TextField
                                            InputProps={{
                                                style: customInputStyle,
                                            }} fullWidth
                                            multiline
                                            rows={12}
                                            placeholder="Image preview shown here"

                                        />
                                    </Grid>
                                </Grid> */}
                                    {/* 
                                <Stack display="block">
                                    <LoadingButton
                                        startIcon={
                                            <SvgIcon>
                                                <WishIcon />
                                            </SvgIcon>
                                        }

                                        sx={{
                                            background:
                                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                            marginLeft: 5,
                                            color: "white",
                                            paddingX: "20px",
                                            paddingY: "12px",
                                            borderRadius: "4px",
                                            width: "fit-content",
                                            float: "right"
                                        }}
                                    >Regenerate Image

                                    </LoadingButton>
                                </Stack>
 */}
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography
                                                color="text.secondary"
                                                variant="h6"
                                                fontWeight={400}
                                                style={{ color: 'black' }}
                                            >
                                                CTA Widget
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Stack flexDirection="row" gap={1}>
                                                <TextField style={{ width: '100%' }}
                                                    // {...register("input_box_title")}
                                                    placeholder="Add a URL here"
                                                    InputProps={{
                                                        style: customInputStyle,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LinkIcon style={{ color: 'black' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={blogPreviewValues?.url_draft}

                                                // value={blogPreviewValues.url_draft}
                                                /* error={!!errors["input_box_title"]}
                                                helperText={errors["input_box_title"]?.message} */
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
                                            {status === 'success' ?
                                                <LoadingButton
                                                    startIcon={
                                                        <SvgIcon>
                                                            <EyeIcon />
                                                        </SvgIcon>
                                                    }

                                                    sx={{
                                                        background:
                                                            "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                                        marginLeft: 5,
                                                        color: "white",
                                                        paddingX: "20px",
                                                        paddingY: "12px",
                                                        borderRadius: "4px",
                                                        width: "fit-content",
                                                    }}

                                                > {/* View Blog */}
                                                    <Link to={`/PreviewBlogs/${sendBlogId}`} target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', textDecoration: 'none' }}>Preview Blog</Link>
                                                </LoadingButton>
                                                : null}
                                        </Grid>
                                    </Grid>

                                    <TextField
                                        // {...register("id")}
                                        type="hidden"
                                        sx={{ display: "none" }}
                                    />
                                </Stack>
                            </Box>
                        </>
                    </Modal>
                </div>
                {/*  */}
            </div>
        </>
    );
};

export default Blogs_List;
