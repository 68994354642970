import {
  Container,
  Box,
  Typography,
  Button,
  SvgIcon,
  Stack,
  Avatar,
} from "@mui/material";
import { ArrowForward, Margin } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import { Arrowcircleright } from "../assets/icons/Arrowcircleright";
import { Arrowcircleright1 } from "../assets/icons/Arrowcircleright1";
import { Arrowright1 } from "../assets/icons/Arrowright1";
import { Arrowright5 } from "../assets/icons/Arrowright5";
import { Bracketscurly2 } from "../assets/icons/Bracketscurly2";
import { Bugbeetle } from "../assets/icons/Bugbeetle";
import { Caretright1 } from "../assets/icons/Caretright1";
import { Caretright2 } from "../assets/icons/Caretright2";
import { Caretup } from "../assets/icons/Caretup";
import { Caretup1 } from "../assets/icons/Caretup1";
import { Circlewavy1 } from "../assets/icons/Circlewavy1";
import { Code3 } from "../assets/icons/Code3";
import { Cpu1 } from "../assets/icons/Cpu1";
import { Diamondsfour } from "../assets/icons/Diamondsfour";
import { Eye } from "../assets/icons/Eye";
import { Gitbranch } from "../assets/icons/Gitbranch";
import { Gitpullrequest } from "../assets/icons/Gitpullrequest";
import { Gradient1 } from "../assets/icons/Gradient1";
import { Image1 } from "../assets/icons/Image1";
import { Note1 } from "../assets/icons/Note1";
import { Paperplanetilt1 } from "../assets/icons/Paperplanetilt1";
import { Playcircle } from "../assets/icons/Playcircle";
import { Tag } from "../assets/icons/Tag";
import { Target } from "../assets/icons/Target";
import { Terminalwindow } from "../assets/icons/Terminalwindow";
import { Treestructure2 } from "../assets/icons/Treestructure2";
import { Usergear } from "../assets/icons/Usergear";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import React, { useEffect }  from "react";

import img1 from "../assets/newimg/img-1.png";
// import img2 from "../assets/newimg/body-img.png"; 
import text2 from "../assets/newimg/text-2.png";
import img3 from "../assets/newimg/icon1.png";
import img4 from "../assets/newimg/icon2.png";
import img5 from "../assets/newimg/icon3.png";
import img6 from "../assets/newimg/icon4.png";
import img7 from "../assets/newimg/icon5.png";
import img8 from "../assets/newimg/icon6.png";
import img9 from "../assets/newimg/icon7.png";
import img10 from "../assets/newimg/icon8.png";
import img11 from "../assets/newimg/icon9.png";
import img12 from "../assets/newimg/icon10.png";
import img13 from "../assets/newimg/icon11.png";
import img14 from "../assets/newimg/icon12.png";
import img15 from "../assets/newimg/icon13.png";
import img16 from "../assets/newimg/icon14.png";
import img17 from "../assets/newimg/icon15.png";
import img18 from "../assets/newimg//lock.png";
import img19 from "../assets/newimg/icon-1-1.png";
import img20 from "../assets/newimg/icon-1-2.png";
import img21 from "../assets/newimg/icon-1-3.png";
import img22 from "../assets/newimg/line.png";
import img23 from "../assets/newimg//lineicon1.png";
import img24 from "../assets/newimg/line.png";
import img25 from "../assets/newimg/lineicon2.png";
import img55 from "../assets/newimg/lineicon3.png";
import img26 from "../assets/newimg/line.png";
import img27 from "../assets/newimg/lineicon3.png";
import img28 from "../assets/newimg/mask_group-img.png";
import img29 from "../assets/newimg/bt_icon1.png";
import img30 from "../assets/newimg/bt_icon2.png";
import img31 from "../assets/newimg/bt_icon3.png";
import img32 from "../assets/newimg/bt_icon4.png";
import img33 from "../assets/newimg/bt_icon5.png";
import img34 from "../assets/newimg/bt_icon6.png";
import img35 from "../assets/newimg/bt_icon7.png";
import img36 from "../assets/newimg/bt_icon8.png";
import img37 from "../assets/newimg/bt_icon9.png";
import img38 from "../assets/newimg/icon-1-1.png";
import img39 from "../assets/newimg/icon-1-2.png";
import img40 from "../assets/newimg//icon-1-3.png";
import img41 from "../assets/newimg/icon-1-3.png";
import img42 from "../assets/newimg/icon-1-3.png";
import img43 from "../assets/newimg/gridicon1.png";
import img44 from "../assets/newimg/gridicon1.png";
import img45 from "../assets/newimg/gridicon1.png";
import img46 from "../assets/newimg/gridicon1.png";
import img47 from "../assets/newimg/img-1.png";
import img48 from "../assets/newimg/img-1.png";
import img49 from "../assets/newimg/img-1.png";
import img50 from "../assets/newimg/img-1.png";
import img51 from "../assets/newimg/card-img-1.png";
import img52 from "../assets/newimg/card-img-2.png";
import img53 from "../assets/newimg/card-img-3.png";
import img54 from "../assets/newimg/logo.png"; 
import video1 from "../assets/newimg/video.mp4";
import text1 from "../assets/newimg/text-3.png";
import body2 from "../assets/newimg/text-2.png";
import lock from "../assets/img/lock.png";
import python from "../assets/img/pythonicon.png";
import react from "../assets/img/react.png";
import vue from "../assets/img/vuejs.png";
import angular from "../assets/img/angularjsbig.png";
import nodejs from "../assets/img/nodejs.png";
import java from "../assets/img/java.png";
import  cprogram from "../assets/img/c#.png";
import  c1 from "../assets/img/c++.png";
import  c2 from "../assets/img/c.png";
import  php from "../assets/img/php.png";
import  ruby from "../assets/img/ruby.png";
import  go from "../assets/img/go.png";
import typescript from "../assets/img/typescript.png";
import swift from "../assets/img/swift.png";
import kotlin from "../assets/img/kotlin.png";
import rust from "../assets/img/rust.png";
import scala from "../assets/img/scala.png";
import r from "../assets/img/r.png";
import matlab from "../assets/img/matlab.png";
import perl from "../assets/img/perl.png";
import javascript1 from "../assets/img/javascript1.png";
import html5 from "../assets/img/html5.png";
import css from "../assets/img/css.png";
import sql from "../assets/img/sql.png";
import dart from "../assets/img/dart.png";
import flutter from "../assets/img/flutter.png";
import icon1 from "../assets/img/icon-1-1.png";
import iconbg from "../assets/img/icon-bg.png";
import gridicon2 from "../assets/newimg/gridicon2.png";
import gridicon3 from "../assets/newimg/gridicon3.png";
import gridicon4 from "../assets/newimg/gridicon4.png";
import logo from "../assets/newimg/logo.png";

import blog1 from "../assets/img/blogs1.png";
import blog2 from "../assets/img/blogs2.png";
//import blog3 from "../assets/img/blogs3.png";
import blog4 from "../assets/img/blogs4.png";

import testimonial1 from "../assets/img/ellipse-34-2.png";
import testimonial2 from "../assets/img/ellipse-34-1.png";
import testimonial3 from "../assets/img/ellipse-34.svg";

import "./newstyle.css";
// import ".assests/Anycode.css";
import LandingHeader from "../components/headers/LandingHeader.headers";
import FAQItem from "../components/FAQItem";
import AuditItem from "../components/AuditItem";
import SliderItem from "../components/SliderItem";
import ItemsCarousel from 'react-items-carousel';
import TestimonialItem from "../components/TestimonialItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useState } from "react";
import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";




const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>


const faqs = [
  {
    title: "What is Code Genie?",
    content:
      "Code Genie is an advanced AI-driven tool designed to audit smart contracts from various blockchain networks. It employs OpenAI's LLM model to provide comprehensive and accurate analysis of smart contract codes.",
  },
  {
    title: "How does Code Genie differ from traditional smart contract auditing methods?",
    content:
      "Unlike traditional methods that rely on manual scrutiny, Code Genie uses artificial intelligence to rapidly analyze and rectify smart contract vulnerabilities, ensuring enhanced accuracy and speed.",
  },
  {
    title: "How long does a Code Genie audit take?",
    content:
      "Leveraging AI technology, Code Genie can complete a full smart contract audit in mere seconds with just a single click.",
  },
  {
    title:
      "Is Code Genie suitable for users without coding knowledge?",
    content:
      "Absolutely! Code Genie is designed to be user-friendly. You do not need to possess coding expertise to audit your contracts using Code Genie.",
  },
  {
    title:
      "How does Code Genie ensure the upgradability of smart contracts?",
    content:
      "Code Genie's audit encompasses various aspects of smart contracts, including upgradability. It checks for future-readiness, ensuring that your contracts can adapt to changes and innovations in the blockchain ecosystem.",
  },
  {
    title: "How cost-effective is using Code Genie compared to other auditing services?",
    content:
      "Code Genie offers high-quality auditing services at a fraction of the industry's average cost. With both free and paid plans available, it provides affordable solutions for startups, developers, and agencies alike.",
  },
  {
    title: "Can Code Genie detect and rectify all types of vulnerabilities in a smart contract?",
    content:
      "Code Genie offers a comprehensive audit, identifying a wide range of vulnerabilities from security to gas usage. Moreover, it provides real-time solutions to rectify detected issues.",
  },
  {
    title: "How secure is the information and code I share with Code Genie?",
    content:
      "User security and data privacy are top priorities for Code Genie. All information and code shared are processed securely.  In addition to this, Code Genie NEVER store your smart contract code or data.",
  },
  {
    title: "What blockchain networks does Code Genie support?",
    content:
      "Code Genie is versatile and designed to audit smart contracts from a myriad of blockchain networks. Detailed specifications can be found on the platform.",
  },
  {
    title: "How can I get started with Code Genie?",
    content:
      "Simply visit the Code Genie website, sign up, and you can start auditing your smart contracts with the click of a button. \r\nRemember, if you have further questions or need any assistance, Code Genie's support team is always available to help you navigate the world of smart contract auditing with ease",
  }
];

const audits = [
	{
	title: "Functionality: Ensuring Your Smart Contracts Work as Intended",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
	content: "Functionality lies at the core of any smart contract. With Code Genie, you can be confident that your smart contracts will execute their intended functions flawlessly. Our AI-powered auditing tool meticulously analyzes your code to identify any discrepancies or errors that might hinder your contract's functionality.",
	},
    {
	title: "Security: Safeguarding Your Smart Contracts from Threats",
    icon:  <Eye className="icon-instance-node-4" color="white" />,
	content: "Security breaches can be catastrophic for your smart contracts. Code Genie's robust security checks go beyond conventional methods, identifying potential vulnerabilities and recommending best practices to mitigate risks. Protect your smart contracts from malicious attacks and unauthorized access with Code Genie.",
	},
    {
	title: "Gas Usage: Optimizing Efficiency and Cost-effectiveness",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
	content: "Efficient gas usage is essential for optimal performance. Code Genie not only identifies gas-guzzling code segments but also suggests optimizations to reduce transaction costs. Save both time and money by ensuring your smart contracts run smoothly without wasting unnecessary resources.",
	},
    {
	title: "Visibility: Enhancing Transparency and Traceability",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "Transparency is key in the blockchain world. Code Genie enhances the visibility of your smart contracts by pinpointing areas where transparency could be improved. By fostering clear and traceable code, your users can trust the integrity of your smart contracts and make informed decisions.",
	},{
	title: "Variables: Thorough Analysis of Data Storage",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
	content: "Smart contracts rely on variables to store crucial data. Code Genie examines your variable declarations and usage patterns, ensuring that data storage is efficient and logically structured. Eliminate potential data inconsistencies with our comprehensive variable analysis.",
	},{
	title: "Event Logs: Capturing Crucial Contract Interactions",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
	content: "Event logs play a vital role in understanding contract interactions. Code Genie reviews your event logging mechanisms, making certain that all significant interactions are appropriately captured. Gain valuable insights into your smart contract's execution for both auditing and analysis purposes.",
	},{
	title: "Upgradability: Planning for Future Contract Evolution",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "Adapting your smart contracts over time is essential. Code Genie assesses your contract's upgradability by examining your upgrade mechanisms and compatibility considerations. Ensure your contracts can evolve while maintaining security and functionality.",
	},{
	title: "Ownership: Clarifying Contract Ownership and Access Control",
    icon:  <Usergear className="user-gear" />,
	content: "Ownership structures define who controls your smart contracts. Code Genie reviews your ownership patterns and access control mechanisms, helping you establish clear ownership guidelines and prevent unauthorized modifications.",
	},{
	title: "Balance: Validating Token and Asset Management",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
	content: "Balances are critical in token and asset management contracts. Code Genie validates your balance management functions, ensuring that token transfers and asset distributions are executed accurately and securely.",
	},{
	title: "Fallback: Managing Unintended Contract Interactions",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
	content: "Fallback functions handle unexpected interactions with your contracts. Code Genie analyzes your fallback mechanisms, ensuring they are well-defined and protect your contracts from unintended behavior.",
	},{
	title: "Self Destruct: Secure Contract Closure",
    icon: <Bugbeetle className="icon-instance-node-4" />,
	content: "Properly terminating a contract is as important as deploying it. Code Genie examines your self-destruct functions, verifying that contract closure is executed securely and with appropriate considerations for remaining funds or assets.",
	},{
	title: "Exploit Scenarios: Identifying Potential Attack Vectors",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "Threats can come from various angles. Code Genie simulates potential exploit scenarios, helping you anticipate and prevent vulnerabilities before they are exploited by malicious actors.",
	},
];


const slider	 = [
	{
	  title: "Extremely Accurate",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
	  content: " Code Genie uses a Large Language Model AI that conducts a full analysis of the smart contract code The AI will detect issues that might be overlooked by human eyes. Code Genie will even attempt to fix the bugs and issues found in your contract, assisting your developers in real time. See below for the various types of analysis performed by our AI.",
	},
    {
	  title: "Extremely Fast &amp; Easy to Use",
    icon:  <Eye className="icon-instance-node-4" color="white" />,
	content: "Leveraging new AI technology, Code Genie can conduct a full smart contract audit in just a few seconds with a single click.You don't need any coding knowledge to self-audit your contracts. Try it for free...",
	},
    {
     title: "Extemely Affordable",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
	content: " Code Genie costs a fraction of the industry average. Our do-it-yourself approach could save you between $5,000 to $15,000+, the typical amount spent on auditing smart contracts in the industry.",
	},
    {
	  title: "Secure your Digital Assets",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: " Code Genie makes it easy for blockchain teams building NFTs, DeFi, crypto, and other projects to self-audit their smart contracts. If at any time you wish to verify if your contract is safe, you can use Code Genie for an instant analysis and receive feedback with just one click.",
	},{
	  title: "The Value",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
	content: " With Code Genie, blockchain projects can now self-audit their smart contracts instantly and securely with just one click, saving time, money, and reducing the risk of hacking or security fraud.",
	},{
    title: ">Bug Free Contracts",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
	   content: "Ultimately, by using Code Genie, companies can feel more confident about their deployed contracts. Code Genie helps identify vulnerabilities in smart contracts and assists developers in resolving these issues. Thus, Code Genie can optimize the company's development process, increase its development team's efficiency, and deliver high-quality, bug-free software products in a timely manner.",

  },
];

const testimonials	 = [
	{
	  name: "Kristina Bruhahn - Continuum CEO,",
    icon:    <img src={testimonial1} className="img-fluid rounded-circle twitter-padding"  alt="" width="49" height="44"></img>,
	  content: "This is excellent for the industry and I love the data points.",
    reference: "Blockchain Compliance Expert"
	},
    {
	  name: "Rutvik G.",
    icon:   <img src={testimonial2} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "Code Genie gives all functions analysis and the kind of stuff that is in the function. So it is good to have an auditing perspective. Accuracy is also excellent. (regarding a Solidity smart contract Functionality audit)",
    reference: "Smart Contract Developer"
	},
    {
     name: "Jamal Woodley - CEO,",
    icon:  <img src={testimonial3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
	  content: "I was able to use Code Genie to resolve the issue that I was having with a smart contract in just a few minutes. Surprising excellent time saver no headaches Rohan #mintmadecerfied let’s go.",
    reference: "Conceptualize AI Inc"
	}
];

const blogs = [
  {
      title: "A Dive into Decentralization: How Code Genie Elevates Smart Contracts",
      image: <img className="card-img-top" src={blog1} alt="" />,
      text: "In the vast cosmos of technological advancements, decentralization emerges as the North Star, guiding countless innovations and reshaping the very bedrock of digital interactions. At the heart of this transformative paradigm is the realm of smart contracts—self-executing contracts with the agreement directly written into lines of code. But as powerful as they are, ensuring their security, efficiency, and functionality is a task that demands a unique blend of precision, speed, and foresight. This is where Code Genie enters the scene, not just as another tool but as a revolution in itself.",
      href: "/blogs/A_Drive_into_Decentralization",
  },
  {
      title: "The AI Advantage: Code Genie's Mastery Over Traditional Auditing Methods",
      image: <img className="card-img-top" src={blog2} alt="" />,
      text: "In the intricate landscape of blockchain technology, smart contracts stand as pillars of innovation, offering a transparent, tamper-proof way to codify and automate agreements. As powerful as they might be, these contracts are not immune to human errors, and any mistake can lead to devastating financial consequences. Enter the world of smart contract auditing, where the focus isn't just on detecting problems but predicting them, mitigating them, and ensuring the seamless execution of every agreement. While traditional auditing methods have made significant strides, they are increasingly being overshadowed by the prowess of AI-driven tools, with Code Genie leading the charge.",
      href: "/blogs/The_AI_Advantage",
  },
  {
      title: "Beyond Security: The Multi-Dimensional Benefits of Code Genie’s Audits",
      image: <img className="card-img-top" src={blog4} alt="" />,
      text: "At the heart of the decentralized revolution, smart contracts are changing the way we transact, trust, and transform digital agreements. Given their pivotal role in underpinning a myriad of applications, the security of these contracts naturally comes to the forefront. However, while security remains paramount, it's merely one dimension of a broader spectrum that defines a robust smart contract. With Code Genie's innovative approach to audits, the horizon expands beyond mere security checks, unveiling a comprehensive suite of benefits that position smart contracts for optimal performance and longevity.",
      href:"/blogs/Beyond_Security",
  },
  /*{
      title: "Unveiling Code Genie: Revolutionizing Smart Contract Audits in the Blockchain Era",
      image: <img className="card-img-top" src={blog5} alt="" />,
      text: "The world of smart contracts is intricate and complex. Ensuring their flawlessness is paramount, not just for functionality but also to fortify them against potential breaches and vulnerabilities. Enter Code Genie – a groundbreaking AI tool that is redefining how we perceive smart contract audits.",
      href:"/blogs/Unveiling_Code_Genie",
  },
  {
      title: "Cost-Effective Auditing: Exploring Code Genie's Affordability in Blockchain",
      image: <img className="card-img-top" src={blog6} alt="" />,
      text: "The blockchain sphere is witnessing an exponential surge in the deployment of smart contracts, paving the way for a new era of digital agreements. However, as the complexity and intricacy of these contracts grow, so does the need for rigorous auditing. Traditionally, this has translated into substantial financial investments, often becoming a deterrent for startups and small projects. Enter Code Genie - an innovative solution that's not only pushing the boundaries of audit precision but also making it economically viable for all. Let's embark on a journey to unpack the affordability of Code Genie's auditing services.",
      href:"/blogs/Cost_Effective_Auditing",
  },
  {
      title: "A Novice in Blockchain? Here's Why Code Genie is Your Best Bet",
      image: <img className="card-img-top" src={blog7} alt="" />,
      text: "Blockchain, with its disruptive potential, is luring enthusiasts from all walks of life. But as novices dive deep into this ocean of possibilities, they often find themselves grappling with the complexities of smart contracts. These self-executing contracts, albeit powerful, require meticulous attention to detail. The stakes are high, and the margin for error? Minuscule. If you find yourself tiptoeing on the edge of this intimidating precipice, Code Genie is here to offer a safety net. Here's how this revolutionary tool ensures that even those without a coding background can navigate the smart contract maze with confidence.",
      href:"/blogs/A_Novice_In_Blockchain",
  },
  {
      title: "One Click, Multiple Solutions: The Comprehensive Auditing of Code Genie",
      image: <img className="card-img-top" src={blog8} alt="" />,
      text: "The blockchain universe is rapidly expanding, and as it does, the complexity of navigating its intricacies grows in tandem. From startups to seasoned developers, everyone is in search of a tool that simplifies the labyrinthine world of smart contracts. Enter Code Genie, the unparalleled smart contract auditing solution that promises not just detection but holistic enhancement. With its one-click audit feature, Code Genie is revolutionizing the way we approach blockchain efficiency, security, and functionality. Let's delve deeper into how Code Genie's comprehensive auditing is setting new standards in the blockchain landscape.",
      href:"/blogs/One_Click_Multiple_Solutions",
  },
  {
      title: "Real-time Rectifications: Code Genie's Proactive Approach to Smart Contract Audits",
      image: <img className="card-img-top" src={blog9} alt="" />,
      text: "In the intricate web of blockchain technology, smart contracts have emerged as the pivotal touchpoint. Their seamless operation determines the efficacy and trustworthiness of blockchain applications. However, as vital as detection of issues is, the true mettle of an auditing tool lies in its rectification capabilities. Code Genie, a trailblazer in the blockchain auditing domain, exemplifies this paradigm by not only identifying but also rectifying smart contract issues in real-time. With the increasing search volume around \"proactive smart contract solutions\" and \"real-time blockchain fixes\", it's evident that the market seeks tools that do more than just highlight problems. Code Genie is perfectly positioned to meet this demand.",
      href:"/blogs/Real_time_Rectifications",
  },
  {
      title: "Upgrading the Upgradable: How Code Genie Ensures Smart Contract Evolution",
      image: <img className="card-img-top" src={blog10} alt="" />,
      text: "The dynamic realm of blockchain technology is akin to a constantly moving train – with new stations being added at each turn. Amidst this progression, smart contracts act as the tracks, guiding and ensuring smooth transitions. Just like rail tracks need upgrades for newer trains, smart contracts need upgradability for evolving requirements. Recognizing this pivotal aspect, Code Genie offers specialized audits that emphasize ensuring smart contract upgradability, fostering adaptability in a world where change is the only constant.",
      href:"/blogs/Upgrading_the_Upgradable",
  },
  {
      title: "Breaking Down the Technical: Code Genie’s Audit Explained",
      image: <img className="card-img-top" src={blog11} alt="" />,
      text: "The blockchain ecosystem, while fascinating, often seems like an intricate web of complexities to the untrained eye. One such element is smart contract auditing, a crucial component to ensure security, efficiency, and robustness in the ever-evolving blockchain universe. Enter Code Genie, a revolutionary tool reshaping the audit landscape. But how does Code Genie make the magic happen? Let's embark on a deep dive into the technicalities and unravel the mysteries behind its auditing magic",
      href:"/blogs/Breaking_Down_the_Technical",
  },
  {
      title: "The Ethical Edge: Code Genie's Commitment to Transparent Auditing",
      image: <img className="card-img-top" src={blog12} alt="" />,
      text: "In the digital age, as the blockchain realm expands at a blistering pace, trust becomes the bedrock of all technological interactions. The blockchain, lauded for its immutable transparency, is no exception. Yet, as layers of complexities unfold, ensuring transparency, especially in areas like smart contract auditing, poses a challenge. Enter Code Genie, the beacon of ethical, transparent, and honest smart contract auditing. This tool stands tall not just for its technical prowess but also for its unwavering commitment to ethical standards.",
      href:"/blogs/The_Ethical_Edge",
  },
  {
      title: "The Evolution of Audits: Tracing Code Genie’s Revolutionary Journey",
      image: <img className="card-img-top" src={blog13} alt="" />,
      text: "In the heart of the digital renaissance, the concept of smart contracts has emerged as a groundbreaking innovation. As these self-executing contracts gain traction, ensuring their accuracy and security is paramount. Enter the world of smart contract audits, a niche but a vital segment of the blockchain ecosystem. This article traces the transformative journey of smart contract auditing, spotlighting the revolutionary contributions of Code Genie.",
      href:"/blogs/The_Evolution_of_Audits",
  },*/
];

const Cost = [
	{
	title: "Early Detection is Cheaper:",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
	content: "The cost to fix a bug increases the later it's found in the software development lifecycle. For example, a bug found during the requirements or design phase can be up to 100 times cheaper to fix than if it's detected after a product release.",
	},
    {
	title: "Post-release Costs",
    icon:  <Eye className="icon-instance-node-4" color="white" />,
	content: "The Systems Sciences Institute at IBM reported that the cost to fix an error found after product release was four to five times more than one uncovered during design, and up to 100 times more than one identified in the maintenance phase.",
	},
    {
	title: "Economic Impact",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
	content: "The Consortium for IT Software Quality (CISQ) estimated that poor software quality resulting from software bugs cost U.S. organizations $2.84 trillion in 2020.",
	},
    {
	title: "High-Profile Failures",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "There have been several high-profile software failures that resulted in significant financial losses. For instance, the 2012 FlashCrash linked to algorithmic trading resulted in a temporary loss of $1 trillion in market value.",
	},{
	title: "Development Time",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
	content: "In many projects, debugging consumes up to 50% of the development time, according to various reports. This means significant time (and thus money) is spent on finding and fixing issues rather than on creating new features.",
	},{
	title: "Reputation and Revenue",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
	content: "For consumer-facing products, especially in the tech industry, a significant bug can tarnish a company's reputation and result in loss of users or sales. For instance, Apple's iOS Maps mishap in 2012 led to public apologies and recommended use of competitors.",
	},{
	title: "Safety and Litigation Costs",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "In critical systems, like healthcare or aviation, software bugs can have dire consequences, leading to safety concerns and significant litigation costs.",
	},{
	title: "Maintenance Overheads",
    icon:  <Usergear className="user-gear" />,
	content: "A study suggested that around 60-80% of the cost of software goes into maintenance, and a significant portion of that is fixing bugs  .",
	},{
	title: "Cost of Downtime",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
	content: "For businesses that rely heavily on software (like online retail, finance, or service providers), downtime due to software bugs can be incredibly costly. Amazon, for example, would lose an estimated $220,000 per minute if its site went down",
	},{
	title: "Developer Costs:",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
	content: "According to Cambridge University, global software developers spend approximately $312 billion a year debugging software errors.",
	},
];

const Benefits = [
	{
	title: "Code Functionality",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
	content: " Summary: Ensuring that the code works as intended and meets the specified requirements value:  Functional code is foundational; if it doesn't work, nothing else matters. This ensures that the software performs its designated tasks and fulfills its purpose.",
 
	},
    {
	title: "Logical Errors",
    icon:  <Eye className="icon-instance-node-4" color="white" />,
	content: " Summary: Checking for flaws in the logic that could cause unexpected behavior or results. value: Efficient code runs faster, uses fewer resources, and provides a better user experience, especially crucial in real-time systems.",

	},
    {
	title: "Coding Standards and Style",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
	content: " Summary: nsuring that the code adheres to established organizational or industry coding standards and is consistent in style.  value: Consistent coding practices make the codebase maintainable, understandable, and less prone to errors introduced by misreading or misunderstanding code segments.",
 	
},
    {
	title: "Readability and Maintainability",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "Summary:  Verifying that the code is well-organized, adequately commented, and structured in a way that's easy for other developers to understand and modify. value:  Readable and maintainable code reduces future developmental costs and allows teams to make updates or find and fix bugs more efficiently.",
  
},{
	title: "Development Time",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
	content: "In many projects, debugging consumes up to 50% of the development time, according to various reports. This means significant time (and thus money) is spent on finding and fixing issues rather than on creating new features.",
	
},{
	title: "Reputation and Revenue",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
	content: " Summary: For consumer-facing products, especially in the tech industry, a significant bug can tarnish a company's reputation and result in loss of users or sales. value: For instance, Apple's iOS Maps mishap in 2012 led to public apologies and recommended use of competitors.",
  
},{
	title: "Security Vulnerabilities",
    icon: <Eye className="icon-instance-node-4" color="white" />,
	content: "Summary: Probing the code for potential security flaws or vulnerabilities that could be exploited maliciously.  value: Secure code protects user data, maintains trust, and ensures the software is resilient against cyber threats.",

},{
	title: "Code Redundancy",
    icon:  <Usergear className="user-gear" />,
	content: "Summary: Checking for repetitive code or functions that could be optimized or consolidated.value:  Reducing redundancy minimizes the chance of errors, decreases the codebase size, and simplifies future modifications.  .",
 
},{
	title: "Testing and Coverage",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
	content: "Summary: Ensuring that tests exist for the code and that they cover a wide range of scenarios. value:  Comprehensive testing ensures that any changes or additions to the code don't introduce new errors, safeguarding software reliability.",
  
},{
	title: "Integration and Dependencies",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
 	content: "Summary: Reviewing how new code integrates with existing systems and ensuring dependencies are current and compatible.value:  Proper integration ensures smooth system operations, while updated dependencies prevent potential security risks and compatibility issues.",
 
},

{
	title: ">Documentation",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
 	content: "Checking if the code comes with adequate documentation, including inline comments and external documentation.value:  Documentation aids in onboarding new team members, supports maintainability, and serves as a reference during debugging.",
 
},

];


const AnycodeLandingPage = () => {
  //const genieSelector = useAppSelector((state) => state.genieState);
  let module_name = "Any Code";//genieSelector.module;
  localStorage.setItem("module", module_name);
  const navigate = useNavigate();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 100;
  const slidestoshow =  (isMobile)?1:3 ;
  const audittoshow =  (isMobile)?1:3 ;
  const testimonialtoshow =  (isMobile)?1:3 ;

  var slidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidestoshow,
    slidesToScroll: 1,
    arrows : false,
    accessibility : false,
    nextArrow: false,
    prevArrow: false,
  

  };

  var auditsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows : false,
    accessibility : false,
    nextArrow: false,
    prevArrow: false,
  

  };
  var costsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows : false,
    accessibility : false,
    nextArrow: false,
    prevArrow: false,
  

  };

  var benefitssettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows : false,
    accessibility : false,
    nextArrow: false,
    prevArrow: false,
  

  };



  var testimonialsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialtoshow ,
    slidesToScroll: 1,
    arrows : true,
    // nextArrow: true,
    // prevArrow: ,
    

  };

  useEffect(() => {
    // Scroll to the top of the landing page
    window.scrollTo(0, 0);
  }, []);

  localStorage.setItem("module", "Any Code");

const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
          const status = response.status;
        
          console.log(status);
          if (status === 'success') {
            toast.success(response.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }
  return (
    <>
       <div className="container">
    <div className="row">
    <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              {/* <img src="./img/logo.png" alt="" /> */}
              <img src={img54} className="img-fluid" alt="" />
  
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="">
                <i className="bi bi-list text-white" />
              </span>
            </button>



            
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                <li className="nav-item">
                  <a
                    className="nav-link nav_active"
                    style={{ color: "#4ba5eb", fontWeight: 800 }}
                    href="/"
                  >
                    Home
                  </a>
                </li>




                <li className="nav-item">
                  <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                    Affiliate
                  </a>
                </li> 


                <li className="nav-item">
                  <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5">
                  <li className="nav-item">
                      <a 
                        className="nav-link nav_simple anycode-nav"
                        style={{ color: "#C0B5B6", fontWeight: 700 }}
                         href="/" 
             
                        >
                        Smart Contract
                      </a>
                    </li>
                    <li className="nav-item">
                      <a 
                        className="nav-link nav_simple smart-nav-1" 
                        style={{ color: "#225CD7", fontWeight: 700 }}
                        href="/" 
                      >
                         Any code
                      </a>
                    </li>
                    
                  </ul>
                  
                </li>

               


              </ul>


              
              <div className="d-flex gap-4" role="search">
              <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("/signup");
              }}>
                  Try Now
                </button>
                <button className="btn btn-outline-primary px-3"
                  onClick={() => {
                    navigate("/signin");
                  }}>Sign  in</button>
              </div>
            </div>
          </div>
        </nav>
      <hr className="mt-3" style={{ color: "rgb(154, 160, 163)" }} />
    </div>
  </div>
  <div className="container">
    <div className="row my-sm-5 mb-5">
      <div className="col-md-5 text-white text-center mt-2">
        <div className="px-sm-0 px-2">
          <div>
            <div className="mb-5">
              <img
                src="./img/text-3.png"
                className="img-fluid"
                style={{ width: "80%" }}
                alt=""
              />
            </div>
            <div className="Ac-1">
            
            <div>
              <img src={text1} className="body-1" alt="" />
            </div>
          </div> <br></br> <br></br>
            <p className="Never-1">
              We NEVER store your code or contract data
            </p>
            <h5 className="bg-primary py-2">TRY IT FOR FREE</h5>
            <p className="fw_samibold" style={{ textAlign: "justify" }}>
              Code Genie, harnessing LLM AI capabilities, offers exhaustive code
              reviews for various programming languages. It not only detects
              bugs but also suggests precise fixes and refactoring methods.
              Serving as a vital tool for developers, Code Genie ensures
              top-tier code quality, bolsters security, and streamlines the
              development process, elevating software robustness and efficiency.
            </p>
          </div>
          <div>
          <button className="button_leniar px-5 my-3"
            onClick={() => {
              navigate("/signup");
            }}
          >
            Get started for free <i className="bi bi-arrow-right " />
          </button>
            <div className="mt-3 mb-sm-0 mb-4">
              <div
                className="mx-auto px-3 py-2 rounded-1"
                style={{
                  width: "fit-content",
                  border: "1px solid grey",
                  backgroundColor: "#020622"
                }}
              >
                <h6>“Impressive.&nbsp; Very Impressive!”</h6>
                <div className="d-flex gap-2">
                  {/* <img src="./img/img-1.png" className="img-fluid" alt="" /> */}
                  <img src={img47} className="img-fluid img_width-2" alt="" />
                  <div className="text-start">
                    <p className="mb-0">Saravanan B.</p>
                    <p
                      className="mb-0"
                      style={{ color: "#7A7A7A", fontSize: 12 }}
                    >
                      Engineering Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-7 my-auto">
        <div className="px-sm-0 px-2">
        <img src={body2} className="body-2" alt="" />
        </div>
      </div>
    </div>
    {/* programming language Supported section start */}
    <div
      className="py-5 mx-sm-0 mx-2 backgroundbg"
      // style={{
      //   backgroundImage: "url(./img/icon-bg.png)",
      //   backgroundRepeat: "no-repeat",
      //   backgroundPosition: "center",
      //   backgroundSize: "cover"
      // }}

    >
      <h3 className="ps-sm-5 pb-4 text-center main_heading">
        Programming Languages supported
      </h3>
      <div
        className="gap-3 justify-content-center"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div className="my-auto">
          {/* <img
            src="./img/pythonicon.png"
            className="img-fluid img_width"
            alt=""
          /> */}
           <img src={python} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/vuejs-1.png" className="img-fluid img_width" alt="" /> */}
          <img src={vue} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/react.png" className="img-fluid img_width" alt="" /> */}
          <img src={react} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img
            src="./img/angularjsbig.png"
            className="img-fluid img_width"
            alt=""
          /> */}
           <img src={angular} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img
            src="./img/nodejs-big.png"
            className="img-fluid"
            style={{ width: "3rem" }}
            alt=""
          /> */}
           <img src={nodejs} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/java.png" className="img-fluid img_width" alt="" /> */}
          <img src={java} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/c#.png" class="img-fluid img_width" alt=""> */}
          {/* mg src={c} className="img-fluid img_width" */}
          <img src={cprogram} className="img-fluid img_width" alt="" />
      </div>
        <div className="my-auto">
          {/* <img src="./img/c++.png" className="img-fluid img_width" alt="" /> */}
         
          <img src={c1} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/c.png" className="img-fluid img_width" alt="" /> */}
          <img src={c2} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/php.png" className="img-fluid img_width" alt="" /> */}
          <img src={php} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/ruby.png" className="img-fluid img_width" alt="" /> */}
          <img src={ruby} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/go.png" className="img-fluid img_width" alt="" /> */}
          <img src={go} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img
            src="./img/typescript.png"
            className="img-fluid img_width"
            alt=""
          /> */}
           <img src={typescript} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/swift.png" className="img-fluid img_width" alt="" /> */}
          <img src={swift} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/kotlin.png" className="img-fluid img_width" alt="" /> */}
          <img src={kotlin} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/rust.png" className="img-fluid img_width" alt="" /> */}
          <img src={rust} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/scala.png" className="img-fluid img_width" alt="" /> */}
          <img src={scala} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/r.png" className="img-fluid img_width" alt="" /> */}
          <img src={r} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/matlab.png" className="img-fluid img_width" alt="" /> */}
          <img src={matlab} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img
            src="./img/perl.png"
            className="img-fluid img_width"
            style={{ width: "1.5rem" }}
            alt=""
          /> */}
           <img src={perl} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img
            src="./img/javascript1.png"
            className="img-fluid img_width"
            style={{ width: "1.5rem" }}
            alt=""
          /> */}
           <img src={javascript1} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/html5.png" className="img-fluid img_width" alt="" /> */}
          <img src={html5} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/css.png" className="img-fluid img_width" alt="" /> */}
          <img src={python} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/sql.png" className="img-fluid img_width" alt="" /> */}
          <img src={sql} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/dart.png" className="img-fluid img_width" alt="" /> */}
          <img src={dart} className="img-fluid img_width" alt="" />
        </div>
        <div className="my-auto">
          {/* <img src="./img/flutter.png" className="img-fluid img_width" alt="" /> */}
          <img src={flutter} className="img-fluid img_width" alt="" />
        </div>
      </div>
    </div>
    {/* programming language Supported section end */}
  </div>
  <div className="container">
    <div className="row mt-sm-5">
      <div className="col-md-4 text-center my-auto">
        <div>
          {/* <img src="./img/lock.png" className="img-fluid" alt="" /> */}
          <img src={lock} alt="" />
        </div>
      </div>
      <div className="col-md-8 my-auto">
        <div className="px-sm-0 px-2">
          <div className="back_border p-4 text-white rounded-1">
            <h3 className="mb-5 ms-3 main_heading">
              The Cost of Software Bugs
            </h3>
            <ul className="fw_samibold">
              <li className="mb-4">
                System downtimes from bugs can cost e-commerce sites up to
                $220,000 per minute in lost revenue
              </li>
              <li className="mb-4">
                Bugs erode user trust, leading to a 25% higher chance of
                negative reviews.
              </li>
              <li className="mb-4">
                In critical sectors, software errors can cause fatalities, with
                100+ deaths annually attributed to medical software faults.
              </li>
              <li className="mb-4">
                Developers spend up to 50% of project time on debugging,
                delaying product releases.
              </li>
              <li className="mb-4">
                Software vulnerabilities from bugs contribute to 60% of data
                breaches.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-5 mb-4">
      <div className="col">
        <h3 className="text-center py-sm-5 py-2 main_heading">
          How is Code Genie Al Different?
        </h3>
      </div>
    </div>
    <div className="row mb-5 px-sm-0 px-2">
      <div className="col-md-4 mb-2 text-white">
        <div className="back_border p-4 rounded-1 box_height">
          <div>
            {/* <img src="./img/icon-1-1.png" style={{ width: "3rem" }} alt="" /> */}
            <img src={img19} alt="" />
          </div>
          <h5 className="my-3 pb-3 f_heading">Comprehensive Code Review</h5>
          <p className="mb-0 fw_samibold">
            Code Genie offers an exhaustive review across multiple programming
            languages, ensuring that developers receive feedback on potential
            vulnerabilities, inefficiencies, and coding standards, all
            contributing to improved software quality.
          </p>
        </div>
      </div>
      <div className="col-md-4 mb-2 text-white">
        <div className="back_border p-4 rounded-1 box_height">
          <div>
            <img src="./img/icon-1-2.png" style={{ width: "3rem" }} alt="" />
            {/* <img src={img39} alt="" /> */}
            <img src={img20} alt="" />
          </div>
          <h5 className="my-3 pb-3 f_heading">Accelerated Debugging</h5>
          <p className="mb-0 fw_samibold">
            By rapidly pinpointing bugs and suggesting precise fixes, Code Genie
            drastically reduces the debugging time, helping teams to meet
            deadlines and reduce developmental costs.
          </p>
        </div>
      </div>
      <div className="col-md-4 mb-2 text-white">
        <div className="back_border p-4 rounded-1 box_height">
          <div>
            {/* <img src="./img/icon-1-3.png" style={{ width: "3rem" }} alt="" /> */}
            <img src={img21} alt="" />
          </div>
          <h5 className="my-3 pb-3 f_heading">Scalability and Accessibility</h5>
          <p className="mb-0 fw_samibold">
            With the power of LLM AI behind it, Code Genie can consistently
            review large codebases without fatigue, making it an accessible tool
            for both individual developers and large enterprises to maintain
            consistent code quality.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-md-6 px-md-5">
        <div className="px-sm-0 px-2">
          <div className="mb-4">
            <div>
              <h4 className="text-primary main_heading">
                Empowering Development Teams
              </h4>
              <div>
                {/* <img src="./img/line.png" className="img-fluid" alt="" /> */}
                <img src={img24} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="d-flex gap-3 my-3">
              {/* <img src="./img/lineicon1.png" className="img-fluid" alt="" /> */}
              {/* <img src={img24} alt="" /> */}
               <img src={img23} alt="" />
              <h5 className="my-auto f_heading">Enhanced Code Quality</h5>
            </div>
            <p className="fw_samibold">
              Code Genie meticulously analyzes code, catching intricate bugs and
              vulnerabilities that might be overlooked by human reviewers. This
              consistent scrutiny ensures that the software meets high standards
              of quality, reducing post-deployment issues
            </p>
          </div>
          <div className="my-4">
            <div>
              <div>
                {/* <img src="./img/line.png" className="img-fluid" alt="" /> */}
                <img src={img24} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="d-flex gap-3 my-3">
              {/* <img src="./img/lineicon2.png" className="img-fluid" alt="" /> */}
              <img src={img25} alt="" />
              <h5 className="my-auto f_heading">Continuous Learning</h5>
            </div>
            <p className="fw_samibold">
              By providing detailed feedback, Code Genie acts as a learning tool
              for development teams. Developers can understand their coding
              mistakes, enhancing their skills and ensuring the same errors
              aren't repeated in future projects.
            </p>
          </div>
          <div className="my-4">
            <div>
              <div>
                {/* <img src="./img/line.png" className="img-fluid" alt="" /> */}
                <img src={img24} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="d-flex gap-3 my-3">
              {/* <img src="./img/lineicon3.png" className="img-fluid" alt="" /> */}
              <img src={img55} alt="" />
              <h5 className="my-auto f_heading">Optimized Productivity</h5>
            </div>
            <p className="fw_samibold">
              Instead of spending hours on debugging and manual code reviews,
              teams can utilize Code Genie for instant feedback. This
              accelerates the development cycle, allowing teams to focus on
              feature enhancement and innovation, thereby delivering products
              faster to the market.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="bg_img">
          <div className="text-center">
            {/* <img
              src="./img/mask_group-img.png"
              className="img-fluid"
              style={{ width: "80%" }}
              alt=""
            /> */}
             <img src={img28} className="img-fluid"
              style={{ width: "80%" }} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container my-lg-5 p-md-5">
    <div className="row px-lg-5 px-2">
      <div className="col text-center">
        <h4 className="my-5 main_heading">
          Some of the items that Code Genie audits for include
        </h4>
      </div>
    </div>
    <div className="row px-lg-5 px-2">
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img29} alt="" />
            </div>
            <h6 className="my-auto f_heading2">
              Correctness &amp; Functionality
            </h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img30} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Error Handling</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img31} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Dependencies</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img32} alt="" />
            </div>
            <h6 className="my-auto f_heading2">
              Code Quality &amp; Readability
            </h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img33} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Performance</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img34} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Documentation &amp; Comments</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img35} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Consistency</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img36} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Security</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-6 my-md-4 my-3">
        <div>
          <div className="d-flex gap-3">
            <div className="p_icon">
            <img src={img37} alt="" />
            </div>
            <h6 className="my-auto f_heading2">Scalability</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className=" backgroundbg-do"
    // style={{
    //   backgroundImage: "url(./img/icon-bg.png)",
    //   display: "flex",
    //   flexWrap: "wrap"
    // }}
  >
    <div className="container">
      <div className="row py-5 px-0 px-lg-5">
        <div className="col-md-6">
          <div>
            <h3 className="px-lg-4 text-md-start text-center main_heading2">
              Do Code Review and fix your bugs within seconds with Code Genie.
            </h3>
          </div>
        </div>
        <div className="col-md-6 text-md-end text-center my-auto">
          <div>
          <button className="button_leniar px-5 my-3"
            onClick={() => {
              navigate("/signup");
            }}
          >
            Get started for free <i className="bi bi-arrow-right " />
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row my-sm-5 pb-5">
      <div className="col-md-6 my-auto">
        <div className="px-lg-5 px-2 text-sm-start text-center">
          <h4 className="mb-5 main_heading">
            Code Genie: Empowering Developers to Ship Bug Free Code
          </h4>
          <p className="my-4 fw_samibold" style={{ textAlign: "justify" }}>
            Code Genie’s core mission has always been to empower coders,
            ensuring that the software they ship is not only efficient but also
            free of errors. In today's digital age, where software forms the
            backbone of countless industries, the importance of bug-free code
            cannot be overstated. Code Genie is here to ensure that every line
            of code you write stands up to the highest standards, allowing you
            to innovate with confidence and deliver exceptional results.
          </p>
          <p className="fw_samibold">
            Code Genie was created by OceSha, Inc. Thank you for using our
            technology.
          </p>
          <p className="fw_samibold">
            Rohan Hall, CEO <br /> OceSha.com
          </p>
        </div>
      </div>

      <div className="col-md-6">
        <div>
          <h6 className="text-primary text-center mb-3">HOW IT WORKS</h6>
          <div>
            {/* <video src="https://uiassetbucket.s3.amazonaws.com/how_it_works.mp4" className="img-fluid" controls="" /> */}
            <video 
                        src="https://uiassetbucket.s3.amazonaws.com/how_it_works.mp4"
                            // height="443"
                            width="100%"
                        controls
                        // style={{height:443, display:"flex", alignItems:"center", justifyContent:"center"}}
                        >
                      </video>
          </div>
        </div>
      </div>
    </div>
    {/* first-slider start */}
    <h4 className="text-center my-sm-5 py-sm-5 pb-5 main_heading">
      The Cost of Software Bugs
    </h4>

    <Slider  {...costsettings}> 
     
     {Cost.map((cost, index) => (
                                 <AuditItem
                                     title={cost.title}
                                     content={cost.content}
                                     key={`cost_item_${index}`}
                                     icon={cost.icon}
                                 />
                                 ))}


                                 

     </Slider>
    {/* <div className="responsive pb-5 mb-5">
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon1.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Early Detection is Cheaper: </h5>
          <p className="mb-0 fw_samibold">
            The cost to fix a bug increases the later it's found in the software
            development lifecycle. For example, a bug found during the
            requirements or design phase can be up to 100 times cheaper to fix
            than if it's detected after a product release.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon2.png" alt="" />
          </div>
          <h5 className="my-3 f_heading mb-5">Post-release Costs</h5>
          <p className="mb-0 fw_samibold">
            The Systems Sciences Institute at IBM reported that the cost to fix
            an error found after product release was four to five times more
            than one uncovered during design, and up to 100 times more than one
            identified in the maintenance phase.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon3.png" alt="" />
          </div>
          <h5 className="my-3  f_heading mb-5">Economic Impact</h5>
          <p className="mb-0 fw_samibold">
            The Consortium for IT Software Quality (CISQ) estimated that poor
            software quality resulting from software bugs cost U.S.
            organizations $2.84 trillion in 2020.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon2.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">High-Profile Failures</h5>
          <p className="mb-0 fw_samibold">
            There have been several high-profile software failures that resulted
            in significant financial losses. For instance, the 2012 "Flash
            Crash" linked to algorithmic trading resulted in a temporary loss of
            $1 trillion in market value.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon6.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Development Time</h5>
          <p className="mb-0 fw_samibold">
            In many projects, debugging consumes up to 50% of the development
            time, according to various reports. This means significant time (and
            thus money) is spent on finding and fixing issues rather than on
            creating new features.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon7.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Reputation and Revenue</h5>
          <p className="mb-0 fw_samibold">
            For consumer-facing products, especially in the tech industry, a
            significant bug can tarnish a company's reputation and result in
            loss of users or sales. For instance, Apple's iOS Maps mishap in
            2012 led to public apologies and recommended use of competitors.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon2.png" alt="" />
          </div>
          <h5 className="my-3  fw-bold mb-5">Safety and Litigation Costs</h5>
          <p className="mb-0 fw_samibold">
            In critical systems, like healthcare or aviation, software bugs can
            have dire consequences, leading to safety concerns and significant
            litigation costs.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon8.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Maintenance Overheads</h5>
          <p className="mb-0 fw_samibold">
            A study suggested that around 60-80% of the cost of software goes
            into maintenance, and a significant portion of that is fixing bugs.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon5.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Cost of Downtime</h5>
          <p className="mb-0 fw_samibold">
            For businesses that rely heavily on software (like online retail,
            finance, or service providers), downtime due to software bugs can be
            incredibly costly. Amazon, for example, would lose an estimated
            $220,000 per minute if its site went down
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon7.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Developer Costs: </h5>
          <p className="mb-0 fw_samibold">
            According to Cambridge University, global software developers spend
            approximately $312 billion a year debugging software errors.
          </p>
        </div>
      </div>
    </div> */}
    {/* first-slider end */}
    {/* second-slider start */}
    <h4 className="text-center my-sm-5 py-5 main_heading">
      The Benefits of Code Genie’s Code Audit
    </h4>
    {/* <div className="responsive pb-5 mb-5">
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon1.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Code Functionality</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong> Ensuring that the code works as intended
            and meets the specified requirements.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Functional code is foundational; if it
            doesn't work, nothing else matters. This ensures that the software
            performs its designated tasks and fulfills its purpose.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon2.png" alt="" />
          </div>
          <h5 className="my-3 f_heading mb-5">Logical Errors</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong> Checking for flaws in the logic that
            could cause unexpected behavior or results.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Catching logical errors early prevents
            potential system failures and ensures that the software behaves
            predictably.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon3.png" alt="" />
          </div>
          <h5 className="my-3  f_heading mb-5">Performance and Efficiency:</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong> Analyzing the code for potential
            bottlenecks, inefficiencies, or segments that might slow down
            execution.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Efficient code runs faster, uses fewer
            resources, and provides a better user experience, especially crucial
            in real-time systems.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon2.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Coding Standards and Style</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong> nsuring that the code adheres to
            established organizational or industry coding standards and is
            consistent in style.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Consistent coding practices make the
            codebase maintainable, understandable, and less prone to errors
            introduced by misreading or misunderstanding code segments.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon6.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">
            Readability and Maintainability
          </h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong> Verifying that the code is
            well-organized, adequately commented, and structured in a way that's
            easy for other developers to understand and modify.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Readable and maintainable code reduces
            future developmental costs and allows teams to make updates or find
            and fix bugs more efficiently.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon7.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Security Vulnerabilities</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong>Probing the code for potential security
            flaws or vulnerabilities that could be exploited maliciously.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong>Secure code protects user data, maintains
            trust, and ensures the software is resilient against cyber threats.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon2.png" alt="" />
          </div>
          <h5 className="my-3  fw-bold mb-5">Code Redundancy</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong>Checking for repetitive code or functions
            that could be optimized or consolidated.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong>Reducing redundancy minimizes the chance of
            errors, decreases the codebase size, and simplifies future
            modifications.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon8.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Testing and Coverage</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong>Ensuring that tests exist for the code
            and that they cover a wide range of scenarios.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong>Comprehensive testing ensures that any
            changes or additions to the code don't introduce new errors,
            safeguarding software reliability.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon5.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Integration and Dependencies</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong>Reviewing how new code integrates with
            existing systems and ensuring dependencies are current and
            compatible.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Proper integration ensures smooth system
            operations, while updated dependencies prevent potential security
            risks and compatibility issues.
          </p>
        </div>
      </div>
      <div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height1">
          <div>
            <img src="./img/bt_icon7.png" alt="" />
          </div>
          <h5 className="my-3 mb-5 f_heading">Documentation</h5>
          <p className="mb-0 fw_samibold">
            <strong> Summary: </strong> Checking if the code comes with adequate
            documentation, including inline comments and external documentation.
          </p>
          <p className="mb-0 fw_samibold">
            <strong> Value: </strong> Documentation aids in onboarding new team
            members, supports maintainability, and serves as a reference during
            debugging.
          </p>
        </div>
      </div>
    </div> */}

<div className="row mb-5">
<Slider  {...benefitssettings}> 
     
     {Benefits.map((benefits, index) => (
                                 <AuditItem
                                     title={benefits.title}
                                     content={benefits.content}
                                     key={`benefits_item_${index}`}
                                     icon={benefits.icon} 
                                 />
                                 ))}


                                 

     </Slider>

   </div>


    {/* second-slider end */}
  </div>
  {/* <div class="container px-md-5 px-md-5">
  <div class="row mt-5">
      <div class="col">
          <h4 class="text-primary text-center fw-bold">Smart Contracts Supported by <br> Code Genie</h4>
      </div>
  </div>
  <div class="row mx-md-5 p-5 mb-5">
      <div class="col-md-6 text-white text-center gird_g">
          <div class="p-4">
              <div>
                  <img src="./img/gridicon1.png"class="p_icon" alt="">
                  <h5 class="my-4 fw-bold">Token Contracts</h5>
                  <p>Ensure the secure and accurate management of your token-based assets</p>
              </div>
          </div>
      </div>
      <div class="col-md-6 text-white text-center gird_g2">
          <div class="p-4">
              <div>
                  <img src="./img/gridicon2.png" class="p_icon" alt="">
                  <h5 class="my-4 fw-bold">DeFi Protocols</h5>
                  <p>Ensure the secure and accurate management of your token-based assets</p>
              </div>
          </div>
      </div>
      <div class="col-md-6 text-white text-center gird_g3">
          <div class="p-4">
              <div>
                  <img src="./img/gridicon3.png" alt="">
                  <h5 class="my-4 fw-bold">NFT Marketplaces</h5>
                  <p>Ensure the secure and accurate management of your token-based assets</p>
              </div>
          </div>
      </div>
      <div class="col-md-6 text-white text-center gird_g4">
          <div class="p-4">
              <div>
                  <img src="./img/gridicon4.png" alt="">
                  <h5 class="my-4">Governance Contracts</h5>
                  <p>Ensure the secure and accurate management of your token-based assets</p>
              </div>
          </div>
      </div>
  </div>
  <h4 class="text-primary text-center my-5 py-5 fw-bold">Smart Contract Language Supported<br> by Code Genie</h4>
  <div class="row text-white mx-md-4">
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Solidity</h5>
              <p class="mb-0 fs-14">The most popular and widely used smart contract language for the Ethereum blockchain</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Fe</h5>
              <p class="mb-0 fs-14">A smart contract language for the Fantom Opera blockchain, designed for safety, security, and efficiency</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Vyper</h5>
              <p class="mb-0 fs-14">A Python-based language designed for Ethereum that prioritizes security and simplicity</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Liquidity</h5>
              <p class="mb-0 fs-14">A language for Tezos that is high-level and easy to use, aiming to make smart contract development more accessible</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">LLL (Low-Level Lisp-like Language)</h5>
              <p class="mb-0 fs-14">A low-level Ethereum language for developers who want more control over the bytecode generated by their contracts</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Michelson</h5>
              <p class="mb-0 fs-14">The low-level smart contract language for the Tezos blockchain, used for formal verification and execution</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Serpent</h5>
              <p class="mb-0 fs-14">An older language for Ethereum that is no longer actively maintained but may still be encountered in legacy contracts</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">V</h5>
              <p class="mb-0 fs-14">A smart contract development platform for the Cardano blockchain, using Haskell-inspired language to ensure correctness</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Bamboo</h5>
              <p class="mb-0 fs-14">A language for Ethereum that focuses on safety and expressiveness, designed to be more secure and human-readable</p>
          </div>
      </div>
      <div class="col-md-6 mb-3">
          <div class="back_border p-4 rounded-1">
              <h5 class="fw-bold mb-4">Cadence</h5>
              <p class="mb-0 fs-14">The resource-oriented smart contract language for the Flow blockchain, emphasizing security and usability</p>
          </div>
      </div>
  </div>
  <hr style="color: lightskyblue;">
</div> */}
  <div className="container text-white">
    <div className="row py-sm-5 pt-3 mx-lg-5">
      <div className="col-md-4 pt-sm-5 pb-3">
        <div className="text-md-start text-center">
          <h4 className="mb-sm-5 main_heading">
            Frequently Asked <br /> Questions
          </h4>
          <p className="fw_samibold">
            Couldn't find what you are looking for? <br />
            Write to us at :
            <a href="mailto:mail@codegenie.com"> support@code-genie.ai</a>
          </p>
        </div>
      </div>
      <div className="col-md-8 py-sm-5 py-3">
        <div>
          <div className="accordion" id="regularAccordionRobots">
            <div className="rounded-1 mb-2">
              <h2 id="regularHeadingFirst" className="accordion-header">
                <button
                  className="accordion-button accordion_style px-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapseFirst"
                  aria-expanded="true"
                  aria-controls="regularCollapseFirst"
                >
                  What is Code Genie?
                </button>
              </h2>
              <div
                id="regularCollapseFirst"
                className="accordion-collapse collapse show"
                aria-labelledby="regularHeadingFirst"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Code Genie is an advanced tool powered by OpenAI, designed to
                  conduct thorough code reviews, identify bugs, suggest fixes,
                  and offer refactoring recommendations across multiple
                  programming languages.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeadingSecond">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapseSecond"
                  aria-expanded="false"
                  aria-controls="regularCollapseSecond"
                >
                  Which programming languages does Code Genie support?
                </button>
              </h2>
              <div
                id="regularCollapseSecond"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeadingSecond"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Code Genie supports a vast array of popular programming
                  languages, including Python, JavaScript, Java, C++, and many
                  more.
                </div>
              </div>
            </div>
            <div className=" rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeadingThird">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapseThird"
                  aria-expanded="false"
                  aria-controls="regularCollapseThird"
                >
                  How does Code Genie enhance the code review process?
                </button>
              </h2>
              <div
                id="regularCollapseThird"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeadingThird"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Code Genie uses AI-driven algorithms to identify and highlight
                  potential code vulnerabilities, inefficiencies, and deviations
                  from coding standards, ensuring an exhaustive and detailed
                  review.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading4">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse4"
                  aria-expanded="false"
                  aria-controls="regularCollapse4"
                >
                  Is Code Genie a replacement for human code reviewers?
                </button>
              </h2>
              <div
                id="regularCollapse4"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading4"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  While Code Genie provides thorough reviews, it's designed to
                  complement human reviewers by catching intricate bugs or
                  nuances that may be overlooked, ensuring a more comprehensive
                  review process.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading5">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse5"
                  aria-expanded="false"
                  aria-controls="regularCollapse5"
                >
                  How does Code Genie handle security and privacy?
                </button>
              </h2>
              <div
                id="regularCollapse5"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading5"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Code Genie places utmost importance on data privacy. It
                  doesn't store or share your code, ensuring your proprietary
                  software remains confidential.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading6">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse6"
                  aria-expanded="false"
                  aria-controls="regularCollapse6"
                >
                  How do I integrate Code Genie into my development workflow?
                </button>
              </h2>
              <div
                id="regularCollapse6"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading6"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Code Genie offers integration tools and APIs, allowing
                  seamless integration into popular IDEs, version control
                  platforms, and continuous integration pipelines.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading7">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse7"
                  aria-expanded="false"
                  aria-controls="regularCollapse7"
                >
                  How does Code Genie help with continuous learning for
                  developers?
                </button>
              </h2>
              <div
                id="regularCollapse7"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading7"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  By offering feedback on potential coding mistakes, Code Genie
                  acts as a learning aid, allowing developers to understand
                  their errors and avoid them in future projects.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading8">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse8"
                  aria-expanded="false"
                  aria-controls="regularCollapse8"
                >
                  What kind of bugs can Code Genie detect?
                </button>
              </h2>
              <div
                id="regularCollapse8"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading8"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Code Genie can identify a range of bugs, from simple syntax
                  errors and logical flaws to more complex security
                  vulnerabilities and performance bottlenecks.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading9">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse9"
                  aria-expanded="false"
                  aria-controls="regularCollapse9"
                >
                  Can Code Genie suggest refactoring opportunities?
                </button>
              </h2>
              <div
                id="regularCollapse9"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading9"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  Yes, apart from bug detection, Code Genie can also recommend
                  code refactoring opportunities to improve code quality,
                  readability, and performance.
                </div>
              </div>
            </div>
            <div className="rounded-1 mb-2">
              <h2 className="accordion-header" id="regularHeading9">
                <button
                  className="accordion-button accordion_style collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularCollapse9"
                  aria-expanded="false"
                  aria-controls="regularCollapse9"
                >
                  How do I get started with Code Genie?
                </button>
              </h2>
              <div
                id="regularCollapse9"
                className="accordion-collapse collapse"
                aria-labelledby="regularHeading9"
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body">
                  You can visit Code Genie's official website, sign up, and
                  follow the step-by-step guides provided to integrate the tool
                  into your development environment.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row my-5 pb-sm-5">
      <div className="col-md-6 my-auto">
        <div className="px-lg-5">
          <h4 className="mb-sm-5 mb-3 px-sm-0 px-2 main_heading text-sm-start text-center">
            CHECK OUT OUR SMART CONTRACTS AUDIT PRODUCT
          </h4>
          <p
            className="my-4 fw_samibold px-sm-0 px-2"
            style={{ textAlign: "justify" }}
          >
            At Code Genie, we recognize the critical importance of secure and
            reliable smart contracts. Our advanced auditing tool ensures that
            your smart contracts are meticulously examined and fortified against
            vulnerabilities. Discover how Code Genie can bolster the integrity
            and performance of your smart contracts
          </p>
          <p className="fw_samibold">
            Code Genie was created by OceSha, Inc. Thank you for using our
            technology.
          </p>
        </div>
      </div>
      <div className="col-md-6 my-auto">
      <div>
          <h6 className="text-primary text-center mb-3">HOW IT WORKS</h6>
          <div>
            {/* <video src="https://uiassetbucket.s3.amazonaws.com/how_it_works.mp4" className="img-fluid" controls="" /> */}
            <video 
                        src="https://uiassetbucket.s3.amazonaws.com/how_it_works.mp4"
                            // height="443"
                            width="100%"
                        controls
                        // style={{height:443, display:"flex", alignItems:"center", justifyContent:"center"}}
                        >
                      </video>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container footer_background py-sm-5 py-3">
    <div className="row text-white pt-sm-5">
      <div className="text-center">
        <p className="footer_boder mx-auto py-2 px-3 my-4 rounded-pill fs-14">
          TESTIMONIALS
        </p>
        <h3 className="fw-bold px-5">
          What people say about{" "}
          <span className="text-primary py-1 px-2 rounded-1 mt-md-0 mt-3 border_resp">
            Code Genie
          </span>
        </h3>
      </div>
    </div>
    <div className="slider center py-5">

      <Slider  {...testimonialsettings}>
     
      {testimonials.map((testimonial, index) => (
                                  <TestimonialItem
                                      name={testimonial.name}
                                      content={testimonial.content}
                                      key={`testimonial_item_${index}`}
                                      icon={testimonial.icon}
                                      reference={testimonial.reference}
                                      index={index}
                                  />
                                  ))}

          </Slider>
      </div>
    {/* <div class="slider center py-5">
          <div class="back_border p-3 rounded-1 mx-2 my-auto">
              <div class="d-flex text-white box_bottm_border">
                  <p>This is excellent for the industry and I love the data points.</p>
                  <div><i class="bi bi-twitter"></i></div>
                  <hr>
              </div>
              <div class="d-flex gap-3">
                  <div>
                      <img src="./img/img-1.png" class="img-fluid rounded-circle" style="width: 3rem;" alt="">
                  </div>
                  <div class="text-white fs-14 my-auto">
                      <p class="mb-0 fs-14">Kristina Bruhahn - Continuum CEO,</p>
                      <p class="mb-0">Blockchain Compliance Expert</p>
                  </div>
              </div>
          </div>
          <div class="back_border p-3 rounded-1 mx-2 my-auto">
              <div class="d-flex text-white box_bottm_border">
                  <p>“Code Genie gives all functions analysis and the kind of stuff that is in the function. So it is good to have an auditing perspective. Accuracy is also excellent. (regarding a Solidity smart contract Functionality audit)”</p>
                  <div><i class="bi bi-twitter"></i></div>
                  <hr>
              </div>
              <div class="d-flex gap-3">
                  <div>
                      <img src="./img/img-1.png" class="img-fluid rounded-circle" style="width: 3rem;" alt="">
                  </div>
                  <div class="text-white fs-14 my-auto">
                      <p class="mb-0">Rutvik G</p>
                      <p class="mb-0">Smart Contract Developer</p>
                  </div>
              </div>
          </div>
          <div class="back_border p-3 rounded-1  mx-2 my-auto">
              <div class="d-flex text-white box_bottm_border">
                  <p>I was able to use Code Genie to resolve the issue that I was having with a smart contract in just a few minutes. Surprising excellent time saver no headaches Rohan #mintmadecerfied let’s go.</p>
                  <div><i class="bi bi-twitter"></i></div>
                  <hr>
              </div>
              <div class="d-flex gap-3">
                  <div>
                      <img src="./img/img-1.png" class="img-fluid rounded-circle" style="width: 3rem;" alt="">
                  </div>
                  <div class="text-white fs-14 my-auto">
                      <p class="mb-0">Jamal Woodley - CEO,</p>
                      <p class="mb-0">Conceptualize AI Inc</p>
                  </div>
              </div>
          </div>
          <div class="back_border p-3 rounded-1 mx-2">
              <div class="d-flex text-white box_bottm_border">
                  <p>A review is an evaluation of a publication, product, service, or company or a critical take on current affairs in literature, politics or culture.</p>
                  <div><i class="bi bi-twitter"></i></div>
              </div>
              <div class="d-flex gap-3">
                  <div>
                      <img src="./img/img-1.png" class="img-fluid rounded-circle" style="width: 3rem;" alt="">
                  </div>
                  <div class="text-white fs-14 my-auto">
                      <p class="mb-0">Jacob Andrew</p>
                      <p class="mb-0">svp @snap</p>
                  </div>
              </div>
          </div>
      </div> */}
  </div>
  <div className="container my-sm-5 my-3">
    <div className="">
      <h4 className="main_heading my-3 text-md-start text-center">News</h4>
    </div>
    <div className="row px-sm-0 px-2">
      <div className="col-md-4 mb-md-0 mb-3">
        <div>
          <div
            className="card p-3 text-white"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #475569"
            }}
          >
            {/* <img
              className="card-img-top"
              src="./img/card-img-1.png"
              alt="Card image"
            /> */}  <img src={img51} alt="" />
            <div className="card-body">
              <h5 className="card-title f_heading">
                A Dive into Decentralization: How Code Genie Elevates Smart
                Contracts
              </h5>
              <p className="card-text my-5 fw_samibold">
                In the vast cosmos of technological advancements,
                decentralization emerges as the North Star, guiding countless
                innovations and reshaping the very bedrock of digital
                interactions.
              </p>

              <a href="/blogs/A_Dive_into_Decentralization" className="back_border py-2 px-3 fw_samibold">

                Read more <i className="bi bi-arrow-right-short" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-md-0 mb-3">
        <div>
          <div
            className="card p-3 text-white"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #475569"
            }}
          >
            {/* <img
              className="card-img-top"
              src="./img/card-img-2.png"
              alt="Card image"
            /> */} <img src={img52} alt="" />
            <div className="card-body">
              <h5 className="card-title f_heading">
                The AI Advantage: Code Genie's Mastery Over Traditional Auditing
                Methods
              </h5>
              <p className="card-text my-5 fw_samibold">
                In the intricate landscape of blockchain technology, smart
                contracts stand as pillars of innovation, offering a
                transparent, tamper-proof way to codify and automate agreements.
              </p>

              <a href="/blogs/The_AI_Advantage" className="back_border py-2 px-3 fw_samibold">

                Read more <i className="bi bi-arrow-right-short" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-md-0 mb-3">
        <div>
          <div
            className="card p-3 text-white"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #475569"
            }}
          >
            {/* <img
              className="card-img-top"
              src="./img/card-img-3.png"
              alt="Card image"
            /> */} <img src={img53} alt="" />
            <div className="card-body">
              <h5 className="card-title f_heading">
                Beyond Security: The Multi-Dimensional Benefits of Code Genie’s
                Audits
              </h5>
              <p className="card-text my-5 fw_samibold">
                At the heart of the decentralized revolution, smart contracts
                are changing the way we transact, trust, and transform digital
                agreements. Given their pivotal role..
              </p>

              <a href="/blogs/Beyond_Security" className="back_border py-2 px-3 fw_samibold">

                Read more <i className="bi bi-arrow-right-short" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="text-center my-5">
      <button type="button" className="btn btn-outline-primary px-5">  <a target="_blank" href="/blogs" style={{ textDecoration: 'underline' }}> Read more blogs</a>
      </button>
    </div>
  </div>
  <div
   className="py-5 mx-sm-0 mx-2 backgroundbg"
    // style={{
    //   backgroundImage: "url(./img/icon-bg.png)",
    //   backgroundRepeat: "no-repeat"
    // }}

  >
   
  </div>
  {/* first-slider js start */}
  {/* first-slider js end*/}
  {/*  */}
  {/*  */}
    </>
  );
};

export default AnycodeLandingPage;
