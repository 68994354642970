import { Box, Typography, Container, Divider, SvgIcon, Button, Hidden, IconButton } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight, Height, BorderBottom, AlignVerticalBottom } from "@mui/icons-material";
import blogs_1 from "../assets/img/blogs1.png";
import { IGetBlogs, IGetBlogsDet, IPrompt } from "../redux/api/types";
import { useGetBlogDetailsQuery, useGetBlogDetQuery, useBlogsCountQuery, useGetPromptByProductIdQuery } from "../redux/api/promptApi";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/store";
import BlogIndexPage from "../pages/BlogIndexPage";
import { setModule } from "../redux/features/genieSlice";
import img54 from "../assets/newimg/logo.png";
import { ThumbUp } from "@mui/icons-material";
import { ThumbDown } from "@mui/icons-material";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Helmet } from 'react-helmet';
import rohanphoto from "../assets/img/rohanphoto.jpg";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from 'react-device-detect';
import "./blog.css";
import { useSavingThumbsDownMutation, useSavingThumbsUpMutation } from "../redux/api/promptApi";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

function RedirectToExternalSite() {
  // Redirect to the external site
  //window.location.href = 'https://consulting.code-genie.ai/';
  // You can also use Navigate component, but keep in mind it's intended for internal routing within your React app.
  // return <Navigate to="https://example.com" />;
  return null; // This component doesn't render anything, as the redirect happens via window.location.href
}

const removeHtmlTags = (htmlString: string) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
};

const ADiveBlogs1 = () => {

  const params = useParams();
  const blgurl = params.url || '';
  const repblogurl = blgurl.replace(/_/g, '-');
  const [blogDetails, setBlogDetails] = useState<IGetBlogsDet[]>([]);
  const [prompt, setPrompt] = useState("");
  const [learnMoreUrl, setLearMoreUrl] = useState("");
  const [blogId, setBlogId] = useState<string | null | undefined>(repblogurl || params.url_draft);
  const { data: getblogdetails, error, isLoading } = useGetBlogDetQuery({ url: blogId || '' });
  //const {data: getpromptsdet} = useGetPromptByProductIdQuery({product_id: getblogdetails?.data[0]?.product_id || '' });

  const [blogs, setBlogs] = useState<IGetBlogs[]>([]);

  const [randomInteger, setRandomInteger] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState("")
  const [keyWord, setKeyWord] = useState("")
  const [cta, setCta] = useState("")
  const [metaDesc, setMetaDesc] = useState("")
  const [randoIndex, setRandoIndex] = useState(0)
  const [metaTitle, setmetaTitle] = useState("")
  const [blogCat, setBlogCat] = useState("")
  const [replaced, setReplaced] = useState(0)
  const [isLiked, setIsLiked] = useState('');
  const [isDisLiked, setIsDisLiked] = useState('');

  // State variables for dynamic data
  const [id, setId] = useState('');
  const [headline, setHeadline] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [datePublished, setdatePublished] = useState('');
  const useremail = localStorage.getItem('user_email') ?? '';
  const domain = window.location.hostname;
  const params1 = new URLSearchParams(window.location.search);
  const promptid = params1.get('promptid') ?? "";
  const getBlogsCount = useBlogsCountQuery({ promptid: promptid, userid: useremail, cat: blogCat, query: "", domain: domain });

  const [savingthumbsupMutation] = useSavingThumbsUpMutation();

  const [savingthumbsdownMutation] = useSavingThumbsDownMutation();

  useEffect(() => {
    // Your JavaScript code for SEO purposes

    // Scroll to the top of the landing page
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (getBlogsCount.data) {
      setTotalPages(getBlogsCount.data);
    }
  }, [getBlogsCount.data]);


  useEffect(() => {
    // Calculate random integer when totalPages is available
    if (totalPages !== null) {
      const min = 1;
      const max = totalPages;
      const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomInteger(randomInteger);
    }
  }, [totalPages]);



  const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;

  // Function to fetch data
  async function getPromptsDetails(productid: string, cat: string) {

    const url = baseUrl + 'prompts/getPromptDet?product_id=' + productid;
    const response = await fetch(url);

    //console.log("response", response)
    if (!response.ok || !response.body) {
      throw response.statusText;
    }
    else {
      const result = await response.json();
      //console.log("response body....................");
      const learmoreurl = result.data[0].prompt.split("URL:")[1].replaceAll("\"", "");
      setLearMoreUrl(learmoreurl);
      getMoreBlogsCount(cat);

      //setPrompt(response.body);
    }

  }


  async function getMoreBlogsCount(cat: string) {

    const url = baseUrl + 'prompts/listBlogsCount?cat=' + cat;
    const response = await fetch(url);

    //console.log("response", response)
    if (!response.ok || !response.body) {
      throw response.statusText;
    }
    else {
      const result = await response.json();
      //console.log("response body....................");
      //const learmoreurl = result.data[0];
      const moreBlogCount = result.data;
      getMoreBlogs(cat, moreBlogCount);

      //setPrompt(response.body);
    }

  }



  async function getMoreBlogs(cat: string, totalcount: number) {

    const min = 1;
    const max = totalcount;
    const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;

    const url = baseUrl + 'prompts/listBlogs?limitval=3&skipval=' + randomInteger + '&cat=' + cat;
    const response = await fetch(url);

    //console.log("response", response)
    if (!response.ok || !response.body) {
      throw response.statusText;
    }
    else {
      const result = await response.json();
      //console.log("response body....................");
      //const learmoreurl = result.data[0];
      setBlogs([...result.data]);
      //setPrompt(response.body);
    }

  }


  useEffect(() => {

    if (getblogdetails && getblogdetails.data) {
      console.log("getblogdetails....**---*");
      console.log(getblogdetails);
      var blogSummary = getblogdetails?.data[0]["blog_summary"].split(".");
      if (blogSummary[0].length > 0) {
        setMetaDesc(blogSummary[0]);
      }
      else {
        setMetaDesc(getblogdetails?.data[0]["blog_summary"]);
      }
      var paras = (getblogdetails?.data[0]["blog_det"] || getblogdetails?.data[0]["blog_det_draft"]).split('\n\n');
      var rndidx = paras.length
      var randoIndex = Math.floor(Math.random() * rndidx - 1) + 1;
      if (randoIndex < 0) randoIndex = 0;
      var tmpidx = 0;

      var blogTitle = keyWord + getblogdetails?.data[0]["blog_title"];
      if (blogTitle.length > 70)
        blogTitle = getblogdetails?.data[0]["blog_title"].split(":")[0];
      else
        blogTitle = getblogdetails?.data[0]["blog_title"];

      setmetaTitle(blogTitle);

      if (paras[randoIndex].indexOf("</h3>") > -1 || paras[randoIndex] == "") {

        while (tmpidx < rndidx) {

          if (paras[randoIndex].indexOf("</h3>") < 0 && removeHtmlTags(paras[randoIndex]).length > 0) {
            randoIndex = tmpidx;
          }
          tmpidx++;
        }

      }

      // Update the state with dynamic data
      setId('https://www.code-genie.ai/blogs/' + getblogdetails.data[0].url);
      setHeadline(getblogdetails.data[0].blog_title);
      setDescription(getblogdetails.data[0].blog_summary);
      setImage(getblogdetails.data[0].blog_image_path);
      setdatePublished(getblogdetails.data[0].publish_date)

      //alert(getblogdetails.data[0].publish_date)

      setRandoIndex(randoIndex);
      setBlogDetails([...getblogdetails.data]);
      console.log([...getblogdetails.data]);

      //setPrompt([...(getpromptsdet?.data ?? [])]);
      const product_id = getblogdetails.data[0].product_id;
      const cat = getblogdetails.data[0].cat;
      setBlogCat(getblogdetails.data[0].cat)
      getPromptsDetails(product_id, cat)

      var url = getblogdetails.nexturl.split('::');
      setNextUrl(url[1]);
      setKeyWord(url[0]);
      setCta(url[2]);



      if (blogCat?.toLocaleUpperCase() == "SMART CONTRACT" || blogCat?.toLocaleUpperCase() == "ANY CODE") {
        //RedirectToExternalSite();
      }

    }
    else if (getblogdetails) {



      window.location.href = "/notfound";
    }
  }, [getblogdetails])


  const handleGetBlogId = () => {
    if (getblogdetails && getblogdetails.data && getblogdetails.data.length > 0) {
      //const blogIdFromGetBlogDetails = getblogdetails.data[0].url;
      // Use type assertion to tell TypeScript that the array contains IGetBlogsDet
      const blogIdFromGetBlogDetails = (getblogdetails.data as IGetBlogsDet[])[0].url;

      setBlogId(blogIdFromGetBlogDetails);
    }

  };

  useEffect(() => {
    handleGetBlogId();
  }, [getblogdetails]);


  const navigation = blogDetails.map((blog, index) => {
  })

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleLearnMore = () => {
    const navigation = blogDetails.map((blog, index) => {
      if (blog.cat == 'cat1' || blog.cat == 'SMART CONTRACTS') {
        debugger
        dispatch(setModule("SMART CONTRACTS"));
        navigate('/');
      }
      else {
        window.open(cta, "_blank")
      }
      // else if (blog.cat == 'CONSULTING') {
      //   window.open("http://consulting.code-genie.ai","_blank")
      // }
      // else if (blog.cat == 'AI COURSE') {
      //   window.open("http://ai-course.code-genie.ai","_blank")
      // }
      // else {
      //   dispatch(setModule("Any Code"));
      //   //window.open(learnMoreUrl,"_blank");
      //   window.open("https://codegenieai.myclickfunnels.com/optin","_blank")
      // }
    })
  }
  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
        const status = response.status;

        console.log(status);
        if (status === 'success') {
          toast.success(response.message);
          setSubscribeVal({
            email: '',
            status: ''
          });
        }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }


  const handleLikesClick = async (blogid: string) => {
    try {
      const response = await savingthumbsupMutation({ blogid });
      if ('data' in response) {
        const datas = response.data;

        if (datas.status === 'success') {

          setBlogDetails(prevBlogDetails => {
            return prevBlogDetails.map(blog => {
              if (blog._id === blogid) {
                const likes = parseInt(blog.thums_up, 10) || 0; // Parse to integer or default to 0
                return { ...blog, thums_up: String(likes + 1) }; // Ensure the result is converted back to string
              }
              return blog;
            });
          });

          setIsLiked(blogid);
        }
      } else if ('error' in response) {
        const { error } = response;
        console.log('Error:', error);
      }
    } catch (error) {
      console.error('Error saving thumbs up:', error);
    }
  }

  const handleDisLikeClick = async (blogid: string) => {
    try {
      const response = await savingthumbsdownMutation({ blogid });
      if ('data' in response) {
        const datas = response.data;

        if (datas.status === 'success') {
          setBlogDetails(prevBlogDetails => {
            return prevBlogDetails.map(blog => {
              if (blog._id === blogid) {
                const likes = parseInt(blog.thums_down, 10) || 0; // Parse to integer or default to 0
                return { ...blog, thums_down: String(likes + 1) }; // Ensure the result is converted back to string
              }
              return blog;
            });
          });
          setIsDisLiked(blogid);
        }
      } else if ('error' in response) {
        const { error } = response;
        console.log('Error:', error);
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  }

  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{(blogDetails[0]?.blog_title || blogDetails[0]?.blog_title_draft)}</title>
        <link rel="canonical" href={`https://www.code-genie.ai/blogs/${blogDetails[0]?.url}`} />
        <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />
        <script async src="https://cdn.ampproject.org/v0.js"></script>
        <meta name="title" content={`${keyWord}. ${metaTitle}`} />
        <meta name="author" content="Rohan Hall" />
        <meta name="description" content={`${keyWord}.${metaDesc}`} />
        <meta name="keywords" content={`${(keyWord || blogDetails[0]?.blog_title)}` + ", artificial intelligence, Artificial Intelligence Course, AI Consulting services, ai software development, AI course, ai business course, ai entrepreneur course"} />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${id}"
            },
            "headline": "${headline}",
            "description": "${description}",
            "image": "${image}",
            "author": {
              "@type": "Organization",
              "name": "Code Genie"
            },
            "publisher": {
              "@type": "Organization",
              "name": "",
              "logo": {
                "@type": "ImageObject",
                "url": ""
              }
            },
            "datePublished": "${datePublished}"
          }
          
        `}
        </script>
        <script type="application/ld+json" className="saswp-custom-schema-markup-output">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Code Genie",
            "image": "${image}",
            "brand": "Code Genie",
            "description": "${description}",

            "aggregateRating": { 
                "@type": "AggregateRating", 
                "ratingValue": "5",
                 "ratingCount": "604"

              }


            
          }
          
        `}
        </script>
      </Helmet>


      <Container maxWidth="lg" style={{ backgroundColor: 'white' }}>

        <Box >


          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxHeight: '485px', overflow: 'hidden' }}>
            {blogDetails.map((blog, index) => (
              <>
                <img className="img-fluid auto-resize card-img-top" alt={(blog.blog_title || blog.blog_title_draft)} style={{ maxWidth: '1152px', maxHeight: 'auto', objectFit: 'cover', objectPosition: 'center top' }} src={(blog.blog_image_path || blog.blog_image_path_draft)} title={(blog.blog_title || blog.blog_title_draft)} />

              </>
            ))}

          </div>

          <br></br> <a href="/">Home</a> &gt; <a href={`/blogs?cat=${blogCat}`}>Blogs</a> &gt;

          {blogDetails.map((blog, index) => (
            <>
              <span style={{ color: "#000" }}>{blog.blog_title || blog.blog_title_draft}</span>

            </>
          ))}

          <br></br><br />


          <Typography
            variant="h1"
            color="black"
            fontSize='3rem'
            fontWeight={600}
            textAlign="center"
            mb={10}
          >
            {blogDetails[0]?.blog_title.replaceAll("h3>", "h2>")
              .replace(/^([0-9])+\./g, "")
              .replace(/"/g, "") ||
              blogDetails[0]?.blog_title_draft.replaceAll("h3>", "h2>")
                .replace(/^([0-9])+\./g, "")
                .replace(/"/g, "")}
          </Typography>

          {/* <Typography
            color="black"
            textAlign="justify"
            mb={10}


          >
            {blogDetails.map((blog, index) => (
              <>
                {blog.blog_summary.replaceAll("+","") || blog.blog_summary_draft.replaceAll("+","")}
              </>
            ))}
          </Typography> */}



          <Typography
            variant="body1"
            color="black"
            fontWeight={300}
            textAlign="justify"
            mb={10}

          >

            <br />
            <div className="author-details d-inline-block" >

              {blogDetails.map((blog, index) => (
                <div key={index}>
                  <div style={{ float: "left" }}>
                    {blog.author_image ? <img className="ellipse-18" alt="Ellipse" style={{ height: 40, width: 30, alignItems: "flex-end" }} src={blog.author_image} title="Code Genie: A Novice in Blockchain? Here's Why Code Genie is Your Best Bet" /> : ''}
                    <span>&nbsp; <a href={blog.author_url ? blog.author_url : blog.cta_url} target='_blank'>{blog.author ? blog.author : ''}</a>, Published on: {datePublished.substring(0, 10)/* .split('T')[0] */}</span>
                  </div>
                </div>
              ))}
            </div>
            <br /><br />

            {blogDetails.map((blog, index) => (



              <div key={index}>

                {
                  blog.blog_det ? (blog.blog_det).split('\n\n').map((paragraph, idx) => {
                    if (paragraph.includes('://')) {

                      return (
                        <div key={idx}>
                          <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h3>", "</br><h2>") }} />
                          <br />
                        </div>
                      );
                    } else if (!paragraph.match(/<h3>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {

                      return (
                        <div key={idx}>
                          <br />
                          {/* <span dangerouslySetInnerHTML={{ __html: `<a href="${nextUrl}">${keyWord}</a>` }} /> */}
                          <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />

                        </div>
                      );
                    } else if (paragraph.includes(':')) {

                      if (paragraph.indexOf(':') < 100) {
                        let parts = paragraph.split(':');
                        let label = parts[0];
                        label = label.replace('<h3>', '');
                        label = label.replace('</h3>', '');
                        label = label.replace('<br>', '');
                        let content = "";
                        if (parts.slice(1).indexOf(":") >= 0) {
                          content = parts.slice(1).join(':');
                        }
                        else
                          content = parts[1];

                        content = content.replace('<h3>', '');
                        content = content.replace('</h3>', '');
                        content = content.replace('<br>', '');
                        return (
                          <div key={idx}>

                            <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />

                          </div>
                        );
                        // return (
                        //   <div key={idx}>
                        //     <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                        //   </div>
                        // );
                      }
                      else {

                        return (
                          <div key={idx}>
                            <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                          </div>
                        );
                      }


                    }
                    else {

                      let modifiedParagraph = "";
                      let newparagraph = paragraph.split('\n')
                      if (newparagraph[0].length < 100) {
                        if (newparagraph[0].indexOf("<h3>") !== -1)
                          modifiedParagraph = newparagraph[0];
                        else
                          modifiedParagraph = "<h2>" + newparagraph[0] + "</h2>";
                        for (var i = 1; i < newparagraph.length; i++) {
                          modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }

                      }
                      else {
                        for (var i = 0; i < newparagraph.length; i++) {
                          modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                        //modifiedParagraph = paragraph.replace("<h3>","</br><h3>"); 
                        modifiedParagraph = modifiedParagraph.replace("</br><h3>", "");
                        modifiedParagraph = modifiedParagraph.replace("</h3></br>", "");
                        modifiedParagraph = modifiedParagraph.replace("</br><div", "<div>");
                        modifiedParagraph = modifiedParagraph.replace("<br></div>", "</div>");
                      }

                      return (

                        <div key={idx}>
                          <div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} />
                          <br />
                        </div>
                      );
                    }



                  }

                  ) : (blog.blog_det_draft).split('\n\n').map((paragraph, idx) => {
                    if (paragraph.includes('://')) {

                      return (
                        <div key={idx}>
                          <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h3>", "</br><h2>") }} />
                          <br />
                        </div>
                      );
                    } else if (!paragraph.match(/<h3>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {

                      return (
                        <div key={idx}>
                          <br />
                          {/* <span dangerouslySetInnerHTML={{ __html: `<a href="${nextUrl}">${keyWord}</a>` }} /> */}
                          <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />

                        </div>
                      );
                    } else if (paragraph.includes(':')) {

                      if (paragraph.indexOf(':') < 100) {
                        let parts = paragraph.split(':');
                        let label = parts[0];
                        label = label.replace('<h3>', '');
                        label = label.replace('</h3>', '');
                        label = label.replace('<br>', '');
                        let content = "";
                        if (parts.slice(1).indexOf(":") >= 0) {
                          content = parts.slice(1).join(':');
                        }
                        else
                          content = parts[1];

                        content = content.replace('<h3>', '');
                        content = content.replace('</h3>', '');
                        content = content.replace('<br>', '');
                        return (
                          <div key={idx}>

                            <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />

                          </div>
                        );
                        // return (
                        //   <div key={idx}>
                        //     <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                        //   </div>
                        // );
                      }
                      else {

                        return (
                          <div key={idx}>
                            <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                          </div>
                        );
                      }


                    }
                    else {

                      let modifiedParagraph = "";
                      let newparagraph = paragraph.split('\n')
                      if (newparagraph[0].length < 100) {
                        if (newparagraph[0].indexOf("<h3>") !== -1)
                          modifiedParagraph = newparagraph[0];
                        else
                          modifiedParagraph = "<h2>" + newparagraph[0] + "</h2>";
                        for (var i = 1; i < newparagraph.length; i++) {
                          modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }

                      }
                      else {
                        for (var i = 0; i < newparagraph.length; i++) {
                          modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                        //modifiedParagraph = paragraph.replace("<h3>","</br><h3>"); 
                        modifiedParagraph = modifiedParagraph.replace("</br><h3>", "");
                        modifiedParagraph = modifiedParagraph.replace("</h3></br>", "");
                        modifiedParagraph = modifiedParagraph.replace("</br><div", "<div>");
                        modifiedParagraph = modifiedParagraph.replace("<br></div>", "</div>");
                      }

                      return (

                        <div key={idx}>
                          <div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} />
                          <br />
                        </div>
                      );
                    }
                  }
                  )}
              </div>
            ))}




            <br />

            <Button variant="contained"

              sx={{ background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)", color: "white", float: 'left', margin: '8px', padding: '10px 30px' }}
              onClick={handleLearnMore}
            >
              Learn More<KeyboardArrowRight />
            </Button>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {blogDetails.map((blog, index) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton aria-label="thumbs-up" size="small">
                    <ThumbUp style={{ color: isLiked === blog._id ? '#3494F6' : '#475569' }} onClick={() => handleLikesClick(blog._id)} />
                  </IconButton>
                  <Typography>{blog.thums_up}</Typography>
                </div>))}

              {blogDetails.map((blog, index) => (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                  <IconButton aria-label="thumbs-down" size="small">
                    <ThumbDown style={{ color: isDisLiked === blog._id ? '#F75751' : '#475569' }} onClick={() => handleDisLikeClick(blog._id)} />
                  </IconButton>
                  <Typography>{blog.thums_down}</Typography>
                </div>))}
            </div>
          </Typography>


        </Box>




      </Container >
      {/* <div className="container-fluid my-5">
        <div className="my-5">
          <h4 className="text-primary text-primary1" style={{ color: '#ebebeb' }}>More like this...</h4>
        </div>
        <div className="row">
          {blogs.map((blog, index) => (
            <div className="col-md-4 mb-md-0 mb-3">
              <div>
                <div
                  className="card p-3 text-white"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #475569",
                    marginBottom: "1.5rem",
                  }}
                >
                  <div style={{ maxHeight: 300, minHeight: 300, overflow: 'Hidden' }}><img className="card-img-top" src={blog.blog_image_path_small} alt="" /></div>
                  <div className="card-body">
                    <h4 className="card-title">
                      {blog.blog_title}
                    </h4>
                    <Typography
                      className="card-text my-4"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                      }}
                      variant="body1">
                      {blog.blog_summary}
                    </Typography>
                    <div className="back_border ps-3 py-1">
                      <a target="_blank" rel="noreferrer" href={`/blogs1/${blog.url || blog.url_draft}`} style={{ textDecoration: 'underline', color: '#fff' }} >Delve into this blog further</a></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center my-5">
          <button type="button" className="btn btn-outline-primary px-5">  <a target="_blank" href={`/blogs?cat=${blogCat}`} style={{ textDecoration: 'underline', color: '#fff' }}> Read more blogs</a>
          </button>
        </div>
      </div> */}
      <div className="py-5 mx-sm-0 mx-2 backgroundbg"
      >
        <div className="container">
          <div className="row px-sm-0 px-2">
            {/* <div className="col-lg-3 text-white text-lg-start text-center">
          <div>
            <div>
              
              <img src={img54} alt="" />
            </div>
            <p className="fw_samibold">
              OceSha/Code Genie Los Angeles, Ca 310-748-8901
            </p>
          </div>
        </div> */}
            {/* <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
          <div>
            <h6 className="f_heading mb-4">
              Get news in your mailbox right away!
            </h6>
            <p className="fw_samibold">

              In addition, stay in loop for our exclusive launches and limited
              time deals.
            </p>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Search" />
            <button className="btn btn-primary" type="submit">
              Subscribe
            </button>
          </div>
        </div> */}
            {/* <div className="col-lg-2 col-sm-4">
          <div>
          <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href=" https://codegenieai.myclickfunnels.com/optin">AI Course</a>
                  </li>
                  <li>
                    <a target="_blank" href=" https://codegenieai.myclickfunnels.com/optin">AI Consulting</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
          </div>
        </div> */}
            {/* <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
          <div>
            <div className="text-white">

              <h6 className="fw_samibold">Information</h6>
              <ul
                className="ps-0 mt-3 footer_dec"
                style={{ listStyle: "none" }}
              >
                <li>
                  <a target="_blank" href="/aboutuspage">About Us</a>
                </li>
                <li>
                    <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                  </li>
                  <li>
                    <a target="_blank" href="/contactuspage">Contact us</a>
                  </li>

              </ul>
            </div>
          </div>
        </div> */}
            {/* <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
          <div>
            <div className="text-white">

              <h6 className="fw_samibold">Support</h6>
              <ul
                className="ps-0 mt-3 footer_dec"
                style={{ listStyle: "none" }}
              >
                <li>
                  <a target="_blank" href="/terms">Terms and Conditions</a>
                </li>
                <li>
                  <a target="_blank" href="/privacy">Privacy &amp; Cookie policy</a>
                </li>
              </ul>

            </div>
          </div>
        </div> */}
            {/* <div className="col-lg-3 text-white d-lg-block d-none"> */}

            {/* <div>
            <h5 className="mb-4 f_heading">
              Get news in your mailbox right away!
            </h5>
            <p className="fw_samibold">

              In addition, stay in loop for our exclusive launches and limited
              time deals.
            </p>
          </div> */}
            {/* News Subscribe */}
            {/* <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                    <input className={`form-control ${errors["email"] ? "error" : ""}`}
                      {...register("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {errors["email"] && (
                      <span className="error-message">{errors["email"]?.message}</span>
                    )}
                  </div> */}
            {/*  */}
            {/* </div> */}
          </div>
        </div>
      </div>

    </>
  );
};

export default ADiveBlogs1;