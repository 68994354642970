import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import img54 from '../../assets/newimg/logo.png';
import logo_white from '../../assets/logo_white.png';
import { ReactComponent as TickIcon } from "../../assets/tick_ico.svg";
import { ReactComponent as RoundedRightArrow } from '../../assets/right_rounded_arrow.svg';
import { isMobile } from "react-device-detect";
import rohanImg from '../../assets/traffic_monster/rohanImg.png';
import { ReactComponent as RightArrowIcon } from '../../assets/right_arrow.svg';
import { Typography } from "@mui/material";
import oracle from '../../assets/traffic_monster/oracle.png';
import hp from '../../assets/traffic_monster/hp.png';
import honda from '../../assets/traffic_monster/honda.png';
import american_red_cross from '../../assets/traffic_monster/american_red_cross.png';
import nv_energy from '../../assets/traffic_monster/nv_energy.png';
import avery_dennison from '../../assets/traffic_monster/avery_dennison.png';
import corning from '../../assets/traffic_monster/corning.png';
import neural_network from '../../assets/traffic_monster/neural_network.png';
import Cpu from '../../assets/traffic_monster/Cpu.png';
import './generated_blogs.css';
import export_search_engine_optimization from '../../assets/traffic_monster/Search_engine_optimization.png';
import frames_with_media_content from '../../assets/traffic_monster/frames_with_media_content.png';
import frame_tick from '../../assets/traffic_monster/frame_tick.png';
import Rectangle_92 from '../../assets/traffic_monster/Rectangle_92.png';
import Rectangle_93 from '../../assets/traffic_monster/Rectangle_93.png';
import Rectangle_94 from '../../assets/traffic_monster/Rectangle_94.png';
import Rectangle_95 from '../../assets/traffic_monster/Rectangle_95.png';
import Rectangle_96 from '../../assets/traffic_monster/Rectangle_96.png';
import marketing_strategy from '../../assets/traffic_monster/marketing_strategy.png';
import marketing_strategy_mbv from '../../assets/traffic_monster/marketing_strategy_mbv.png';
import testimonial_rating from '../../assets/traffic_monster/testimonials_rating.png';
import testimonial_videoImg1 from '../../assets/traffic_monster/testimonial_videoImg1.png';
import testimonial_videoImg2 from '../../assets/traffic_monster/testimonial_videoImg2.png';
import Slider from "react-slick";
import TestimonialItem from '../../components/TestimonialItem';
import ArrowCircleLeft from '../../assets/traffic_monster/ArrowCircleLeft.png';
import ArrowCircleRight from '../../assets/traffic_monster/ArrowCircleRight.png';
import testimonialImg1 from '../../assets/traffic_monster/testimonialImg1.png';
import testimonialImg2 from '../../assets/traffic_monster/testimonialImg2.png';
import testimonialImg3 from '../../assets/traffic_monster/testimonialImg3.png';
import equip_your_kids from '../../assets/traffic_monster/equip_your_kids.png';
import signup_frame from '../../assets/traffic_monster/signup_frame.png';
import AI_wealth_mastery_course from '../../assets/traffic_monster/AI_wealth_mastery_course.png';
import AI_fundamental_course from '../../assets/traffic_monster/AI_fundamental_course.png';
import AI_custom_software_dev_course from '../../assets/traffic_monster/AI_custom_software_dev_course.png';
import testimonialImg4 from '../../assets/traffic_monster/testimonialImg4.jpg';
import { Link } from "react-router-dom";
import { useGetgeneratedBlogListingsQuery, useGetrandomBlogListingsQuery } from '../../redux/api/promptApi';
import testimonial_paul_video from '../../assets/traffic_monster/Paul_Claxton_testimonial_vid.mp4';
import { ReactComponent as TickIco } from '../../assets/traffic_monster/tick_icon.svg';
import TimeManagement from '../../assets/traffic_monster/Time management.png';
import techblueprint from '../../assets/traffic_monster/Technical blueprints and architectural research.png';
import userfeedback from '../../assets/traffic_monster/User feedback.png';
import coinsIcon from '../../assets/traffic_monster/coins icon.png';



const TrafficMonsterlandingnew = () => {

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexTextTesti, setCurrentIndexTextTesti] = useState(0);
    const [isWideScreen, setIsWideScreen] = useState(false);
    const [randomInteger, setRandomInteger] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [blogs, setBlogs] = useState<any>();
    const [randomBlogs, setRandomBlogs] = useState<any>();

    const promptid = params.get('promptid') ?? "";

    const videotestimonialData = [
        {
            // image: testimonial_videoImg1,
            video: "https://www.youtube.com/embed/mEyC24nC73k",
            name: "Jay Levin",
            title: "CEO Equip Our Kids LA Weekly Magazine"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/bQ694TD-QCE",
            name: "Giovanni Villa",
            title: "CEO Bella2Bello"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/KLLaclzl5r0",
            name: "Paul Claxton",
            title: "Managing General Partner"
        }

    ];
    const testimonialCount = videotestimonialData.length;
    /* video testimonial */
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 3 + testimonialCount) % testimonialCount);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonialCount);

    };

    const getDisplayedTestimonials = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(videotestimonialData[(currentIndex + i) % testimonialCount]);
        }
        return displayedTestimonials;
    };

    const handlePrevMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNextMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex === videotestimonialData.length - 1 ? 0 : (isMobile ? prevIndex + 1 : 0)));
    };

    /*  */



    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */

    /* Text testimonial */
    const testimonialtoshow = (isMobile) ? 1 : 3;

    var testimonialsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: testimonialtoshow,
        slidesToScroll: 1,
        arrows: true,
    };
    const testimonials = [
        {
            name: "CRAIG SHAH/CEO",
            icon: <img src={testimonialImg4} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Amazing!",
            reference: "EVOKE"
        },
        {
            name: "KURT KUMAR/CEO",
            icon: <img src={testimonialImg3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Traffic Monster solves a problem that every business encounters.  How do you get traffic and leads and ultimately sales to your business.",
            reference: "SAPX"
        },
        {
            name: "JAMAL WOODLEY/CEO",
            icon: <img src={testimonialImg1} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "It's very impressive.  I think this is something that every entrepreneur will be excited about..",
            reference: "MINDMADE BRANDS"
        }
    ];
    const testimonialCount1 = testimonials.length;

    const handlePrevTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex - 1 + testimonialCount1) % testimonialCount1);
    };

    const handleNextTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex + 1) % testimonialCount1);
    };

    const getDisplayedTestimonials1 = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(testimonials[(currentIndexTextTesti + i) % testimonialCount1]);
        }
        return displayedTestimonials;
    };
    /*  */

    useEffect(() => {
        // Calculate random integer when totalPages is available
        if (totalPages !== null) {
            const min = 1;
            const max = totalPages;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
            setRandomInteger(randomInteger);
        }
    }, [totalPages]);

    /* News portion Blogs */
    const supportEmail: string = process.env.REACT_APP_SUPPORT_EMAIL || '';
    const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: ''/* promptid */, userid: ''/* supportEmail */, limitval: 3, skipval: randomInteger });

    useEffect(() => {
        if (getBlogs.data) {
            setBlogs([...getBlogs.data]);
        }
    }, [getBlogs]);
    /*  */
    
    /* random customer blogs */
    const getRandomBlogs = useGetrandomBlogListingsQuery({});

    useEffect(() => {
        if (getRandomBlogs.data) {
            setRandomBlogs([...getRandomBlogs.data]);
        }
    }, [getRandomBlogs]);
    /*  */

    // const domain = window.location.hostname;
    const handleReadMoreClick = (userid: any) => {
        window.open(`/blogs?userid=${userid}`, '_blank');
    }

    const supportUserid = process.env.REACT_APP_SUPPORT_USER_ID || '';

    return (
        <>
            {/* Header */}
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-md">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                {/* <img src="./img/logo.png" alt="" /> */}
                                <img src={img54} className="img-fluid" alt="" />

                            </a>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarScroll"
                                aria-controls="navbarScroll"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="">
                                    <i className="bi bi-list text-white" />
                                </span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarScroll">
                                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link nav_active"
                                            style={{ color: "#4ba5eb", fontWeight: 800 }}
                                            href="/"
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => {
                                        localStorage.setItem('pricing_from_landing', 'true');
                                        navigate("/pricing");
                                    }} style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                            Pricing
                                        </a>
                                    </li>
                                    {/*  <li className="nav-item" style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup?revgems-session-id=45a7e1ae-a67a-4197-9973-f002c2338e37&_gl=1*159ev21*_gcl_au*MjI5MjQ3ODg2LjE3MTQ3MzU2NTE.*_ga*MTAyOTUzNzQyMC4xNzE0NzM1NjU0*_ga_YJYFH7ZS27*MTcxNTY3NjkxOC40LjEuMTcxNTY3NzIwMy41MS4wLjA">
                                            Affiliates
                                        </a>
                                    </li> */}
                                    <li className="nav-item" style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                                            Affiliates
                                        </a>
                                    </li>

                                    <li className="nav-item" onClick={() => {
                                        navigate("/contactus");
                                    }} style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                            Contact
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => {
                                        window.open(`/blogs?userid=${supportUserid}`, '_blank');
                                    }} style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                            Blogs
                                        </a>
                                    </li>
                                </ul>
                                <div className="d-flex gap-4" role="search" style={{justifyContent: isMobile ? 'center' : ''}}>
                                    <button className="btn btn-outline-primary px-3"
                                        onClick={() => {
                                            if (localStorage.getItem('logged_in') === 'true') {
                                                navigate('/codegenie/generated-blogs');
                                            } else {
                                                navigate("/signin");
                                            }
                                        }}>Sign  In</button>
                                    <button className="button_leniar px-3" type="submit" onClick={() => {
                                        navigate("/signup");
                                    }}>
                                        {/* Try Now */}Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <hr className="mt-3" style={{ color: "#9aa0a3" }} />
                </div>
            </div>
            {/*  */}

            <div className="container">
                <div className="row  mb-5">{/* my-sm-5 */}
                    <div className="col-md-5 text-white text-center">{/* mt-2 */}
                        <div className="px-sm-0 px-2">
                            <div>
                                <div className="mb-5">
                                    <img
                                        src="./img/text-1.png"
                                        className="img-fluid"
                                        style={{ width: "80%" }}
                                        alt=""
                                    />
                                </div>
                                <div className="Ac-1">
                                    <div className="frame-1707">
                                        <div /* className="frame-33345" */>
                                            <div /* className="text-wrapper-7775" */ style={{ textAlign: /* isMobile ? 'center' :  */'left', fontWeight: 800, fontSize: '62px', lineHeight: '75.03px' }}>Traffic Monster.</div> <br></br>
                                            {/* <div style={{ textAlign: 'left', fontWeight: 800, fontSize: '32px' }}>Use AI to Automatically Generate High-Quality, SEO-Enriched Blogs for your Niche</div> */}
                                            <div style={{ textAlign: /* isMobile ? 'center' :  */'left', fontWeight: 'bold', fontSize: '32px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>Create Blogs Automatically</span>
                                                    <span>From Your Website Content</span>
                                                    {/* <span>Website Content, Podcast, Or Videos</span> */}
                                                </div>
                                            </div>
                                        </div> <br></br>

                                        <div style={{ textAlign: 'left' }}>
                                            <ul style={{ listStyleType: 'none', paddingLeft: 0, fontWeight: 800, fontSize: isMobile ? '19px' : '28px'/* isMobile ? '30px' : '32px' */, lineHeight: '32px', letterSpacing: '0.04px', gap: '18px' }}>
                                                <li style={{ padding: '1%', display: 'flex', alignItems: 'center' }}><TickIco /><span style={{ padding: '1%' }}>{/* Get Traffic */}Improved SEO and Discoverability</span></li>
                                                <li style={{ padding: '1%', display: 'flex', alignItems: 'center' }}><TickIco /><span style={{ padding: '1%' }}>{/* Get Traffic */}Wider Audience Reach</span></li>
                                                <li style={{ padding: '1%', display: 'flex', alignItems: 'center' }}><TickIco /><span style={{ padding: '1%' }}>{/* Get Leads */}Content Repurposing</span></li>
                                                <li style={{ padding: '1%', display: 'flex', alignItems: 'center' }}><TickIco /><span style={{ padding: '1%' }}>{/* Get Customers */}Enhanced Engagement</span></li>
                                                <li style={{ padding: '1%', display: 'flex', alignItems: 'center' }}><TickIco /><span style={{ padding: '1%' }}>{/* Get Revenue */}Monetization Opportunities</span></li>
                                                <li style={{ padding: '1%', display: 'flex', alignItems: 'center' }}><TickIco /><span style={{ padding: '1%' }}>{/* Get Revenue */}Sharable to Social Media</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div> <br></br>
                            </div>
                            <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
                                <button className="button_leniar px-5 my-3"
                                    onClick={() => {
                                        navigate("/signup");
                                    }}
                                    style={{ borderRadius: '3px', /* height: isMobile ? '100%' : '' */height: '50px' }}
                                >
                                    Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 my-auto">
                        <div className="px-sm-0 px-2">
                            <button className="button_leniar px-5 my-3"
                                /* onClick={() => {
                                    navigate("/signup");
                                }} */
                                style={{ borderRadius: '3px', width: '100%', paddingBottom: isMobile ? '5%' : '2%', height: '100%' }}
                            >
                                {/* Get started for free <i className="bi bi-arrow-right " /> */}
                                Know More About Traffic Monster From This Video
                            </button>
                            {/* <img src={rohanImg} style={{ maxWidth: isMobile ? '100%' : '' }} className="img-fluid-code-L1" alt="" /> */}

                            <iframe
                                width="100%"
                                src="https://www.youtube.com/embed/C2awPePNTx4?autoplay=1&mute=1&rel=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="img-fluid-code-L1"
                                style={{ maxWidth: isMobile ? '100%' : '', height: isMobile ? '300px' : '600px' }}
                            ></iframe>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="py-5 mx-sm-0 mx-2" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                    <Typography style={{ textAlign: 'center', width: "100%", marginBottom: isMobile ? '14%' : '' }}>By Rohan Hall</Typography>
                    <div className="gap-3 justify-content-center" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "center" }}>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={oracle} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={hp} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={honda} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={american_red_cross} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={nv_energy} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={avery_dennison} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                        <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "5px" }}>
                            <img src={corning} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </div>
                    </div>
                    <Typography style={{ textAlign: 'center', width: "100%", marginTop: isMobile ? '14%' : '4%' }}>"This is incredible. Traffic Monster's only goal is to drive traffic to your website." - Jamal Woodley</Typography>
                </div>
                <hr></hr>

                {/* what if you had 1000 AI agents section */}
                <div className="container">
                    <div className="row mt-sm-5">
                        <div className="col-md-2 text-center my-auto">
                            <div>
                                &nbsp;
                            </div>

                        </div>

                        {isWideScreen ?
                            <div className="col-md-8 my-auto" style={{ width: '100%' }}>
                                <Typography style={{ fontSize: '56px', fontWeight: 'bolder', color: '#fff', textAlign: 'center' }}>1-CLICK BLOG<br /> CREATION AND <br />AUTOMATION WITH AI</Typography>
                                {/* <Typography style={{ fontSize: '24px', fontWeight: 'lighter', color: '#fff', textAlign: 'center' }}>1-CLICK BLOG CREATION AND AUTOMATION WITH AI</Typography> */}

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <Typography style={{ fontSize: '32px', fontWeight: 'bolder', textAlign: 'left', color: '#fff' }}>WHAT YOU GET!</Typography>
                                    <Typography style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>With 1-CLICK, our AI Agents will...</Typography>
                                    <div style={{ textAlign: 'left' }}>
                                        <ul style={{ color: '#fff', listStyleType: 'none', paddingLeft: 0, fontWeight: 'lighter', fontSize: '18px', lineHeight: '24px', letterSpacing: '0.04px', gap: '18px' }}>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Create Dozens of Personalized, High-Quality Blogs for your Brand Every Month */}Analyze content from your website content{/* , podcast, or video */}</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* SEO Enhance Each Blog With the Best SEO Keywords */}Write Personalized, High-Quality Blogs for your Brand Daily</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Add Engagement, Social Sharing, and Leads Capturing Features */}Add Keywords, Engagement, Social Sharing, Leads Capturing Features</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Submit all Your Blogs to Google to Get Immediate Indexed and Ranked */}Create and Publish New Blogs Daily to Google for Indexing and Ranking</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Submit all Your Blogs to Google to Get Immediate Indexed and Ranked */}Create, Publish, Rank Dozens of Blogs Monthly for Each Traffic</span></li>
                                        </ul>
                                    </div>
                                    <Typography style={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', textAlign: 'left' }}>100% AUTOMATED</Typography>
                                    <Typography style={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', textAlign: 'left' }}>...with the ability to edit, customize, change your blog content.</Typography>

                                    {/* <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}> */}
                                    <div className="row" style={{ padding: '5%' }}>
                                        <div className="col-md-12" style={{ marginTop: '6%' }}>
                                            <iframe
                                                width="100%"
                                                src="https://www.youtube.com/embed/QPaseDAoLJw?autoplay=0&mute=1&rel=0"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                className="img-fluid-code-L1"
                                                style={{ maxWidth: isMobile ? '100%' : '', height: isMobile ? '300px' : '500px' }}
                                            ></iframe>
                                            {/* <img src={Cpu} />
                                                <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                    Innovative AI technology
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    Code Genie AI is an innovative AI technology company that uses Artificial Intelligence to create AI Agents, integrated with high quality, SEO enriched blog content page to drive traffic, create leads, increase your revenue.
                                                </Typography> */}
                                        </div>

                                        {/* <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                <img src={neural_network} />
                                            </div> */}
                                    </div>
                                    {/* </div> */}
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6 d-flex align-items-center">
                                                <img src={export_search_engine_optimization} />
                                            </div>

                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                    Driving traffic
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    The technology uses a model that creates blogs and content that targets your business, your industry, and is focused on driving traffic to your website.  AI Agents are used connect and educate potential customers about your brand.
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                    Content creation
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    The product is a set it and leave it model. Unique, high-quality content is automatically created, scheduled, posted, and indexed en masse with no need for your intervention. Google will find and pick up the pages and present them in their search results. You can edit the content as needed.
                                                </Typography>
                                            </div>

                                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                <img src={frames_with_media_content} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className="col-md-8 my-auto">
                                <Typography style={{ fontSize: '56px', fontWeight: 'bolder', color: '#fff', textAlign: 'center' }}>1-CLICK BLOG<br /> CREATION AND <br />AUTOMATION WITH AI</Typography>
                                {/* <Typography style={{ fontSize: '24px', fontWeight: 'lighter', color: '#fff', textAlign: 'center' }}>1-CLICK BLOG CREATION AND AUTOMATION WITH AI</Typography> */}

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <Typography style={{ fontSize: '32px', fontWeight: 'bolder', textAlign: 'left', color: '#fff' }}>WHAT YOU GET!</Typography>
                                    <Typography style={{ fontSize: '24px', fontWeight: 'lighter', textAlign: 'left', color: '#fff' }}>With 1-CLICK, our AI Agents will...</Typography>
                                    <div style={{ textAlign: 'left' }}>
                                        <ul style={{ color: '#fff', listStyleType: 'none', paddingLeft: 0, fontWeight: 'lighter', fontSize: '18px', lineHeight: '24px', letterSpacing: '0.04px', gap: '18px' }}>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Create Dozens of Personalized, High-Quality Blogs for your Brand Every Month */}Analyze content from your website content{/* , podcast, or video */}</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* SEO Enhance Each Blog With the Best SEO Keywords */}Write Personalized, High-Quality Blogs for your Brand Daily</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Add Engagement, Social Sharing, and Leads Capturing Features */}Add Keywords, Engagement, Social Sharing, Leads Capturing Features</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Submit all Your Blogs to Google to Get Immediate Indexed and Ranked */}Create and Publish New Blogs Daily to Google for Indexing and Ranking</span></li>
                                            <li style={{ padding: '1%', display: 'flex', alignItems: "flex-start" }}><span style={{ marginRight: "10px", marginTop: '1%' }}><TickIco /></span><span style={{ padding: '1%' }}>{/* Submit all Your Blogs to Google to Get Immediate Indexed and Ranked */}Create, Publish, Rank Dozens of Blogs Monthly for Each Traffic</span></li>
                                        </ul>
                                    </div>
                                    <Typography style={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', textAlign: 'left' }}>100% AUTOMATED</Typography>
                                    <Typography style={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', textAlign: 'left' }}>...with the ability to edit, customize, change your blog content.</Typography>
                                    {/* <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}> */}
                                    <div className="row" style={{ marginTop: isMobile ? '10%' : '' }}>
                                        <div className="col-md-12" style={{ marginTop: '6%' }}>
                                            <iframe
                                                width="100%"
                                                src="https://www.youtube.com/embed/QPaseDAoLJw?autoplay=0&mute=1&rel=0"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                className="img-fluid-code-L1"
                                                style={{ maxWidth: isMobile ? '100%' : '', height: isMobile ? '300px' : '500px' }}
                                            ></iframe>
                                            {/* <img src={Cpu} />
                                                <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                    Innovative AI technology
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    Code Genie AI is an innovative AI technology company that uses Artificial Intelligence to create AI Agents, integrated with high quality, SEO enriched blog content page to drive traffic, create leads, increase your revenue.
                                                </Typography> */}
                                        </div>

                                        {/* <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                <img src={neural_network} />
                                            </div> */}
                                    </div>
                                    {/* </div> */}
                                </div>

                                <div className="row" style={{ marginTop: '4%' }}>
                                    <Typography style={{ fontSize: '62px', fontWeight: 'bolder', color: '#fff', textAlign: 'center' }}>Traffic Monster</Typography>
                                    <Typography style={{ fontSize: '24px', fontWeight: 'lighter', color: '#fff', textAlign: 'center' }}>Traffic Monster helps traffic hosts and guests maximize their reach, efficiency, and impact with AI-driven conversions.</Typography>
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '30px', fontWeight: 'Bold'/* , textAlign: 'left' */ }}>
                                                    Enhanced Online Presence and Visibility
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    1. Traffic Monster converts traffics into SEO-enriched blog posts.<br />
                                                    2. This boosts search engine rankings and discoverability.
                                                </Typography>
                                            </div>

                                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                <img src={neural_network} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6 d-flex align-items-center">
                                                <img src={TimeManagement} />
                                            </div>

                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '30px', fontWeight: 'Bold' }}>
                                                    Time and Effort Efficiency
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    1. Traffic Monster automates transcription and SEO optimization.<br />
                                                    2. Hosts focus on content creation while AI handles the rest.
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '30px', fontWeight: 'Bold' }}>
                                                    Broader Audience Reach
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    1. Content is available in audio, video, and text formats.<br />
                                                    2. This caters to diverse preferences, increasing exposure for guests.
                                                </Typography>
                                            </div>

                                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                <img src={techblueprint} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6 d-flex align-items-center">
                                                <img src={userfeedback} />
                                            </div>

                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '30px', fontWeight: 'Bold' }}>
                                                    Increased Engagement and Interaction
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    1. Blog posts include comment sections and social media sharing.<br />
                                                    2. Written content enhances audience connection.
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                    <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                        <div className="row" style={{ padding: '5%' }}>
                                            <div className="col-md-6" style={{ marginTop: '6%' }}>
                                                <img src={Cpu} />
                                                <Typography style={{ fontSize: '30px', fontWeight: 'Bold' }}>
                                                    Long-Term Value and Monetization Opportunities
                                                </Typography>
                                                <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                    1. SEO-enriched blog posts provide ongoing traffic and value.<br />
                                                    2. Hosts and guests can monetize through affiliate marketing and sponsored content.
                                                </Typography>
                                            </div>

                                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                <img src={coinsIcon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        <div className="col-md-2 text-center my-auto">
                            <div>
                                &nbsp;
                            </div>

                        </div>
                    </div>
                </div>
                {/* section end */}
                <hr></hr>

                {/* traffic Monster section */}
                <div className="container">
                    <div className="row mt-sm-5">

                        <div className="col-md-12 my-auto">
                            <Typography style={{ fontSize: '56px', fontWeight: 'bolder', color: '#fff', textAlign: 'center' }}>Traffic Monster</Typography>
                            <Typography style={{ fontSize: '24px', fontWeight: 'lighter', color: '#fff', textAlign: 'center' }}>Automated end-to-end solution to convert traffics and videos to SEO optimized blogs</Typography>

                            <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                    <div className="row" style={{ padding: '3%' }}>
                                        <div className="col-md-6" style={{ marginTop: '4%' }}>
                                            <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                <img src={frame_tick} style={{ marginBottom: '1%' }} /><span style={{ padding: '3%' }}>EASY TO USE</span>
                                            </Typography>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>1. Effortless Setup: </b>No need to learn AI, SEO, or blogging. Just sign up, paste your YouTube video URL, and click a button. Our AI handles the rest, generating blogs and content for you within seconds.
                                            </Typography><br></br>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>2. User-Friendly: </b>Traffic Monster is designed for anyone. If you can copy and paste a YouTube URL, you can start generating blog content immediately, with no technical knowledge required.
                                            </Typography>

                                            <button className="button_leniar px-5 my-3"
                                                onClick={() => {
                                                    navigate("/signup");
                                                }}
                                                style={{ borderRadius: '3px', /* height: isMobile ? '12%' : '' */height: '50px' }}
                                            >
                                                {/* Get started for free <i className="bi bi-arrow-right " /> */}
                                                Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                                            </button>
                                        </div>

                                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                                            <img src={Rectangle_92} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                    <div className="row" style={{ padding: '3%' }}>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <img src={Rectangle_93} />
                                        </div>

                                        <div className="col-md-6" style={{ marginTop: '6%' }}>
                                            <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                <img src={frame_tick} style={{ marginBottom: '1%' }} /><span style={{ padding: '3%' }}>PERSONALIZED</span>
                                            </Typography>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>1. Custom Blog Creation: </b>Blog content is generated from the traffic video transcript. The blogs are personalized based on the transcript, enhancing the traffic guest's brand awareness and providing other benefits.
                                            </Typography><br></br>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>2. Brand-Specific Content: </b> Each blog is tailored to the traffic guest, boosting their brand visibility and leveraging the advantages of SEOenriched, personalized content.
                                            </Typography>
                                            <button className="button_leniar px-5 my-3"
                                                onClick={() => {
                                                    navigate("/signup");
                                                }}
                                                style={{ borderRadius: '3px', /* height: isMobile ? '12%' : '' */height: '50px' }}
                                            >
                                                {/* Get started for free <i className="bi bi-arrow-right " /> */}
                                                Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                    <div className="row" style={{ padding: '3%' }}>
                                        <div className="col-md-6" style={{ marginTop: '6%' }}>
                                            <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                <img src={frame_tick} style={{ marginBottom: '1%' }} /><span style={{ padding: '3%' }}>HIGH QUALITY</span>
                                            </Typography>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>1. Superior Quality: </b>Content is crafted to be as high quality as or better than human-written content, with no grammar, spelling, or other errors often found in marketing or SEO team outputs.
                                            </Typography><br></br>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>2. SEO Advantage: </b> Google prioritizes content quality in its search rankings. It doesn't matter if the content is AI or human-written; high-quality content boosts your ranking.
                                            </Typography>
                                            <button className="button_leniar px-5 my-3"
                                                onClick={() => {
                                                    navigate("/signup");
                                                }}
                                                style={{ borderRadius: '3px', /* height: isMobile ? '12%' : '' */height: '50px' }}
                                            >
                                                {/* Get started for free <i className="bi bi-arrow-right " /> */}
                                                Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                                            </button>
                                        </div>

                                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                                            <img src={Rectangle_94} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                    <div className="row" style={{ padding: '3%' }}>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <img src={Rectangle_95} />
                                        </div>

                                        <div className="col-md-6" style={{ marginTop: '6%' }}>
                                            <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                <img src={frame_tick} style={{ marginBottom: '1%' }} /><span style={{ padding: '3%' }}>SCALABLE</span>
                                            </Typography>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>1. Scalable Content Production: </b>Generate 100 to over 1,000 SEOenriched content pages, leveraging the law of averages. More pages mean more brand awareness and improved search engine rankings.
                                            </Typography><br></br>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>2. Targeted SEO Topics: </b> The content covers SEO-friendly topics important to your readers, increasing their interest in your products and services while boosting your online visibility.
                                            </Typography>
                                            <button className="button_leniar px-5 my-3"
                                                onClick={() => {
                                                    navigate("/signup");
                                                }}
                                                style={{ borderRadius: '3px', /* height: isMobile ? '12%' : '' */height: '50px' }}
                                            >
                                                {/* Get started for free <i className="bi bi-arrow-right " /> */}
                                                Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-sm-0 px-2" style={{ marginTop: '5%' }}>
                                <div className="back_border p-4 text-white" style={{ borderRadius: '16px' }}>
                                    <div className="row" style={{ padding: '3%' }}>
                                        <div className="col-md-6" style={{ marginTop: '6%' }}>
                                            <Typography style={{ fontSize: '32px', fontWeight: 'Bold' }}>
                                                <img src={frame_tick} style={{ marginBottom: '1%' }} /><span style={{ padding: '3%' }}>INTERACTIVE & ENGAGING</span>
                                            </Typography>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>1. Interactive and Engaging Content: </b>The AI Agent and diverse content types create SEO-enriched, interactive, educational, and conversion-focused pages, boosting engagement with your content and enhancing your business value.
                                            </Typography><br></br>
                                            <Typography style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                                                <b>2. Real-Time Interaction: </b> Customers can interact with the SEO-optimized content and AI Agents in real-time, learning about your business, services, and products. This can significantly increases brand awareness, and online presence.
                                            </Typography>
                                            <button className="button_leniar px-5 my-3"
                                                onClick={() => {
                                                    navigate("/signup");
                                                }}
                                                style={{ borderRadius: '3px', /* height: isMobile ? '12%' : '' */height: '50px' }}
                                            >
                                                {/* Get started for free <i className="bi bi-arrow-right " /> */}
                                                Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                                            </button>
                                        </div>

                                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                                            <img src={Rectangle_96} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* section end */}

                {/* Marketing strategy 101 for entrepreneur section */}
                {/* <div className="container">
                    <div className="row mt-sm-5">

                        <div className="col-md-12 my-auto">
                            <Typography style={{ fontSize: '56px', fontWeight: 'bolder', color: '#fff', textAlign: 'center' }}>Marketing Strategy 101</Typography>
                            <Typography style={{ fontSize: '56px', fontWeight: 'bolder', color: '#fff', textAlign: 'center' }}>for Entrepreneur</Typography>
                            <div className="row" style={{ marginTop: '6%' }}>
                                {isMobile ? <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}><img src={marketing_strategy_mbv} /></div>
                                    :
                                    <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}><img src={marketing_strategy} /></div>
                                }
                            </div>
                        </div>

                    </div>
                </div> */}
                {/* section end */}


                {/* Testimonials Section */}
                <div className="container py-sm-5 py-3">
                    <div className="row text-white pt-sm-5">
                        <div className="text-center">
                            <img src={testimonial_rating} />
                            <p className="footer_boder mx-auto py-2 px-3 my-4 rounded-pill fs-14" style={{ fontSize: '14px', color: '#4BA5EB', border: '1px solid #4BA5EB' }}>
                                TESTIMONIALS
                            </p>
                            <h3 className="fw-bold px-5">
                                What People SayAbout{" "}
                                <span className="text-primary py-1 px-2 rounded-1 mt-md-0 mt-3 border_resp">
                                    Traffic Monster
                                </span>
                            </h3>
                        </div>
                    </div>
                    {/* Video Testimonial section */}
                    <div className="slider center py-5">
                        {isMobile ?
                            <>
                                <div className="row">

                                    <div className="col-md-3">
                                        {/* <img src={videotestimonialData[currentIndex].image} alt="Testimonial" style={{ marginLeft: '22%' }} /> */}
                                        <iframe
                                            // width="560"
                                            // height="315"
                                            style={{ marginLeft: '9%' }}
                                            src={videotestimonialData[currentIndex].video}
                                            // title={`Testimonial Video ${videotestimonialData[currentIndex] + 1}`}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                        <Typography style={{ fontWeight: 'bold', fontSize: '17.85px', textAlign: 'center' }}>{videotestimonialData[currentIndex].name}</Typography>
                                        <Typography style={{ fontWeight: 'lighter', fontSize: '15px', textAlign: 'center' }}>{videotestimonialData[currentIndex].title}</Typography>
                                    </div>

                                </div>
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-md-auto" style={{ cursor: 'pointer', textAlign: 'center' }} >
                                        <img src={ArrowCircleLeft} alt="Previous" onClick={handlePrevMob} />
                                        <img src={ArrowCircleRight} alt="Next" onClick={handleNextMob} />
                                    </div>
                                </div>
                            </>
                            :
                            <div className="row align-items-center" style={{ marginLeft: '3%' }}>
                                <div className="col-md-1" style={{ cursor: 'pointer' }} onClick={handlePrev}>
                                    <img src={ArrowCircleLeft} alt="Previous" />
                                </div>
                                {/* videotestimonialData.slice(currentIndex, currentIndex + 3) */getDisplayedTestimonials().map((testimonial, index) => (
                                    <div className="col-md-3" key={index}>
                                        {/* <img src={testimonial.image} alt="Testimonial" style={{ width: isWideScreen ? '100%' : '' }} /> */}
                                        <iframe
                                            // width="560"
                                            // height="315"
                                            style={{ width: isWideScreen ? '100%' : '100%', height: '190px' }}
                                            src={testimonial.video}
                                            title={`Testimonial Video ${index + 1}`}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                        <Typography style={{ fontWeight: 'bold', fontSize: '17.85px', textAlign: 'center' }}>{testimonial.name}</Typography>
                                        <Typography style={{ fontWeight: 'lighter', fontSize: '15px', textAlign: 'center' }}>{testimonial.title}</Typography>
                                    </div>
                                ))}
                                <div className="col-md-1" style={{ justifyContent: 'right', display: 'flex', cursor: 'pointer' }} onClick={handleNext} >
                                    <img src={ArrowCircleRight} alt="Next" />
                                </div>
                            </div>
                        }
                    </div>
                    {/* videotestimonial section end */}

                    <div className="row text-white pt-sm-5">
                        <div className="text-center">
                            <h3 className="fw-bold px-5">
                                What More Users Say About Traffic Monster?
                            </h3>
                        </div>
                    </div>

                    {/* Text Testimonial section */}
                    <div className="slider center py-5">

                        <Slider  {...testimonialsettings} >

                            {/* testimonials.slice(currentIndexTextTesti, currentIndexTextTesti + 3) */getDisplayedTestimonials1().map((testimonial: any, index: any) => (
                                <div key={`testimonial_item_${index}`}>
                                    <TestimonialItem
                                        name={testimonial.name}
                                        content={testimonial.content}
                                        key={`testimonial_item_${index}`}
                                        icon={testimonial.icon}
                                        reference={testimonial.reference}
                                        index={index}
                                    />
                                </div>
                            ))}


                        </Slider>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-12" style={{ cursor: 'pointer', textAlign: 'center' }} >
                                <img src={ArrowCircleLeft} alt="Previous" onClick={handlePrevTextTesti} />
                                <img src={ArrowCircleRight} alt="Next" onClick={handleNextTextTesti} />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '4%' }}>
                            <button className="button_leniar px-5 my-3"
                                onClick={() => {
                                    navigate("/signup");
                                }}
                                style={{ borderRadius: '3px', /* height: isMobile ? '100%' : '' */height: '50px' }}
                            >
                                Start Your Free Trial Today <i className="bi bi-chevron-double-right"></i>
                            </button>
                        </div>
                    </div>
                    {/* text testimonial section end */}
                </div >

                {/* Testimonial section end */}

                {/* codegenie products section */}
                <div className="container text-white">
                    <div className="row py-sm-5 pt-3 mx-lg-5">
                        <div className="col-md-5 pt-sm-5 pb-3">
                            <div className="text-md-start text-center">
                                <h4 className="mb-sm-5 main_heading" style={{ color: '#fff' }}>
                                    Other Code Genie Products
                                </h4>
                            </div>
                        </div>
                        <div className="col-md-7 py-sm-5 py-3">
                            <hr style={{ borderWidth: '3px' }}></hr>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <img src={AI_wealth_mastery_course} />
                            <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Artificial Intelligence Wealth Mastery Course</Typography>
                            <Typography style={{ fontSize: 16, fontWeight: 'normal' }}>This course teaches entrepreneurs how to build wealth with Artificial Intelligence</Typography>
                            <button className="button_leniar px-5 my-3"
                                onClick={() => {
                                    window.open("https://www.rohanhall.com/ai-wealth-mastery", "_blank");
                                }}
                                style={{ borderRadius: '7px', width: '100%' }}
                            >
                                Read more <RightArrowIcon />
                            </button>
                        </div>
                        <div className="col-md-4">
                            <img src={AI_fundamental_course} />
                            <Typography style={{ fontSize: 22, fontWeight: 'bold', height: '65px' }}>Artificial Intelligence Fundamentals Course</Typography>
                            <Typography style={{ fontSize: 16, fontWeight: 'normal' }}>This course teaches professionals the basics and fundamentals of Artificial Intelligence</Typography>
                            <button className="button_leniar px-5 my-3"
                                onClick={() => {
                                    window.open("https://www.rohanhall.com/ai-fundamentals-course", '_blank');
                                }}
                                style={{ borderRadius: '7px', width: '100%' }}
                            >
                                Read more <RightArrowIcon />
                            </button>
                        </div>
                        <div className="col-md-4">
                            <img src={AI_custom_software_dev_course} />
                            <Typography style={{ fontSize: 22, fontWeight: 'bold', height: '70px' }}>Artificial Intelligence Software Development</Typography>
                            <Typography style={{ fontSize: 16, fontWeight: 'normal', height: '43px' }}>The Code Genie AI team will build your AI technology for you.</Typography>
                            <button className="button_leniar px-5 my-3"
                                onClick={() => {
                                    window.open("https://www.rohanhall.com/software-development", '_blank');
                                }}
                                style={{ borderRadius: '7px', width: '100%' }}
                            >
                                Read more <RightArrowIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                {/* section end */}

                {/* some of our customers */}

                {/* <div className="row text-white pt-sm-5">
                    <div className="text-center">
                        <h3 className="fw-bold px-5">
                            Some of Our Customers
                        </h3>
                    </div>
                </div>
                <div className="container my-sm-5 my-3">
                    <div className="row">
                        {randomBlogs && randomBlogs.map((blog: any, index: any) => (
                            <div className="col-md-4 mb-md-0 mb-3">
                                <div>
                                    <div
                                        className="card p-3 text-white"
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "1px solid #475569",
                                            marginBottom: "1.5rem",
                                        }}
                                    >
                                        <div style={{ maxHeight: 300, minHeight: isMobile ? '' : 250, overflow: 'Hidden' }}><img className="card-img-top" style={{ maxWidth: isMobile ? '330px' : '' }} src={blog.blog_image_path} alt="" /></div>
                                        <div className="card-body">
                                            <h4 className="card-title">
                                                {blog.blog_title}
                                            </h4>
                                            <Typography
                                                className="card-text my-4"
                                                sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 3,
                                                }}
                                                variant="body1">
                                                {blog.blog_summary}
                                            </Typography>
                                            <div className="back_border ps-3 py-1">
                                                <a href={`/blogs?userid=${blog.userid}`} target="_blank" style={{ color: 'white' }} >Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

                {/*  */}

                {/* FAQ questions? section */}
                <div className="container text-white">
                    <div className="row py-sm-5 pt-3 mx-lg-5">
                        <div className="col-md-4 pt-sm-5 pb-3">
                            <div className="text-md-start text-center">
                                <p className={isMobile ? "py-2 px-3 my-4 rounded-pill fs-14 text-center" : "footer_boder py-2 px-3 my-4 rounded-pill fs-14 text-center"} style={{ fontSize: '16px', fontWeight: 'lighter', color: '#101828', background: '#E9F2FF' }}>
                                    Have Questions?
                                </p>
                                <h4 className="mb-sm-5 main_heading" style={{ color: '#fff' }}>
                                    We have 24/7 <br /> support available
                                </h4>
                                <button className="button_leniar px-5 my-3"
                                    onClick={() => {
                                        navigate("/contactus");
                                    }}
                                    style={{ borderRadius: '6px', height: isMobile ? '100%' : '' }}
                                >
                                    Contact support <span><RoundedRightArrow /></span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-8 py-sm-5 py-3">
                            <div>
                                <div className="accordion" id="regularAccordionRobots">
                                    <div className="rounded-1 mb-2">
                                        <h2 id="regularHeadingFirst" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapseFirst"
                                                aria-expanded="true"
                                                aria-controls="regularCollapseFirst"
                                            >
                                                What is Traffic Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapseFirst"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="regularHeadingFirst"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Traffic Monster is an AI-powered tool that converts your website content{/* , podcast, or video */} into high-quality, SEO-optimized blog posts, helping you reach a broader audience and improve your search engine rankings.
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeadingThird">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapseThird"
                                                aria-expanded="false"
                                                aria-controls="regularCollapseThird"
                                            >
                                                How does Traffic Monster work?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapseThird"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeadingThird"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Simply provide a website url for your business, or a YouTube URL with your podcast.   Traffic Monster’s AI will use the content from your website or video to create SEO optimized, personalized blogs daily, tailored to your brand.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading4">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse4"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse4"
                                            >
                                                What types of content can Traffic Monster generate from my content?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse4"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading4"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Traffic Monster generates SEO-enriched blog posts, which can include multimedia elements like embedded videos and podcasts, Call to Action buttons, lead capture forms, and social media sharing options.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading5">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse5"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse5"
                                            >
                                                Can I customize the blog content generated by Traffic Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse5"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading5"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, you can customize various aspects of the blog content, including the blog text, the Call to Action button, integrating multimedia, and configuring lead capture forms to align with your marketing goals.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading6">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse6"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse6"
                                            >
                                                How does Traffic Monster improve my SEO?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse6"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading6"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Traffic Monster optimizes your blog content for relevant keywords, ensuring it ranks higher in Google search results. This increases your visibility and drives more organic traffic to your site.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading7">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse7"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse7"
                                            >
                                                What are the benefits of embedding my podcast into the blog?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse7"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading7"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Embedding your podcast or other video directly into the blog allows visitors to listen or watch the episode without leaving the page, enhancing engagement and exposing your podcast to a wider audience.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading8">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse8"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse8"
                                            >
                                                How can I monetize my content using Traffic Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse8"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading8"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                By generating more traffic through SEO-optimized blogs, you can monetize your content through ads, affiliate marketing, and sponsored posts embedded within the blogs.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading9">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse9"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse9"
                                            >
                                                Is it possible to integrate lead capture forms and newsletter sign-ups into the blogs?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse9"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading9"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, Traffic Monster allows you to include lead capture forms and newsletter sign-up options in your blogs, helping you grow your email list and generate leads.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading10">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse10"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse10"
                                            >
                                                Can I share the blog content on social media?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse10"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading10"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Absolutely! Traffic Monster includes social media sharing features, making it easy for you and your visitors to share blog content across various platforms, increasing your reach and visibility.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading11">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse11"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse11"
                                            >
                                                How does Traffic Monster help with brand awareness?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse11"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading11"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                By creating personalized, high-quality blog content based on your podcast transcripts or website, Traffic Monster helps strengthen your brand’s voice, increases online presence, and builds a loyal audience.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading12">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse12"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse12"
                                            >
                                                Who can benefit from using Traffic Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse12"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading12"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Traffic Monster is ideal for businesses, entrepreneurs, podcasters, content creators, and businesses looking to maximize the reach of their podcast content by converting it into engaging, SEO-friendly blog posts.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-1 mb-2">
                                        <h2 className="accordion-header" id="regularHeading13">
                                            <button
                                                className="accordion-button accordion_style collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse13"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse13"
                                            >
                                                How often can I generate blog content with Traffic Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse13"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading13"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Our AI automatically generate new personalized SEO blogs for you daily based on your website or podcast content.  You can generate additional blog content by creating new blog campaigns, allowing for a consistent and scalable content strategy.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* FAQ section end */}

                {/* kids emotional intelligence section */}
                {/* <div className="row my-sm-5 mb-5">
                    <div className="col-md-7 text-white text-center mt-2">
                        <div className="px-sm-0 px-2">
                            <div>
                                <div className="mb-5">
                                    <img
                                        src="./img/text-1.png"
                                        className="img-fluid"
                                        style={{ width: "80%" }}
                                        alt=""
                                    />
                                </div>
                                <div className="Ac-1">
                                    <div className="frame-1707">

                                        <div>
                                            <div  style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '40px' }}>Kids Emotional Intelligence</div>
                                            <div  style={{ textAlign: 'left', fontWeight: 'normal', fontSize: '40px', color: '#4BA5EB', fontFamily: 'Gochi Hand' }}>Let's Create Happy, Healthy Kids</div>
                                        </div> <br></br>  <br></br>

                                        <div style={{ textAlign: 'left' }}>
                                            <p style={{ fontSize: '18px', fontWeight: 'lighter' }}>Code Genie AI (Traffic Monster) are supporters of kids emotional intelligence.  We believe that we have an obligation to create happy and emotionally healthy children. We have partnered with Equip Our Kids, a non profit and a part of the Earth Ways Foundation 501c3, to help support parents with creating emotionally intelligent kids.Learn more at <a href="https://equipourkids.org/" style={{ color: '#4BA5EB' }}>https://equipourkids.org/</a></p>
                                        </div>
                                    </div>

                                </div> <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 my-auto">
                        <div className="px-sm-0 px-2">
                            <img src={equip_your_kids} style={{ maxWidth: isMobile ? '100%' : '' }} className="img-fluid-code-L1" alt="" />
                        </div>
                    </div>
                </div> */}
                <hr></hr>

                {/* kids emotional intelligence section end */}

                {/* signMeUp section */}
                <div className="container" style={{ padding: '10%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={() => navigate('/signup')}>
                        <img className="wide-on-mobile" src={signup_frame} />
                    </div>
                </div>
                {/* section end */}
                <hr></hr>


                {/* <hr></hr> */}

                {/* News section */}
                <div className="container my-sm-5 my-3">
                    <div className="">
                        <h4 className="main_heading my-3 text-md-start text-center">{/* News */}Blogs</h4>
                    </div>
                    <div className="row">
                        {blogs && blogs.map((blog: any, index: any) => (
                            <div className="col-md-4 mb-md-0 mb-3">
                                <div>
                                    <div
                                        className="card p-3 text-white"
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "1px solid #475569",
                                            marginBottom: "1.5rem",
                                        }}
                                    >
                                        <div style={{ maxHeight: 300, minHeight: isMobile ? '' : 250/* 300 */, overflow: 'Hidden' }}><img className="card-img-top" style={{ maxWidth: isMobile ? '330px' : '', cursor: 'pointer' }} src={blog.blog_image_path} alt="" onClick={()=>window.open(`/PreviewBlogs1/${blog._id}`, '_blank')}/></div>
                                        <div className="card-body">
                                            <h4 className="card-title" style={{cursor: 'pointer'}} onClick={()=>window.open(`/PreviewBlogs1/${blog._id}`, '_blank')}>
                                                {blog.blog_title}
                                            </h4>
                                            <Typography
                                                className="card-text my-4"
                                                sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 3,
                                                }}
                                                variant="body1">
                                                {blog.blog_summary}
                                            </Typography>
                                            <div className="back_border ps-3 py-1">
                                                {/* <a target="_blank" rel="noreferrer" href={blog.url} style={{  textDecoration: 'underline' }} >Read more</a> */}
                                                <a href={`/PreviewBlogs1/${blog._id}`} target="_blank" style={{ color: 'white' }} >{/* Read More */}View Blogs</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/*  */}
                {/* {!showAllBlogs && ( */}
                <div className="text-center my-5">
                    <button type="button" className="btn btn-outline-primary px-5">  <a target="_blank" href={`/blogs?userid=${blogs && blogs[0]?.userid}`} style={{ textDecoration: 'underline', color: '#FFF' }}> Read more blogs</a>
                    </button>
                </div>
                <br />
                <br />
                <hr />
                {/* )} */}

                {/* Footer section */}
                <div className="py-5 mx-sm-0 mx-2">
                    <div className="container">
                        <div className="row px-sm-0 px-2">
                            <div className="col-lg-3 text-white text-lg-start text-center">
                                <div>
                                    <div>
                                        <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}><img src={logo_white} alt="" />CodeGenie</Typography>

                                    </div>
                                    <p /* className="fw_samibold" */ style={{ fontSize: 14, fontWeight: 'lighter' }}>
                                        {/* Mr John Smith. 132, My Street, Kingston, New York 12401. */}
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-2 col-sm-4">
                                &nbsp;
                            </div>
                            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
                                &nbsp;
                            </div>
                            <div className="col-lg-5 col-sm-4  text-lg-start text-sm-end" style={{ display: 'flex', justifyContent: 'right' }}>
                                Copyright © 2024 Code-Genie.ai | All Rights Reserved.
                            </div>

                        </div>
                    </div>
                </div>
                {/* footer section end*/}
            </div >

        </>
    )
}

export default TrafficMonsterlandingnew;